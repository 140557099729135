import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@Themes/main';
import Routes from '@Routes/index.jsx';
import './App.less';
import './i18n';

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
}
