import { firestore } from '@Config/firebase';

export const image360ScenesRef = firestore.collection('scenes');
export const jpoaRef = firestore.collection('jpaCarousel');
export const virtualTourVideoInterviewsRef = firestore.collection('virtualTourVideoInterviews');
export const virtualTourElementsRef = firestore.collection('virtualTourElements');
export const virtualTourPhotosRef = firestore.collection('virtualTourPhotos');
export const virtualTourNewsRef = firestore.collection('virtualTourNews');
export const virtualTourMapsRef = firestore.collection('virtualTourMaps');
export const virtualTourAerialPhotosRef = firestore.collection('virtualTourAerialPhotos');
export const virtualTourGeneralInfoRef = firestore.collection('generalInformation');
export const trailsMapRef = firestore.collection('trailsMapData');
