import PageWrapper from '@Components/PageWrapper';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';

export default styled(PageWrapper)`
    background: #252525;
    height: auto;

    .banner-title-custom {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '60px')};
        position: absolute;
        bottom: 20vh;
        left: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: rgba(0, 0, 0, 0.5);
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    .banner-pt {
        padding-top: ${between('30px', '130px')};
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .eqh-wrapper {
        display: flex;
        flex-flow: column;
    }

    .font-title {
    }
    .font-title-mirror {
        font-family: 'Didot' !important;
        opacity: 0.4;
    }

    .transform-top-banner {
        transform: translateY(-90%);
    }

    .padding-horizontal-sm {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .eqh-img {
        flex: 1 1 auto;
    }

    .eqh-text {
        flex: 0 1 70px;
    }

    .eqh-md-text {
        flex: 0 1 90px;
    }

    .eqh-lg-text {
        flex: 0 1 110px;
    }

    .padding-horizontal {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .letterspacing-fix {
        padding-left: 1.5vw;
    }

    .padding-vertical {
        padding-top: ${between('110px', '300px', '375px', '1920px')};
        padding-bottom: ${between('10px', '40px', '375px', '1920px')};
    }

    .mb-xs {
        margin-bottom: 5vh;
    }

    .mb-sm {
        margin-bottom: 10vh;
    }

    .mb-md {
        margin-bottom: 20vh;
    }

    .mb-lg {
        margin-bottom: 30vh;
    }

    .h-100 {
        height: 100%;
    }

    .h-100vh {
        height: 100vh;
    }

    .w-100 {
        width: 100%;
    }

    .pb-xs {
        padding-bottom: 5vh;
    }

    .pb-sm {
        padding-bottom: 10vh;
    }

    .pb-md {
        padding-bottom: 20vh;
    }

    .pt-xs {
        padding-top: 5vh;
    }

    .pt-sm {
        padding-top: 10vh;
    }

    .pt-lg {
        padding-top: 20vh;
    }

    .pt-img-alt {
        padding-top: 10px;
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .vertical-sb {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
    }

    .vertical-sa {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        justify-content: space-around;
    }

    .vertical-end {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .horizontal-center {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
    }

    .horizontal-sb {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .ant-col:first-child {
        padding-left: 0 !important;
    }

    .ant-col:last-child {
        padding-right: 0 !important;
    }

    .fade-in {
        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fade-in 2s ease-in forwards;
    }

    .pl-reset {
        padding-left: 0px !important;
    }

    .pr-reset {
        padding-right: 0px !important;
    }

    .transform-top-sm {
        transform: translateY(-10%);
    }
    .transform-top-md {
        transform: translateY(-25%);
    }
    .transform-top-lg {
        transform: translateY(-40%);
    }

    .border-test {
        border: 1px solid red;
    }

    .flex-full-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mb-xs-static {
        margin-bottom: 32px;
    }

    .sm-banner {
        height: 30vh;
    }

    .pnlm-load-box {
        background: transparent;
        p {
            display: none;
        }

        .pnlm-lbox {
            display: none;
        }
    }
    .pnlm-container {
        background: transparent;
    }
    .slider3 {
        .water-alt-container {
            padding-left: ${between('30px', '110px')} !important;
            padding-right: ${between('30px', '110px')} !important;
        }
    }
`;

export const Banner = styled(Row)`
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-repeat: no-repeat;
    /* background-position: center center;
    background-size: contain; */
    height: ${({ sm }) => (sm ? '40vh' : '100vh')};
    background-position: center;
    background-size: 100% 100%;
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
`;

export const TourWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    height: ${({ sm }) => (sm ? '50vh' : '100vh')};
`;
