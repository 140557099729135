import { between } from 'polished';
import styled from 'styled-components';
import Layout from '../index';

const { Header } = Layout;

export default styled(Header)`
    z-index: 1000;
    width: 100%;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme, isAos, isInfo }) => (isAos || isInfo ? theme.layoutStyles.headerColorAos : theme.layoutStyles.headerColor)};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${between('0px', '60px')};
    position: fixed;

    .header-logo {
        cursor: pointer;
        user-select: none;
        height: 54px;
        padding-top: 0px;
    }

    .active-btn-element {
        span {
            font-weight: 800 !important;
            transition: all 0.2s ease-in-out;
        }
    }

    .padding-horizontal-aos-nav {
        /* padding-left: ${between('30px', '120px')};
        padding-right: ${between('30px', '120px')}; */
        max-width: 300px;
        border: 1px solid red;
    }

    .aos-active-btn {
        border-bottom: 2px solid #ffffff;
    }

    .margin-left-hamburger {
        margin-left: 16px !important;
    }
`;
