import styled from 'styled-components';
import { Row, Col } from 'antd';
import { HomeOutlined, EnvironmentFilled, EnvironmentOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Button from '@Components/Button';

export const StyledMapWrapper = styled.div`
    position: absolute;
    bottom: 0px;
    right: 45px;
    height: 315px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-top-left-radius: 15px;
    border-right: 1px solid transparent;
    img {
        /* width: 100%; */
        height: 100%;
        object-fit: contain;
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .viewControlButtonWrapper {
        display: none !important;
    }

    animation: ${({ isMapVisible }) => (!isMapVisible ? 'fade-out 0.8s ease-out forwards' : 'fade-in 0.8s ease-in forwards')};
    display: ${({ isMapVisible }) => !isMapVisible && 'none'};
    /* transition-property: display, transform;
    transition-duration: 1.5s;
    transition-timing-function: linear; */
`;

export const LeftSide = styled(Col)`
    background-color: rgba(0, 0, 0, 0.6);
    height: 40vh;
    padding-top: 16px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 50px;

    span {
        display: block;
        margin-bottom: 16px;
    }
`;

export const StyledHomeOutlined = styled(HomeOutlined)`
    font-size: 24px;
    width: 50px;
    height: 30px;
    margin: 0px;

    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledLinkButton = styled(Button)`
    height: 30px;
    width: 50px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0px;
        div {
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }

    svg {
        transform: scale(0.7) !important;
    }
`;

export const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
    font-size: 24px;
    width: 50px;
    height: 30px;
    margin: 0px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
    font-size: 24px;
    width: 50px;
    height: 30px;
    margin: 0px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledEnvironmentFilled = styled(EnvironmentFilled)`
    font-size: 24px;
    color: #ffffff;
    width: 50px;
    height: 30px;
    margin: 0px;

    :hover {
        cursor: pointer;
    }
`;

export const StyledEnvironmentOutlined = styled(EnvironmentOutlined)`
    font-size: 24px;
    width: 50px;
    height: 30px;
    margin: 0px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;
