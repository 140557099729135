import React from 'react';
import Maps from '@Components/Map';
import { Col, Row } from 'antd';
import { Text } from '@Components/Typography/styles';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import { canonicals } from '@Helpers';
import { Helmet } from 'react-helmet';
import CommunityStyles, { Banner } from './styles';

const CommunityPage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    return (
        <CommunityStyles>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.community')}</title>
                <link rel="canonical" href={canonicals.community} />
            </Helmet>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FTopluluk%2Fs1.jpg?alt=media&token=c31f9153-bf84-4123-82dd-60d2d1d11da1">
                <Col className="vertical-sb padding-vertical" span={24}>
                    <Row align="middle" justify="center">
                        <Text className="banner-title" family="secondary" size="140px" color="light" weight="700">
                            {t('aos.community.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" weight="600">
                            {t('aos.community.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt pb-md bg-color-main">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.community.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.community.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Maps style={{ height: '100vh' }} />
        </CommunityStyles>
    );
};

export default CommunityPage;
