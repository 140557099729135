/* eslint-disable no-plusplus */
/* eslint-disable import/no-duplicates */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-restricted-properties */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { LoadScript, Marker } from '@react-google-maps/api';
import useFetchFirestoreData from '@Hooks/useFetchFirestoreData';
import { trailsMapRef } from '@Services/Collections/index';
import useResponsive from '@Hooks/useMediaQuery';
import { Col, Row } from 'antd';
import Carousel from '@Components/Carousel';

import { Text } from '@Components/Typography/styles';
import { useTranslation } from 'react-i18next';

import { generalMapOptions } from './map-data';
import StyledGoogleMap, { StyledLegend, Wrapper, Dot, StyledButton, StyledModal, Img } from './styles';

const VisibleMarker = ({ setSelectedLocation, selectedPlace }) => {
    const onMarkerLoad = (marker) => {
        const timeOutVal = (Math.random() * (0.4 - 0.2) + 0.2).toFixed(2);
        const markerOpacityIncrement = 0.05;
        const markerOpacityIncrementRevert = Math.pow(markerOpacityIncrement, -1);
        setTimeout(function () {
            setTimeout(() => {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < markerOpacityIncrementRevert.toFixed(); i++) {
                    marker.setOpacity(marker.getOpacity() + Number(markerOpacityIncrement));
                }
            }, timeOutVal);
        }, 700);
    };

    const onMarkerClick = (location) => {
        setSelectedLocation(location);
    };

    return (
        <>
            {selectedPlace?.locations?.map((location) => (
                <Marker
                    className="fade-in"
                    options={{
                        icon: {
                            path: 'M56.7307 92.9917C78.0188 88.5103 94 69.6211 94 47C94 21.0425 72.9574 0 47 0C21.0426 0 0 21.0425 0 47C0 69.6211 15.9812 88.5103 37.2693 92.9917L47 158L56.7307 92.9917Z',
                            fillColor: selectedPlace.markerColor,
                            fillOpacity: 1,
                            scale: 0.3,
                        },
                    }}
                    key={`${location.nameTR}`}
                    onLoad={onMarkerLoad}
                    visible
                    clickable
                    onClick={() => onMarkerClick(location)}
                    opacity={0}
                    position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                />
            ))}
        </>
    );
};

const Legend = ({ selectedPlace, documents, setSelectedPlace }) => {
    const { t, i18n } = useTranslation('common');

    const handleLegendItemClick = (clickedPlace) => {
        if (selectedPlace.docId === clickedPlace.docId) {
            // selectedPlaces.some((item) => item.docId === clickedPlace.docId)
            // setSelectedPlaces(selectedPlaces.filter((item) => item.docId !== clickedPlace.docId));
        } else {
            setSelectedPlace(clickedPlace);
        }
    };

    return (
        <StyledLegend>
            {documents.map((item) => (
                <Row className="space" align="middle" justify="start" key={`${item.docId}-legend`}>
                    <StyledButton onClick={() => handleLegendItemClick(item)}>
                        <Dot active={selectedPlace.docId === item.docId} color={item.markerColor} className="dot-style" />
                        <Text>{i18n.language === 'tr' ? item.titleTR : item.titleEN}</Text>
                    </StyledButton>
                </Row>
            ))}
        </StyledLegend>
    );
};

const Map = () => {
    const [documents] = useFetchFirestoreData(trailsMapRef);
    const [selectedPlace, setSelectedPlace] = useState();
    const [map, setMap] = useState();
    const [selectedLocation, setSelectedLocation] = useState(false);
    const [isMapIdle, setIsMapIdle] = useState(false);
    const { ismobile } = useResponsive();
    const { t, i18n } = useTranslation('common');

    const onLoad = (polygon) => {};

    useEffect(() => {
        if (documents) {
            setSelectedPlace(documents[0]);
        }
    }, [documents]);

    const handleCancel = () => {
        setSelectedLocation(false);
    };

    return (
        <Wrapper>
            <LoadScript googleMapsApiKey="AIzaSyDIkz21QhCfWRsOLKjMpF7ECQ6IIKG4kG0">
                <StyledGoogleMap
                    onLoad={(mapInstance) => {
                        setMap(mapInstance);
                    }}
                    onIdle={() => setIsMapIdle(true)}
                    options={generalMapOptions.mapOptions}
                    mapContainerStyle={generalMapOptions.containerStyle}
                    center={generalMapOptions.center}
                    zoom={15}>
                    {selectedPlace && <VisibleMarker setSelectedLocation={setSelectedLocation} selectedPlace={selectedPlace} />}
                </StyledGoogleMap>
            </LoadScript>
            {selectedPlace && (
                <>
                    <Legend selectedPlace={selectedPlace} documents={documents} setSelectedPlace={setSelectedPlace} />
                    <StyledModal
                        forceRender
                        centered
                        key={selectedLocation.nameTR}
                        width={ismobile ? 600 : 900}
                        selectedLocation={selectedLocation}
                        visible={selectedLocation}
                        onCancel={handleCancel}>
                        <Row className="h-100">
                            <Col className="vertical-center" span={24}>
                                <Row>
                                    <Col className="vertical-center" span={selectedLocation.images ? 12 : 24}>
                                        <Row className="mb-sm">
                                            <Text size="36px" color="light">
                                                {i18n.language === 'tr' ? selectedLocation.nameTR : selectedLocation.nameEN}
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Text color="light">{i18n.language === 'tr' ? selectedLocation.textTR : selectedLocation.textEN}</Text>
                                        </Row>
                                        <Row className="pt-alt">
                                            <Text size="14px" weight="300" color="grey">
                                                {i18n.language === 'tr' ? selectedLocation.textAltTR : selectedLocation.textAltEN}
                                            </Text>
                                        </Row>
                                    </Col>
                                    {selectedLocation?.images?.length === 1 ? (
                                        <Col className="vertical-center" span={12}>
                                            <Img src={selectedLocation.images[0].src} />
                                        </Col>
                                    ) : selectedLocation?.images?.length > 1 ? (
                                        <Col className="vertical-center" span={12}>
                                            <Carousel data={selectedLocation.images} trails />
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </StyledModal>
                </>
            )}
        </Wrapper>
    );
};

export default Map;
