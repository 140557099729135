import React, { useState, useEffect } from 'react';
import { Text } from '@Components/Typography/styles';
import useFetchFirestoreData from '@Hooks/useFetchFirestoreData';
import LoadingSpinner from '@Components/LoadingSpinner';
import useResponsive from '@Hooks/useMediaQuery';
import PhotoSlider from '@Components/PhotoSlider';
import { StyledModal, ModalContentWrapper } from './styles';

const PhotoModal = ({ isPhotoModalVisible, key, documents, handleCancel }) => {
    const { ismobile } = useResponsive();

    const triggerCancel = () => {
        handleCancel();
    };

    return (
        <StyledModal key={key} width={ismobile ? 600 : 1140} centered destroyOnClose visible={isPhotoModalVisible} onCancel={triggerCancel}>
            <ModalContentWrapper destroyOnClose span={24}>
                {documents && (
                    <PhotoSlider
                        documents={documents?.sort(({ orderId: previousID }, { orderId: currentID }) => previousID - currentID)}
                        isNews={documents[0]?.alt}
                    />
                )}
            </ModalContentWrapper>
        </StyledModal>
    );
};

export default PhotoModal;
