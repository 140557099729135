/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import useResponsive from '@Hooks/useMediaQuery';
import { Text } from '@Components/Typography/styles';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import Carousel from '@Components/Carousel';
import { Pannellum } from 'pannellum-react';
import StyledWaterCulturePage, { Banner, Img, TourWrapper } from './styles';

const Content360 = () => {
    const { t, i18n } = useTranslation('common');
    const panellumRef = useRef('panellum-ref');
    const [forceRender, setForceRender] = useState(false);

    useEffect(() => {
        panellumRef.current.forceRender();
    }, [t]);

    const scenes = [
        {
            id: 0,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs7-360-1.JPG?alt=media&token=d68e3d00-c7b3-4299-a4e0-29e05aaa47a9',

            hotspots: [
                {
                    id: 2,
                    type: 'navigation',
                    yaw: 375,
                    pitch: -2.41,
                    to: 1,
                },
            ],
        },
        {
            id: 1,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs7-360-2.JPG?alt=media&token=4676a5c2-c08e-453f-b580-59083e59897d',
            hotspots: [
                {
                    id: 5,
                    type: 'navigation',
                    yaw: 400.76,
                    pitch: -4.41,
                    to: 0,
                },
            ],
        },
    ];

    const [currentScene, setCurrentScene] = useState(scenes[0]);

    return (
        <Pannellum
            ref={panellumRef}
            forceRender={forceRender}
            width="100%"
            height="100vh"
            image={currentScene.sceneURL}
            pitch={10}
            yaw={0}
            hfov={110}
            autoRotate={5}
            autoLoad
            mouseZoom={false}
            autoRotateInactivityDelay={2}
            showZoomCtrl={false}>
            {currentScene.hotspots.map((item) =>
                item.type === 'navigation' ? (
                    <Pannellum.Hotspot
                        type="custom"
                        key={item.id}
                        pitch={item.pitch}
                        yaw={item.yaw}
                        handleClick={() => setCurrentScene(scenes[item.to])}
                    />
                ) : (
                    <Pannellum.Hotspot
                        type="info"
                        key={item.id}
                        text={i18n.language === 'tr' ? item.textTR : item.textEN}
                        pitch={item.pitch}
                        yaw={item.yaw}
                    />
                )
            )}
        </Pannellum>
    );
};

const WaterCulturePage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    const slider2Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fslide2-1.jpg?alt=media&token=faba9f27-d4df-4137-b8cb-813a0240afd9',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fslide2-2.jpg?alt=media&token=0a7024e5-db52-4865-ba70-74fa21e13a39',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fslide2-3.jpg?alt=media&token=eb5a98d6-f8df-4420-b1ee-984c72ed8f8c',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fslide2-4.jpg?alt=media&token=47036dd1-2536-43a2-a8cb-0f02125fe8d7',
            },
            {
                id: 5,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fslide2-5.jpg?alt=media&token=82aa5b1b-a874-48f9-a4e2-b54f00cdadf7',
            },
        ],
        imgAlt: t('aos.water.slider2.sliderAlt'),
        imgAltDesc: t('aos.water.slider2.sliderAltDesc'),
    };

    return (
        <StyledWaterCulturePage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.waterCulture')}</title>
                <link rel="canonical" href={canonicals.waterCulture} />
            </Helmet>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs1.jpg?alt=media&token=dbbf829c-8eaa-44ee-a916-0c284a73cd80">
                <Col className="vertical-sb overlay padding-vertical padding-horizontal" span={24}>
                    <Row className="letterspacing-fix " align="middle" justify="center">
                        <Text className="title-styles" size="140px" letterSpacing="big" color="light" weight="700">
                            {t('aos.water.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" weight="600">
                            {t('aos.water.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.water.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.water.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            {/* <Row className="padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.section2.text')}
                </Text>
            </Row>
            <Row align="middle" justify="start" className="padding-horizontal pt-img-alt mb-xs">
                <Text displayblock color="grey" weight="300">
                    {t('aos.water.section2.textAlt')}
                </Text>
            </Row> */}
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.water.section2.text')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="start" className="pt-img-alt">
                        <Text displayblock color="grey" weight="300">
                            {t('aos.water.section2.textAlt')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs2.jpg?alt=media&token=f79b6c27-7986-4a18-aa1d-c1ca13a8f09a" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section2.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section2.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-sb pt-lg'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.water.section3.text')}
                        </Text>
                    </Row>
                    <div>
                        <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs3-1.jpg?alt=media&token=e2292a41-e1d5-404f-8d4d-4023fd64dbc9" />
                        <Row className="pt-img-alt">
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.water.section3.imgAlt1')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.water.section3.imgAlt1Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs3-2.jpg?alt=media&token=34aebd2b-5686-414a-a855-820f9461224c" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section3.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section3.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Carousel type="slider1" water data={t('aos.water.slider1', { returnObjects: true })} />
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.water.section4pre.text')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="start" className="pt-img-alt">
                        <Text displayblock color="grey" weight="300">
                            {t('aos.water.section4pre.textAlt')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-sm padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.section4.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs5-1.jpg?alt=media&token=ccbd30b0-18c6-431d-b7c1-50bb8f4d1225"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section4.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section4.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs5-2.jpg?alt=media&token=4da48808-27c2-4a84-a17b-2f8b84f2a02b"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section4.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section4.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs5-3.JPG?alt=media&token=cb9e72de-efdd-45b3-8db1-f071bf9798c0"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section4.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section4.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.slider2.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text displayblock color="grey" weight="300">
                    {t('aos.water.slider2.textAlt')}
                </Text>
            </Row>
            <Carousel className="mb-lg" type="slider1" data={slider2Data} />

            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={14}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.water.section5.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={10}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs7-1.JPG?alt=media&token=46b4f153-adf5-40f4-be88-6dad83918889" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section4.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section4.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md">
                <TourWrapper sm={sm}>
                    <Content360 />
                </TourWrapper>
                <Row className="pt-img-alt">
                    <Col>
                        <Text displayblock color="grey">
                            {t('aos.water.section5.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.water.section5.imgAltDesc')}
                        </Text>
                    </Col>
                </Row>
            </Row>
            <Row className="padding-horizontal mb-md">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.section6.text')}
                </Text>
            </Row>
            <Row className="mb-md ">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs8-1.jpg?alt=media&token=7b38d21c-f132-4d0f-b6a7-d84669987504" />
                <Row className="pt-img-alt padding-horizontal">
                    <Col>
                        <Text displayblock color="grey">
                            {t('aos.water.section6.imgAlt1')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.water.section6.imgAlt1Desc')}
                        </Text>
                    </Col>
                </Row>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col sm={24} md={10}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs8-2.jpg?alt=media&token=4259c630-b6be-48bc-be29-6ec9153349c7" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section6.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section6.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={14}>
                    {t('aos.water.section6.legend', { returnObjects: true }).map((item, i) => (
                        <Row key={`${i}legend`} className="pt-img-alt">
                            <Text displayblock color="grey">
                                {item.title} :
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {item.desc}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>

            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Carousel type="slider3" water data={t('aos.water.section7.slider3', { returnObjects: true }).slice(0, 2)} />
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.water.section7.text')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="start" className="pt-img-alt">
                        <Text displayblock color="grey" weight="300">
                            {t('aos.water.section7.textAlt')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Carousel
                className="padding-horizontal mb-lg"
                type="slider3"
                water
                data={t('aos.water.section7.slider3', { returnObjects: true }).slice(2)}
            />

            <Row className="padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.section8.text')}
                </Text>
            </Row>
            <Row align="middle" justify="start" className="pt-img-alt padding-horizontal mb-sm">
                <Text displayblock color="grey" weight="300">
                    {t('aos.water.section8.textAlt')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs10-1.jpg?alt=media&token=221e7604-d69e-41c7-9a21-3ed7b00ab251"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section8.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section8.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs10-2.jpg?alt=media&token=c18a102e-8e2f-499e-87a2-bd8162e96ed0"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section8.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section8.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs10-3.jpg?alt=media&token=2bbe55c1-6966-479c-b5cf-d1b886f28433"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section8.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section8.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.water.section9.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={14}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs11-1.jpg?alt=media&token=24f19df1-b02b-4de7-86a9-861108c4bbdd"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section9.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section9.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={10}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs11-2.jpg?alt=media&token=67b214a4-0745-46a7-b9c9-02907b0cfebc"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.water.section9.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.water.section9.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className="banner-title-custom" style={!sm && { maxWidth: '50%' }}>
                        <Text displayblock size="28px" color="light" weight="600">
                            {t('aos.water.section10.text')}
                        </Text>
                    </Row>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FSu%2Fs12.jpg?alt=media&token=f9d4f782-263c-4dfb-83da-4a2e94a946f7" />
                </Col>
            </Row>

            <Row className="padding-horizontal" style={{ paddingBottom: '5vh' }}>
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.water.section10.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.water.section10.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
        </StyledWaterCulturePage>
    );
};

export default WaterCulturePage;
