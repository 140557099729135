/* eslint-disable sonarjs/no-identical-functions */
import React from 'react';
import StyledLandingPage from '@Components/PageWrapper';
import VideoOverlay from '@Components/VideoOverlay';
import Button from '@Components/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';
import useResponsive from '@Hooks/useMediaQuery';
import { MailOutlined, InfoOutlined } from '@ant-design/icons';
import { Wrapper, Content, NavItemsWrapper, NavItemsWrapperMobile, LandingFooter } from './styles';

const LandingPage = () => {
    const jqaHeroVideo = `https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/jqa-hero-video.mp4?alt=media&token=16ca2eb0-f81e-492e-a737-06c5c824959c`;
    const { t } = useTranslation('common');
    const history = useHistory();
    const { sm } = useResponsive();

    return (
        <StyledLandingPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.landing')}</title>
                <link rel="canonical" href={canonicals.landing} />
            </Helmet>
            <Wrapper span={24}>
                <VideoOverlay opacity="0.35" />
                <Content align="middle">
                    <video data-keepplaying id="jqa-hero-video" preload="auto" loop autoPlay muted>
                        <source src={jqaHeroVideo} type="video/mp4" />
                    </video>
                </Content>
                {sm ? (
                    <NavItemsWrapperMobile align="middle" justify="center">
                        {t('landingPage.navItems', { returnObjects: true }).map(({ id, title, path }) => (
                            <Button
                                className="button-styles-mobile"
                                onClick={() => history.push(path)}
                                key={id}
                                letterSpacing="middle"
                                textsize="64px"
                                textcolor="light"
                                weight="bold"
                                type="link">
                                {title}
                            </Button>
                        ))}
                    </NavItemsWrapperMobile>
                ) : (
                    <NavItemsWrapper align="middle" justify="center">
                        {t('landingPage.navItems', { returnObjects: true }).map(({ id, title, path }) => (
                            <Button
                                onClick={() => history.push(path)}
                                key={id}
                                letterSpacing="middle"
                                className={id !== 3 && 'button-styles'}
                                textsize="21px"
                                textcolor="light"
                                type="link">
                                {title}
                            </Button>
                        ))}
                    </NavItemsWrapper>
                )}
                <LandingFooter align="middle" justify="center">
                    <Button type="secondary" onClick={() => history.push('/info')} className="footer-icons">
                        <InfoOutlined className="mail-icon" />
                    </Button>
                    <Button type="secondary" className="footer-icons">
                        <a href="mailto:contact@jewishquarterankara.com">
                            <MailOutlined className="mail-icon" />
                        </a>
                    </Button>
                </LandingFooter>
            </Wrapper>
        </StyledLandingPage>
    );
};

export default LandingPage;
