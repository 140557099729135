import styled from 'styled-components';
import { Drawer } from 'antd';
import { between } from 'polished';

export default styled(Drawer)`
    .anticon-close {
        font-size: ${between('14px', '28px')};

        color: white;
    }

    .icon-width {
        max-width: ${between('60px', '103px')};
    }

    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .aos-active-btn {
        border-bottom: 2px solid #ffffff;
    }

    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: ${between('10px', '24px')};

    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .ant-drawer-content {
        background-color: transparent;
    }
    .ant-drawer-header {
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;
