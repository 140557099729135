/* eslint-disable no-use-before-define */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useResponsive from '@Hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import { Text } from '@Components/Typography/styles';
import { Helmet } from 'react-helmet';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectCoverflow, Mousewheel } from 'swiper';
import { canonicals } from '@Helpers';
import { StyledWrapper, Img } from './styles';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';

const ArchiveOfSilencePage = () => {
    SwiperCore.use([Navigation, Pagination, EffectCoverflow, Mousewheel]);
    const [swiperInstance, setSwiperInstance] = useState(false);
    const { sm } = useResponsive();
    const history = useHistory();
    const { t } = useTranslation('common');

    const handleClick = (path) => {
        history.push(path);
    };

    const handleOnIdle = () => {
        if (swiperInstance) {
            swiperInstance.slideTo(4);
        }
    };

    const handleOnActive = () => {};

    const handleOnAction = () => {};

    useIdleTimer({
        timeout: 5000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
    });

    return (
        <StyledWrapper>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.aos')}</title>
                <link rel="canonical" href={canonicals.aos} />
            </Helmet>
            <Swiper
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                effect="coverflow"
                lazyLoading
                pagination={!!sm}
                lazyLoadingInPrevNext
                mousewheel={{ invert: true }}
                coverflowEffect={{
                    rotate: 10,
                    stretch: 200,
                    depth: 300,
                    scale: 1,
                    modifier: 1,
                    slideShadows: true,
                }}
                slidesPerView={sm ? 1 : 3}
                centeredSlides
                style={{ height: '80vh' }}
                preloadImages
                initialSlide={1}
                roundLengths
                freeMode={{ enabled: true, sticky: false, momentumBounce: false, momentumVelocityRatio: 0.2, momentumBounceRatio: 0.2 }}
                freeModeMomentum={!!sm}
                scrollbar={{ draggable: true }}
                speed={5000}>
                {t('aos.navItems', { returnObjects: true }).map(({ id, name, path, imgURL, subTitle, source }) => (
                    <SwiperSlide
                        onClick={() => handleClick(path)}
                        key={id}
                        className="swiper-slide-styles"
                        style={{
                            backgroundImage: `url(${imgURL})`,
                        }}>
                        <Col className="w-100 h-100 wrapper-flex" span={24}>
                            <Row align="middle" justify="center">
                                <Text
                                    style={{ textAlign: 'center' }}
                                    displayblock
                                    family="secondary"
                                    size={sm ? '3000px' : '60px'}
                                    color="light"
                                    weight="600">
                                    {name.toUpperCase()}
                                </Text>
                            </Row>
                            <Row align="middle" justify="center" style={{ textAlign: 'center', paddingLeft: '5vw', paddingRight: '5vw' }}>
                                <Text displayblock family="secondary" size="21px" color="light" weight="600">
                                    {subTitle}
                                </Text>
                            </Row>

                            <Row className="card-source">
                                <Text displayblock family="secondary" color="light" weight="600">
                                    {source}
                                </Text>
                            </Row>
                        </Col>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Row className="scroll-icon-wrapper" align="middle" justify="center">
                <img style={sm ? { width: '8px' } : { width: '12px' }} src={MousescrollIcon} alt="scroll-icon" />
            </Row>
        </StyledWrapper>
    );
};

export default ArchiveOfSilencePage;
