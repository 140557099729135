/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@Components/Typography/styles';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import PhotoModal from '../PhotoModal';
import VideoModal from '../VideoModal';
import animationData from './playing.json';

import StyledInfoCard, {
    RightSide,
    StyledFileImageOutlined,
    StyledSoundOutlined,
    StyledPlaySquareOutlined,
    Wrapper,
    StyledQuestionCircleOutlined,
    StyledSoundFilled,
    StyledQuestionCircleFilled,
    StyledFileImageFilled,
    StyledPlaySquareFilled,
    StyledBoxPlotOutlined,
    StyledReadOutlined,
    StyledReadFilled,
    StyledBoxPlotFilled,
} from './styles';

const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const InfoCard = ({
    setIsAmbientSoundPlaying,
    ambientRef,
    descriptionEN,
    descriptionTR,
    dubsrcEN,
    dubsrcTR,
    nameEN,
    nameTR,
    photos,
    videos,
    plans,
    news,
    photos2,
    plans2,
    news2,
}) => {
    const { i18n } = useTranslation('common');
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);
    const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false);
    const [isNewsModalVisible, setIsNewsModalVisible] = useState(false);
    const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
    const [isDubPlaying, setDubPlaying] = useState(false);
    const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
    const audioRef = useRef('audio-ref');

    const handleCancel = () => {
        setIsPhotoModalVisible(false);
        setIsVideoModalVisible(false);
        setIsNewsModalVisible(false);
        setIsPlanModalVisible(false);
    };

    const handleDubSoundPlayClicked = () => {
        if (!isDubPlaying) {
            audioRef.current.play();
            setDubPlaying(true);
        } else {
            audioRef.current.pause();
            setDubPlaying(false);
        }
    };

    useEffect(() => {
        setDubPlaying(false);
    }, [i18n.language]);

    useEffect(() => {
        if (isPhotoModalVisible || isNewsModalVisible || isPlanModalVisible) {
            ambientRef.current.pause();
            setIsAmbientSoundPlaying(false); // audioRef.current.pause();
        }
    }, [isPlanModalVisible, isPhotoModalVisible, isNewsModalVisible]);

    return (
        <Wrapper xs={18} sm={18} md={10}>
            <StyledInfoCard align="middle" justify="center">
                <RightSide span={24}>
                    <Row style={{ marginBottom: '16px', width: '100%' }} align="middle" justify="center">
                        <Text color="light" size="title">
                            {i18n.language === 'en' ? nameEN : nameTR}
                        </Text>
                    </Row>
                    <Row
                        style={{ paddingTop: '10px', paddingBottom: '10px', width: '100%' }}
                        className="overflow-scroll"
                        align="middle"
                        justify="center">
                        <Col span={24}>
                            {isDescriptionVisible && <Text color="light">{i18n.language === 'en' ? descriptionEN : descriptionTR}</Text>}
                        </Col>
                    </Row>
                    <Row align="middle" justify="center" gutter={[32, 32]}>
                        {descriptionTR && (
                            <Col>
                                {!isDescriptionVisible ? (
                                    <StyledQuestionCircleOutlined style={{ display: 'none' }} onClick={() => setIsDescriptionVisible(true)} />
                                ) : (
                                    <StyledQuestionCircleFilled style={{ display: 'none' }} onClick={() => setIsDescriptionVisible(false)} />
                                )}
                            </Col>
                        )}
                        {(photos || photos2) && (
                            <Col>
                                {!isPhotoModalVisible ? (
                                    <StyledFileImageOutlined onClick={() => setIsPhotoModalVisible(true)} />
                                ) : (
                                    <StyledFileImageFilled onClick={() => setIsPhotoModalVisible(false)} />
                                )}
                            </Col>
                        )}
                        {videos && (
                            <Col>
                                {!isVideoModalVisible ? (
                                    <StyledPlaySquareOutlined onClick={() => setIsVideoModalVisible(true)} />
                                ) : (
                                    <StyledPlaySquareFilled onClick={() => setIsVideoModalVisible(false)} />
                                )}
                            </Col>
                        )}
                        {(plans || plans2) && (
                            <Col>
                                {!isPlanModalVisible ? (
                                    <StyledBoxPlotOutlined onClick={() => setIsPlanModalVisible(true)} />
                                ) : (
                                    <StyledBoxPlotFilled onClick={() => setIsPlanModalVisible(false)} />
                                )}
                            </Col>
                        )}
                        {((news && i18n.language === 'tr') || (news2 && i18n.language === 'tr')) && (
                            <Col>
                                {!isPlanModalVisible ? (
                                    <StyledReadOutlined onClick={() => setIsNewsModalVisible(true)} />
                                ) : (
                                    <StyledReadFilled onClick={() => setIsNewsModalVisible(false)} />
                                )}
                            </Col>
                        )}
                        {dubsrcTR && (
                            <Col style={{ height: '27px' }} className="flex-row">
                                {isDubPlaying ? (
                                    <StyledSoundFilled onClick={() => handleDubSoundPlayClicked()} />
                                ) : (
                                    <StyledSoundOutlined onClick={() => handleDubSoundPlayClicked()} />
                                )}
                                <Lottie isPaused={!isDubPlaying} options={defaultOptions} isClickToPauseDisabled height={32} width={32} />
                            </Col>
                        )}
                    </Row>
                </RightSide>
            </StyledInfoCard>
            {i18n.language === 'en' ? (
                <audio key={i18n.language} ref={audioRef}>
                    <source src={dubsrcEN} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            ) : (
                <audio key={i18n.language} ref={audioRef}>
                    <source src={dubsrcTR} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            )}

            <PhotoModal
                key={`${nameTR}-photomodal-planmodal`}
                documents={
                    isPhotoModalVisible
                        ? photos || photos2
                        : isPlanModalVisible
                        ? plans || plans2
                        : isNewsModalVisible && i18n.language === 'tr' && (news || news2)
                }
                plans={plans}
                isPhotoModalVisible={isPhotoModalVisible || isPlanModalVisible || isNewsModalVisible}
                handleCancel={handleCancel}
            />
        </Wrapper>
    );
};

export default InfoCard;

/* <QuestionCircleOutlined /> */
