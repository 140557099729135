import React from 'react';
import ButtonStyles from './styles';
import { Text } from '../Typography/styles';

const Button = (props) => {
    // Buttons without icons : primary,secondary

    const { type, children, hovertextcolor, textcolor, textsize, letterSpacing, weight, buttonWidth } = props;

    return (
        <ButtonStyles buttonWidth styles={type} hovertextcolor={hovertextcolor} {...props}>
            {children && (
                <Text className="inner-text" weight={weight} size={textsize} disableselect="true" color={textcolor} letterSpacing={letterSpacing}>
                    {children}
                </Text>
            )}
        </ButtonStyles>
    );
};

export default Button;
