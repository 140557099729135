import styled from 'styled-components';
import { between } from 'polished';
import Layout from '../index';

const { Footer } = Layout;

export default styled(Footer)`
    /* height: ${({ theme }) => theme.layoutStyles.footerHeight}; */
    background-color: ${({ theme }) => theme.layoutStyles.footerColor};
    width: 100%;
    padding: 0px;
`;
