export default [
    {
        id: 1,
        family: 'Bahar',
        color: 'rgba(20, 63, 34, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Bahar',
                lat: '39.923384',
                lng: '32.855039',
            },
            {
                id: 'm-2',
                name: 'Aron Bahar',
                lat: '39.924372',
                lng: '32.854091',
            },
            {
                id: 'm-3',
                name: 'Beki L. Bahar',
                lat: '39.925339',
                lng: '32.855383',
            },
            {
                id: 'm-4',
                name: 'Davit Bahar ',
                lat: '39.924677',
                lng: '32.855706',
            },
            {
                id: 'm-5',
                name: 'Eliza Bahar Berkün',
                lat: '39.923330',
                lng: '32.855336',
            },
            {
                id: 'm-6',
                name: 'Ester Bahar',
                lat: '39.923212',
                lng: '32.854997',
            },
            {
                id: 'm-7',
                name: 'Gerşon Bahar',
                lat: '39.924252',
                lng: '32.854042',
            },
            {
                id: 'm-8',
                name: 'Hayim Bahar',
                lat: '39.924735',
                lng: '32.853706',
            },
            {
                id: 'm-9',
                name: 'İzak Bahar',
                lat: '39.922629',
                lng: '32.853901',
            },
            {
                id: 'm-10',
                name: 'İzzet İ. Bahar',
                lat: '39.923387',
                lng: '32.854177',
            },
            {
                id: 'm-11',
                name: 'Mordo Bahar ',
                lat: '39.924344',
                lng: '32.854948',
            },
            {
                id: 'm-12',
                name: 'Rezzan R. Bahar (Özsafarati)',
                lat: '39.923028',
                lng: '32.854763',
            },
            {
                id: 'm-13',
                name: 'Rıfat Bahar',
                lat: '39.922617',
                lng: '32.855074',
            },
            {
                id: 'm-14',
                name: 'Salamon Bahar Hiya',
                lat: '39.923781',
                lng: '32.853799',
            },
            {
                id: 'm-15',
                name: 'Sami Bahar',
                lat: '39.923330',
                lng: '32.852459',
            },
            {
                id: 'm-16',
                name: 'Samuel Bahar',
                lat: '39.924746',
                lng: '32.855051',
            },
            {
                id: 'm-17',
                name: 'Sara Bahar',
                lat: '39.923925',
                lng: '32.853833',
            },
            {
                id: 'm-18',
                name: 'Sarah Bahar',
                lat: '39.922587',
                lng: '32.852793',
            },
            {
                id: 'm-19',
                name: 'Tamara Bahar',
                lat: '39.923998',
                lng: '32.853958',
            },
            {
                id: 'm-20',
                name: 'Türkan Bahar',
                lat: ' 39.924609',
                lng: '32.853090',
            },
            {
                id: 'm-21',
                name: 'Vitorya Bahar ',
                lat: '39.924554',
                lng: '32.855276',
            },
            {
                id: 'm-22',
                name: 'Yusuf (Josef) Bahar',
                lat: '39.925326',
                lng: '32.854802',
            },
        ],
    },
    {
        id: 2,
        family: 'Berk',
        color: 'rgba(96, 131, 183, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Deniz Berk',
                lat: '39.924283',
                lng: '32.855437',
            },
            {
                id: 'm-2',
                name: 'Rıfat Berk',
                lat: '39.923143',
                lng: '32.854989',
            },
            {
                id: 'm-3',
                name: 'Zeki Berk',
                lat: '39.923941',
                lng: '32.853839',
            },
        ],
    },
    {
        id: 3,
        family: 'Day',
        color: 'rgba(68, 68, 132, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Klara Day',
                lat: '39.924365',
                lng: '32.853420',
            },
            {
                id: 'm-2',
                name: 'Sami Day',
                lat: '39.923777',
                lng: '32.852559',
            },
        ],
    },
    {
        id: 4,
        family: 'Hekim',
        color: 'rgba(107, 142, 153, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Levent Hekim',
                lat: '39.922023',
                lng: '32.853876',
            },
            {
                id: 'm-2',
                name: 'Liza Hekim Sulema',
                lat: '39.922023',
                lng: '32.853876',
            },
            {
                id: 'm-3',
                name: 'Yusuf Hekim',
                lat: '39.922414',
                lng: '32.854343',
            },
        ],
    },
    {
        id: 5,
        family: 'Marküs',
        color: 'rgba(84, 162, 187, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Marküs',
                lat: '39.922537',
                lng: '32.853871',
            },
            {
                id: 'm-2',
                name: 'Sevim Marküs',
                lat: '39.921912',
                lng: ' 32.854332',
            },
            {
                id: 'm-3',
                name: 'Sevinç Marküs',
                lat: '39.921871',
                lng: '32.853758',
            },
        ],
    },
    {
        id: 6,
        family: 'Blok',
        color: 'rgba(165, 184, 153, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İsrael Mitrani ',
                lat: '39.922372',
                lng: '32.854665',
            },
            {
                id: 'm-2',
                name: 'Marko Mitrani (Özdoğan)',
                lat: '39.921829',
                lng: '32.853823',
            },
            {
                id: 'm-3',
                name: 'Nesim Mitrani',
                lat: '39.922566',
                lng: '32.854391',
            },
        ],
    },
    {
        id: 7,
        family: 'Morhayim',
        color: 'rgba(87, 106, 75, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Morhayim',
                lat: '39.922126',
                lng: '32.854295',
            },
            {
                id: 'm-2',
                name: 'Ester Morhayim',
                lat: '39.924668',
                lng: '32.855380',
            },
            {
                id: 'm-3',
                name: 'Jak Morhayim',
                lat: '39.923634',
                lng: '32.853410',
            },
            {
                id: 'm-4',
                name: 'Leyla Morhayim',
                lat: '39.921747',
                lng: '32.853710',
            },
            {
                id: 'm-5',
                name: 'Sara Morhayim',
                lat: '39.922601',
                lng: '32.854802',
            },
        ],
    },
    {
        id: 8,
        family: 'Namer',
        color: 'rgba(171, 134, 113, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Avram Namer',
                lat: ' 39.922595',
                lng: '32.854413',
            },
            {
                id: 'm-2',
                name: 'İsrael Namer',
                lat: '39.922619',
                lng: '32.853946',
            },
            {
                id: 'm-3',
                name: 'İzzet Namer',
                lat: '39.921912',
                lng: '32.854091',
            },
            {
                id: 'm-4',
                name: 'Leon (Yuda) Namer',
                lat: '39.924316',
                lng: '32.854609',
            },
            {
                id: 'm-5',
                name: 'Esti Namer',
                lat: '39.923589',
                lng: '32.854091',
            },
            {
                id: 'm-6',
                name: 'Işık Namer',
                lat: '39.923362',
                lng: '32.854965',
            },
            {
                id: 'm-3',
                name: 'Selim Namer',
                lat: '​​39.921866',
                lng: '32.855126',
            },
        ],
    },
    {
        id: 9,
        family: 'Özdamar',
        color: 'rgba(241, 223, 187, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Hiyaçi Özdamar',
                lat: '39.921899',
                lng: '32.854359',
            },
            {
                id: 'm-2',
                name: 'Kaya Özdamar',
                lat: '39.921821',
                lng: '32.853764',
            },
            {
                id: 'm-3',
                name: 'Mayıraçı Özdamar',
                lat: '39.922105',
                lng: '32.853850',
            },
            {
                id: 'm-4',
                name: 'Rebeka Özdamar',
                lat: '39.922578',
                lng: '32.854391',
            },
        ],
    },
    {
        id: 10,
        family: 'Özşahin',
        color: 'rgba(183, 173, 155, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Ceni Özşahin Berkp',
                lat: '39.922397',
                lng: '32.853501',
            },
            {
                id: 'm-2',
                name: 'İvet Özşahin Mizrahi',
                lat: '39.921957',
                lng: '32.854279',
            },
            {
                id: 'm-3',
                name: 'Sara Özşahin Tabuman',
                lat: '39.923952',
                lng: '32.854047',
            },
        ],
    },
    {
        id: 11,
        family: 'Sivil',
        color: 'rgba(122, 117, 107, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Davit Sivil',
                lat: '39.922479',
                lng: '32.853093',
            },
            {
                id: 'm-2',
                name: 'Sami Sivil',
                lat: '39.923237',
                lng: '32.855009',
            },
            {
                id: 'm-3',
                name: 'Şemuel Sivil ',
                lat: '39.922464',
                lng: '32.853543',
            },
        ],
    },
    {
        id: 14,
        family: 'Siyalon',
        color: 'rgba(28, 20, 4, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İzak Siyalon',
                lat: '39.923328',
                lng: '32.854950',
            },
            {
                id: 'm-2',
                name: 'Jak Siyalon',
                lat: ' 39.921949',
                lng: '32.853772',
            },
        ],
    },
    {
        id: 15,
        family: 'Yolak',
        color: 'rgba(67, 46, 5, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Baruh Yolak',
                lat: '39.922502',
                lng: '32.854594',
            },
            {
                id: 'm-2',
                name: 'Çele Mizrahi Yolak',
                lat: '39.922197',
                lng: '32.853861',
            },
            {
                id: 'm-3',
                name: 'Renata Sibel Yolak',
                lat: '39.921803',
                lng: '32.854432',
            },
            {
                id: 'm-4',
                name: 'Betül Betina Yolak',
                lat: '39.9254740',
                lng: '32.855231',
            },
        ],
    },
    {
        id: 16,
        family: 'Yücedağ',
        color: 'rgba(147, 105, 21, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Marko Yücedağ',
                lat: '39.922502',
                lng: '32.854594',
            },
            {
                id: 'm-2',
                name: 'Mordo Yücedağ',
                lat: '39.922197',
                lng: '32.853861',
            },
            {
                id: 'm-3',
                name: 'Leyla Lili Yücedağ',
                lat: '39.921803',
                lng: '32.854432',
            },
            {
                id: 'm-4',
                name: 'Yaşar Ben Mordehay',
                lat: '39.926088',
                lng: '32.855367',
            },
        ],
    },
    {
        id: 17,
        family: 'Yürüm',
        color: 'rgba(53, 171, 34, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Joya Yürüm',
                lat: '39.923589',
                lng: '32.854017',
            },
            {
                id: 'm-2',
                name: 'Öjeni Yürüm',
                lat: '39.922294',
                lng: '32.853662',
            },
            {
                id: 'm-3',
                name: 'Parla Yürüm',
                lat: '39.922110',
                lng: '32.853891',
            },
            {
                id: 'm-4',
                name: 'Yuda Yürüm',
                lat: '39.924486',
                lng: '32.854076',
            },
        ],
    },
    {
        id: 18,
        family: 'Kişiler',
        color: 'rgba(174, 127, 30, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Karasu',
                lat: '39.922459',
                lng: '32.853984',
            },
            {
                id: 'm-2',
                name: 'Aşer Kalvo',
                lat: '39.922164',
                lng: ' 32.854311',
            },
            {
                id: 'm-3',
                name: 'Beki Hadora',
                lat: '39.925573',
                lng: '32.854972',
            },
            {
                id: 'm-4',
                name: 'Beki Perehya',
                lat: '39.921958',
                lng: '32.854287',
            },
            {
                id: 'm-5',
                name: 'Beto Benrubi',
                lat: '39.921511',
                lng: '32.853809',
            },
            {
                id: 'm-6',
                name: 'Bohoruça Aktekin',
                lat: '39.922624',
                lng: '32.853576',
            },
            {
                id: 'm-7',
                name: 'Davit Roditi',
                lat: '39.925604',
                lng: '32.854644',
            },
            {
                id: 'm-8',
                name: 'Davud Benmayor',
                lat: '39.925513',
                lng: '32.854893',
            },
            {
                id: 'm-9',
                name: 'Eli Altabes',
                lat: '39.922290',
                lng: '32.853523',
            },
            {
                id: 'm-10',
                name: 'Eli Pinto',
                lat: '39.925705',
                lng: '32.855591',
            },
            {
                id: 'm-11',
                name: 'Eli Şoef',
                lat: '39.921757',
                lng: '32.853792',
            },
            {
                id: 'm-12',
                name: 'Ester Kyra',
                lat: '39.925049',
                lng: '32.853999',
            },
            {
                id: 'm-13',
                name: 'Ester Marguez',
                lat: '39.921391',
                lng: '32.853765',
            },
            {
                id: 'm-14',
                name: 'Ester Matalon',
                lat: '39.924770',
                lng: '32.855306',
            },
            {
                id: 'm-15',
                name: 'Camila Nine',
                lat: '39.925352',
                lng: '32.8550256',
            },
            {
                id: 'm-15',
                name: 'Fis ailesi',
                lat: '39.924134',
                lng: '32.853721',
            },
            {
                id: 'm-16',
                name: 'Gavriel Badi',
                lat: '39.921411',
                lng: '32.854025',
            },
            {
                id: 'm-17',
                name: 'Habib Gerez',
                lat: '39.921500',
                lng: '32.854296',
            },
            {
                id: 'm-18',
                name: 'İsak Özbarış',
                lat: '39.921730',
                lng: '32.854511',
            },
            {
                id: 'm-19',
                name: 'İshak İshaki',
                lat: '39.921844',
                lng: '32.854673',
            },
            {
                id: 'm-20',
                name: 'İzak Lombrozo',
                lat: '39.922145',
                lng: '32.854664',
            },
            {
                id: 'm-21',
                name: 'Jerry Barad',
                lat: '39.922398',
                lng: '32.854878',
            },
            {
                id: 'm-22',
                name: 'Josef Nasi',
                lat: '39.922597',
                lng: ' 32.854691',
            },
            {
                id: 'm-23',
                name: 'La Tiya Beya (Beya Teyze)',
                lat: '39.922135',
                lng: '32.853338',
            },
            {
                id: 'm-24',
                name: 'Leonora Reytan',
                lat: '39.924378',
                lng: '32.855378',
            },
            {
                id: 'm-25',
                name: 'Camila Nine',
                lat: '39.920975',
                lng: '32.853989',
            },
            {
                id: 'm-26',
                name: 'Liya (El-rufyiko)',
                lat: '39.924040',
                lng: '32.855476',
            },
            {
                id: 'm-27',
                name: 'Lombrozo',
                lat: '39.923664',
                lng: '32.855538',
            },
            {
                id: 'm-28',
                name: 'Maretta Altaras',
                lat: '39.923063',
                lng: '32.855391',
            },
            {
                id: 'm-29',
                name: 'Marko Saharof',
                lat: '39.924998',
                lng: '32.854276',
            },
            {
                id: 'm-30',
                name: 'Mile Palaçi',
                lat: '39.924641',
                lng: '32.853737',
            },
            {
                id: 'm-31',
                name: 'Moiz Galante',
                lat: '39.924294',
                lng: '32.853773',
            },
            {
                id: 'm-32',
                name: 'Moiz Şenar',
                lat: '39.923955',
                lng: '32.853589',
            },
            {
                id: 'm-33',
                name: 'Motola',
                lat: '39.924101',
                lng: '32.853802',
            },
            {
                id: 'm-34',
                name: 'Nisim Saban',
                lat: '39.922668',
                lng: '32.853357',
            },
            {
                id: 'm-35',
                name: 'Papuşado',
                lat: '39.923354',
                lng: '32.853050',
            },
            {
                id: 'm-36',
                name: 'Püler (Madam Püler)',
                lat: '39.922734',
                lng: ' 32.852769',
            },
            {
                id: 'm-37',
                name: 'Rafael Faradji',
                lat: '39.922734',
                lng: '32.852769',
            },
            {
                id: 'm-38',
                name: 'Rafael Öz',
                lat: '39.926078',
                lng: '32.855078',
            },
            {
                id: 'm-39',
                name: 'Rebeka Karako',
                lat: '39.923298',
                lng: '32.855820',
            },
            {
                id: 'm-40',
                name: 'Rifat Bali',
                lat: '39.923391',
                lng: '32.853467',
            },
            {
                id: 'm-41',
                name: 'Samuel Abravanel',
                lat: '39.922452',
                lng: '32.853393',
            },
            {
                id: 'm-42',
                name: 'Samuel Amon',
                lat: '3 39.923363',
                lng: '32.853124',
            },
            {
                id: 'm-43',
                name: 'Sebetay Galimidi',
                lat: '39.922978',
                lng: '32.854888',
            },
            {
                id: 'm-44',
                name: 'Yuda Sulema',
                lat: '39.922386',
                lng: '32.855256',
            },
            {
                id: 'm-45',
                name: 'Yusuf İzak',
                lat: '39.922489',
                lng: '32.855024',
            },
            {
                id: 'm-46',
                name: 'Samuel Abravanel',
                lat: '39.922452',
                lng: '32.853393',
            },
            {
                id: 'm-47',
                name: 'Yusuf Selami Gabay',
                lat: '39.922193',
                lng: '32.855155',
            },
            {
                id: 'm-48',
                name: 'Zali Farhi',
                lat: '39.924353',
                lng: '32.854341',
            },
            {
                id: 'm-49',
                name: 'Erol Güney Akşiyote ',
                lat: '39.923782',
                lng: '32.853706',
            },
        ],
    },
];
