import styled from 'styled-components';
import { Carousel, Row } from 'antd';

export const StyledCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;

    .slick-slider {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
        div {
            height: 100%;
        }
    }

    .ant-image-mask-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .slick-prev {
        left: 10px;
        z-index: 60;
    }

    .slick-next {
        right: 20px;
        bottom: 3px;
        z-index: 60;
    }

    .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-image {
        height: 85% !important;
    }
    .ant-image-mask {
        height: 85%;
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }

    .ant-image-img {
        object-fit: ${({ objectFit }) => (objectFit ? 'contain' : 'cover')};
        height: 100% !important;
        object-position: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const TextWrapper = styled(Row)`
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: scroll;
    width: 100%;
    z-index: 3000;
    height: 15% !important;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
`;
