import PageWrapper from '@Components/PageWrapper';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';

export default styled(PageWrapper)`
    background: #252525;
    height: auto;
    padding-left: ${between('0px', '60px')};
    padding-right: ${between('0px', '60px')};
    padding-top: ${between('120px', '180px')};

    .padding-reset {
        padding: 0px !important;
    }

    .padding-horizontal {
        padding-left: ${between('0px', '60px')};
        padding-right: ${between('0px', '60px')};
    }

    .w-100 {
        width: 100%;
    }

    .mb-semi-sm {
        margin-bottom: 6vh;
    }

    .mb-xs {
        margin-bottom: 3vh;
    }

    .mb-sm {
        margin-bottom: 10vh;
    }
    .mb-md {
        margin-bottom: 20vh;
    }

    .pb-sm {
        padding-bottom: 15vh;
    }
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
    border-radius: 20px;
`;
