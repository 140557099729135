/* eslint-disable react/no-this-in-sfc */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@Components/Typography/styles';
import { Col, Row } from 'antd';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import { useTranslation } from 'react-i18next';
import { Pannellum } from 'pannellum-react';
import useResponsive from '@Hooks/useMediaQuery';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';

import SwiperCore, { Navigation, Pagination, EffectFade, Mousewheel } from 'swiper';
import Carousel from '@Components/Carousel';
import StyledMultiSchooledPage, { Banner, Img, TourWrapper } from './styles';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

const Content360 = () => {
    const { t, i18n } = useTranslation('common');
    const panellumRef = useRef('panellum-ref');
    const [forceRender, setForceRender] = useState(false);

    useEffect(() => {
        panellumRef.current.forceRender();
    }, [t]);

    const scenes = [
        {
            id: 0,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider360-1.jpg?x-request=html&alt=media&token=9ea0d28e-0ae8-446f-8760-c4705d55eee4',

            hotspots: [
                {
                    id: 1,
                    type: 'info',
                    yaw: 400,
                    pitch: 8.41,
                    textTR: 'Günümüzde Anafartalar Lisesi olan yapının yerinde Rum Kızlar Okulu, Rum Erkekler Okulu ve Rum Anaokulu bulunmaktaydı.',
                    textEN: 'The Greek School for Boys, The Greek School for Girls and The Greek Kindergarten were located in the place of the building, which is now Anafartalar High School.',
                },
                {
                    id: 2,
                    type: 'navigation',
                    yaw: 375,
                    pitch: -2.41,
                    to: 1,
                },
            ],
        },
        {
            id: 1,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider360-2.jpg?alt=media&token=9ef1a7cc-7da3-478d-99a0-0827e897c4e2',
            hotspots: [
                {
                    id: 3,
                    type: 'info',
                    yaw: 257.76,
                    pitch: 8.41,
                    textTR: 'Günümüzde polis kulübesi olarak görülen yapı, geçmişte “Ravza-i Terakki Mektebi” adıyla da eğitim veren Türk Musevi Mektebi binasıdır.',
                    textEN: "The building, which is seen as a police station today, is the Turkish Jewish School building, which gave education under the name of 'Ravza-i Terakki School' in the past.",
                },
                {
                    id: 4,
                    type: 'navigation',
                    yaw: 335.76,
                    pitch: 4.41,
                    to: 2,
                },
                {
                    id: 5,
                    type: 'navigation',
                    yaw: 400.76,
                    pitch: -4.41,
                    to: 0,
                },
            ],
        },
        {
            id: 2,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider360-3.jpg?alt=media&token=6987c3e0-2f2b-44f8-a07c-128b379f58c3',
            hotspots: [
                {
                    id: 6,
                    type: 'info',
                    yaw: 257.76,
                    pitch: 8.41,
                    textTR: 'Günümüzde Sakalar İlkokulu’nun bulunduğu alanda, geçmişte İstiklal İlkokulu, Albayrak İlkokulu ve Özel Işık İlkokulu - Hususi Bizim Mektep adlarında, yan yana üç okul olduğu bilinmektedir.',
                    textEN: 'It is known that in the area where Sakalar Primary School is located today, there were three schools side by side in the past, namely Istiklal Primary School, Albayrak Primary School and Private Işık Primary School - Hususi Bizim Mektep.',
                },
                {
                    id: 7,
                    type: 'navigation',
                    yaw: 205.76,
                    pitch: -2.41,
                    to: 3,
                },
                {
                    id: 8,
                    type: 'navigation',
                    yaw: 370.76,
                    pitch: 4.41,
                    to: 1,
                },
            ],
        },
        {
            id: 3,
            sceneURL:
                'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider360-4.jpg?alt=media&token=a93f3f08-10eb-47ac-96d4-98c3e5b221b7',
            hotspots: [
                {
                    id: 9,
                    type: 'info',
                    yaw: 257.76,
                    pitch: 8.41,
                    textTR: 'Geçmişte “Ravza-i Terakki Mektebi Kızlar Okulu” adıyla da eğitim veren okul binası',
                    textEN: "The school building of 'Ravza-i Terakki School for Girls'",
                },
                {
                    id: 10,
                    type: 'navigation',
                    yaw: 370.76,
                    pitch: 4.41,
                    to: 0,
                },
                {
                    id: 11,
                    type: 'navigation',
                    yaw: -220.76,
                    pitch: 4.41,
                    to: 2,
                },
            ],
        },
    ];

    const [currentScene, setCurrentScene] = useState(scenes[0]);

    return (
        <Pannellum
            ref={panellumRef}
            forceRender={forceRender}
            width="100%"
            height="100vh"
            image={currentScene.sceneURL}
            pitch={10}
            yaw={0}
            hfov={110}
            autoRotate={5}
            autoLoad
            mouseZoom={false}
            autoRotateInactivityDelay={2}
            showZoomCtrl={false}>
            {currentScene.hotspots.map((item) =>
                item.type === 'navigation' ? (
                    <Pannellum.Hotspot
                        type="custom"
                        key={item.id}
                        pitch={item.pitch}
                        yaw={item.yaw}
                        handleClick={() => setCurrentScene(scenes[item.to])}
                    />
                ) : (
                    <Pannellum.Hotspot
                        type="info"
                        key={item.id}
                        text={i18n.language === 'tr' ? item.textTR : item.textEN}
                        pitch={item.pitch}
                        yaw={item.yaw}
                    />
                )
            )}
        </Pannellum>
    );
};

const MultiSchooledPage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();
    SwiperCore.use([EffectFade, Pagination, Navigation]);

    const carouselItems = [
        {
            id: 'ci-1',
            url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider-1.jpg?alt=media&token=c8391c26-71c5-42f7-880d-12e591d30240',
            imgAltTR:
                'Kaynağında 1927 yılına tarihlenen MB rumuzlu (Moughamian Biraderler veya Memduh Biraderler) fotoğraf, Ermeni Katolik Kilisesi mezarlığından Bedesten (Anadolu Medeniyetleri Müzesi) yönüne doğru çekilmiştir. Bu yer günümüzde ‘Ankara Mesleki ve Teknik Anadolu Lisesi’ (eski Ankara Ticaret Lisesi) yakınına denk gelmektedir. Fotoğrafta solda İtfaiye Meydanı tarafı görülürken sağda küçük bir bölüm halinde Yahudi Mahallesi yer almaktadır.',
            imgAltDescTR: '[Atila Cangır Arşivi, Uğur Tunalı Koleksiyonu, “Cumhuriyetin Başkenti”, Cilt I, Seri 6 – Foto: M.B Fotoğraf No: 213]',
            imgAltEN:
                'The photograph with the pseudonym M.B. (Moughamian Brothers or Memduh Brothers), which is dated to 1927, was taken from the Armenian Catholic Church cemetery in the direction of Bedesten (Museum of Anatolian Civilizations). Today, this place coincides with the Ankara Technical Anatolian High School. There is a small section of the Jewish Quarter seen on the right side of the photo.',
            imgAltDescEN:
                '[Atila Cangır Archive, Uğur Tunalı Collection, “The Capital of The Republic”, Volume I, Series  – Photo M.B., Photo No: 213, 1927]',
        },
        {
            id: 'ci-2',
            url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider-2.jpg?alt=media&token=328003aa-6576-47ce-9401-16e89c3f7f27',
            imgAltTR:
                'Kaynağında 1927 yılına tarihlenen MB rumuzlu (Moughamian Biraderler veya Memduh Biraderler) fotoğraf, Ermeni Katolik Kilisesi mezarlığından Bedesten (Anadolu Medeniyetleri Müzesi) yönüne doğru çekilmiştir. Bu yer günümüzde ‘Ankara Mesleki ve Teknik Anadolu Lisesi’ (eski Ankara Ticaret Lisesi) yakınına denk gelmektedir. Fotoğrafta solda İtfaiye Meydanı tarafı görülürken sağda küçük bir bölüm halinde Yahudi Mahallesi yer almaktadır.',
            imgAltDescTR: '[Atila Cangır Arşivi, Uğur Tunalı Koleksiyonu, “Cumhuriyetin Başkenti”, Cilt I, Seri 6 – Foto: M.B Fotoğraf No: 213]',
            imgAltEN:
                'The photograph with the pseudonym M.B. (Moughamian Brothers or Memduh Brothers), which is dated to 1927, was taken from the Armenian Catholic Church cemetery in the direction of Bedesten (Museum of Anatolian Civilizations). Today, this place coincides with the Ankara Technical Anatolian High School. There is a small section of the Jewish Quarter seen on the right side of the photo.',
            imgAltDescEN:
                '[Atila Cangır Archive, Uğur Tunalı Collection, “The Capital of The Republic”, Volume I, Series  – Photo M.B., Photo No: 213, 1927]',
        },
        {
            id: 'ci-3',
            url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider-3.jpg?alt=media&token=f10880ea-f503-4be5-a349-73b1e966ae39',
            imgAltTR:
                'Resim ve Heykel Müzesi’nin bulunduğu alandan çekilmiş olan fotoğrafta Denizciler Caddesi ve Yahudi Mahallesi’nin kuzey tarafı görünmektedir. ',
            imgAltDescTR:
                '[Atila Cangır Arşivi, Uğur Tunalı Koleksiyonu, “Cumhuriyetin Başkenti”, Cilt II, Seri 24 – Editör ve Fotoğrafçısı Bilinmeyen Kartlar, Fotoğraf No: 1485, 1937]',
            imgAltEN:
                'The photograph, taken from the area where the Ankara Art and Sculpture Museum is located today, shows Denizciler Street and the north side of the Jewish Quarter.',
            imgAltDescEN:
                '[Atila Cangır Archive, Uğur Tunalı Collection, “The Capital of The Republic”, Volume II, Series 24 – Cards by Unknown Editor and Photographer, Photo No: 1485, 1937]',
        },
        {
            id: 'ci-4',
            url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fslider-4.jpg?alt=media&token=dcd2daab-5c02-4bf6-8cb9-606305bc699b',
            imgAltTR:
                'Resim ve Heykel Müzesi’nin bulunduğu alandan çekilmiş olan fotoğrafta Denizciler Caddesi ve Yahudi Mahallesi’nin kuzey tarafı görünmektedir. ',
            imgAltDescTR:
                '[Atila Cangır Arşivi, Uğur Tunalı Koleksiyonu, “Cumhuriyetin Başkenti”, Cilt II, Seri 24 – Editör ve Fotoğrafçısı Bilinmeyen Kartlar, Fotoğraf No: 1485, 1937]',
            imgAltEN:
                'The photograph, taken from the area where the Ankara Art and Sculpture Museum is located today, shows Denizciler Street and the north side of the Jewish Quarter.',
            imgAltDescEN:
                '[Atila Cangır Archive, Uğur Tunalı Collection, “The Capital of The Republic”, Volume II, Series 24 – Cards by Unknown Editor and Photographer, Photo No: 1485, 1937]',
        },
    ];

    return (
        <StyledMultiSchooledPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.multiSchooled')}</title>
                <link rel="canonical" href={canonicals.multiSchooled} />
            </Helmet>
            <Banner
                sm={sm}
                className="padding-horizontal padding-vertical "
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs1.png?alt=media&token=5377a39d-803b-49fe-9191-0e83d50679d0">
                <Col className="vertical-sb" span={24}>
                    <div>
                        <Row>
                            <Text family="secondary" size="96px" color="light" weight="700">
                                {t('aos.multischooled.section1.title')}
                            </Text>
                        </Row>
                        <Row className="banner-pl-sm">
                            <Text family="secondary" size="96px" color="light" weight="700">
                                שכונה מרובת בתי ספר
                            </Text>
                        </Row>
                        <Row className="banner-pl-md">
                            <Text family="secondary" size="96px" color="light" weight="700">
                                διαπολιτισμική εκπαίδευση
                            </Text>
                        </Row>
                    </div>
                    <Row align="middle" justify="center">
                        <Text size="36px" color="light" weight="600">
                            {t('aos.multischooled.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row align="middle" justify="start" className="padding-horizontal mb-small">
                <Col>
                    <Text displayblock className="pt-img-alt" color="grey" weight="400">
                        {t('aos.multischooled.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section1.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[0, { sm: 32, md: 32 }]}>
                <Col span={24}>
                    <Row gutter={[24, 24]}>
                        <Col className={sm ? 'vertical-end pr-reset' : 'vertical-end'} sm={24} md={10}>
                            <Img
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs2-1.png?alt=media&token=5e8de2fd-2130-4784-87cf-b592533f94d4"
                                alt={t('aos.multischooled.section2.imgAlt')}
                            />
                        </Col>
                        <Col className={sm && 'pl-reset pr-reset'} sm={24} md={7}>
                            <Img
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs2-2.png?alt=media&token=4fe135e6-6081-4dc3-86f7-fe82cb9ee61f"
                                alt={t('aos.multischooled.section2.imgAlt')}
                            />
                        </Col>
                        <Col className={sm ? 'vertical-sa pl-reset' : 'vertical-sa'} sm={24} md={7}>
                            <Row gutter={[16, 16]}>
                                <Col span={1} style={{ backgroundColor: '#F8240E' }} />
                                <Col span={23}>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="600">
                                            {t('aos.multischooled.section2.legend.red.name')}
                                        </Text>
                                    </Row>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="400">
                                            {t('aos.multischooled.section2.legend.red.description')}
                                        </Text>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={1} style={{ backgroundColor: '#418E08' }} />
                                <Col span={23}>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="600">
                                            {t('aos.multischooled.section2.legend.green.name')}
                                        </Text>
                                    </Row>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="400">
                                            {t('aos.multischooled.section2.legend.green.description')}
                                        </Text>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={1} style={{ backgroundColor: '#ED28B5' }} />
                                <Col span={23}>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="600">
                                            {t('aos.multischooled.section2.legend.pink.name')}
                                        </Text>
                                    </Row>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="400">
                                            {t('aos.multischooled.section2.legend.pink.description')}
                                        </Text>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={1} style={{ backgroundColor: '#554B94' }} />
                                <Col span={23}>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="600">
                                            {t('aos.multischooled.section2.legend.purple.name')}
                                        </Text>
                                    </Row>
                                    <Row align="start" justify="start">
                                        <Text color="light" weight="400">
                                            {t('aos.multischooled.section2.legend.purple.description')}
                                        </Text>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pt-img-alt">
                        <Text color="grey" weight="300">
                            {t('aos.multischooled.section2.imgAlt')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section2.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <TourWrapper sm={sm}>
                    <Content360 />
                </TourWrapper>
            </Row>
            <Banner
                sm={sm}
                className="banner-pt"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs3.jpg?alt=media&token=7c0fdb99-b839-447c-8149-50e0e5f1b0a9">
                <Col span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.multischooled.section3.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="mb-small padding-horizontal pt-img-alt">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section3.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section3.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section3.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col className="eqh-wrapper" sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs4-1.jpg?alt=media&token=f32f90a7-4e62-456f-b75d-0479846d0809"
                            />
                            <Row className="pt-img-alt eqh-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section4.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section4.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs4-2.jpg?alt=media&token=e4c7a55f-6bd5-4ca8-b7c6-3b5816fbe726"
                            />
                            <Row className="pt-img-alt eqh-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section4.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section4.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs5.jpg?alt=media&token=bdc321c3-dd41-4a6f-96ee-d38380593e78"
            />
            <Row className="padding-horizontal pt-img-alt mb-small">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section5.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section5.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section5.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal" gutter={[12, 12]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row gutter={[12, 12]}>
                        <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs6-1.jpg?alt=media&token=3b65fcc1-df30-4740-863a-e47ed388eb41"
                            />
                        </Col>
                        <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs6-2.jpg?alt=media&token=4bb6318b-1c71-450b-8254-ed69a4f0721a"
                            />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '12px' }}>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs6-4.jpg?alt=media&token=f06526b9-8277-4ca5-9b30-cb8a7bb30858" />
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row className={!sm && 'h-100'}>
                        <Col span={24}>
                            <Img
                                className={!sm && 'h-100'}
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs6-3.jpg?alt=media&token=2fe428ec-6879-433b-a00d-a775ac884400"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-medium">
                <Col sm={24} md={12}>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section6.imgAlt1')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section6.imgAlt1Desc')}
                    </Text>
                    <Text displayblock color="grey" className="pt-img-alt">
                        {t('aos.multischooled.section6.imgAlt2')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section6.imgAlt2Desc')}
                    </Text>
                </Col>
                <Col sm={24} md={12}>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section6.imgAlt3')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section6.imgAlt3Desc')}
                    </Text>
                    <Text displayblock color="grey" className="pt-img-alt">
                        {t('aos.multischooled.section6.imgAlt4')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section6.imgAlt4Desc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-medium">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section6.text')}
                </Text>
            </Row>
            {/* Check Image numbers! */}
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[32, 32]}>
                        <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs7-1.jpg?alt=media&token=7fad0c6b-e597-4e47-a61c-92a592a6ad06"
                            />
                            <Row className="pt-img-alt eqh-text">
                                <Text displayblock color="grey">
                                    {t('aos.multischooled.section7.imgAlt1')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.multischooled.section7.imgAlt1Desc')}
                                </Text>
                            </Row>
                        </Col>
                        <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs7-2.jpg?alt=media&token=66886156-6876-4122-b24e-993727668b46"
                            />
                            <Row className="pt-img-alt eqh-text">
                                <Text displayblock color="grey">
                                    {t('aos.multischooled.section7.imgAlt2')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.multischooled.section7.imgAlt2Desc')}
                                </Text>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[32, 32]} style={{ paddingTop: '32px' }}>
                        <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs7-3.jpg?alt=media&token=75924648-3925-4bfa-a5e5-84248b6a4bc1"
                            />
                            <Row className="pt-img-alt eqh-text" style={{ flex: '0 1 80px' }}>
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section7.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section7.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>

                        <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs7-4.jpg?alt=media&token=3c0e6cbd-c810-48ca-b578-4bb9f88f2222"
                            />
                            <Row className="pt-img-alt eqh-text" style={{ flex: '0 1 80px' }}>
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section7.imgAlt4')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section7.imgAlt4Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs8.jpg?alt=media&token=1a596904-4647-4111-8e76-1c8089f30e9f">
                <Col className="banner-pt" span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.multischooled.section8.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-medium">
                <Text style={{ whiteSpace: ' pre-line' }} displayblock color="grey">
                    {t('aos.multischooled.section8.imgAlt')}
                </Text>
                <Text displayblock color="grey" weight="300">
                    {t('aos.multischooled.section8.imgAltDesc')}
                </Text>
            </Row>
            <Row gutter={[32, 32]} className="padding-horizontal mb-small">
                <Col className={sm ? 'pr-reset vertical-center' : 'vertical-center'} sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.multischooled.section9.text')}
                    </Text>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row>
                        <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs9-1.jpg?alt=media&token=834e3e8e-361d-48ed-a52a-7d9516d3305c" />
                    </Row>
                    <Text className="pt-img-alt" displayblock color="grey">
                        {t('aos.multischooled.section9.imgAlt1')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[32, 32]}>
                        <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs9-2.png?alt=media&token=4717a92d-1988-42ef-95ed-bbc247056f39"
                            />
                            <Row className="pt-img-alt eqh-md-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section9.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section9.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={sm ? 'pl-reset vertical-center' : 'eqh-wrapper'} sm={24} md={12}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs9-3.png?alt=media&token=f2117e55-2232-424e-8dff-2bd92938cf42"
                            />
                            <Row className="pt-img-alt eqh-md-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section9.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section9.imgAltDesc3')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[32, 32]}>
                        <Col className={sm && 'pr-reset'} sm={24} md={12}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs10.png?alt=media&token=d7d026d4-142d-4843-91ae-f17c6dac88c1" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section10.imgAlt')}
                                    </Text>
                                    <Text className="pt-img-alt" color="grey" weight="300">
                                        {t('aos.multischooled.section10.imgAltDesc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={sm && 'pl-reset'} sm={24} md={12}>
                            <Text color="light" size="21px" weight="400">
                                {t('aos.multischooled.section10.text')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[32, 32]}>
                        <Col className={sm ? 'pr-reset vertical-center' : 'vertical-center'} sm={24} md={12}>
                            <Text color="light" size="21px" weight="400">
                                {t('aos.multischooled.section11.text')}
                            </Text>
                        </Col>
                        <Col className={sm && 'pl-reset'} sm={24} md={12}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs11.png?alt=media&token=8e993de6-95b2-44d7-a91b-c472e0a4b765" />
                            <Row className="pt-img-alt" align="middle" justify="start">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section11.imgAlt')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section11.imgAltDesc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xsmall">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section11sub.text')}
                </Text>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs12.jpg?alt=media&token=535479c9-7bdc-4ee3-b14d-ac80e31791ce">
                <Col className="vertical-end" sm={24} md={3}>
                    <div
                        className="overlay w-100"
                        style={{ borderTopRightRadius: '30px', paddingTop: '32px', paddingBottom: '16px', paddingLeft: '16px' }}>
                        <Row gutter={[16, 16]}>
                            <Col span={2} style={{ backgroundColor: '#F8240E' }} />
                            <Col span={22}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section12.red.name')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-img-alt" gutter={[16, 16]}>
                            <Col span={2} style={{ backgroundColor: '#418E08' }} />
                            <Col span={22}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section12.green.name')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-img-alt" gutter={[16, 16]}>
                            <Col span={2} style={{ backgroundColor: '#554B94' }} />
                            <Col span={22}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section12.blue.name')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Banner>
            <Row className="padding-horizontal mb-small">
                <Col>
                    <Text displayblock className="pt-img-alt" color="grey">
                        {t('aos.multischooled.section12.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section12.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row>
                        <Col sm={24} md={18}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs13-1.png?alt=media&token=57d48ada-eb8c-43a6-bce6-cd370b6c028f" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section13.imgAlt')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section13.imgAltDesc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pt-sm pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text displayblock className="mb-xsmall" size="32px" color="light">
                        {t('aos.multischooled.section13.title')}
                    </Text>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.multischooled.section13.text')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm ? 'pt-sm pr-reset' : 'pt-sm'} sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.multischooled.section14.text')}
                    </Text>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row align="middle" justify="center">
                        <Col sm={24} md={12}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs13-2.png?alt=media&token=b0970a48-d79b-4794-8f27-1b9ea8419a4f" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section14.imgAlt')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section14.imgAltDesc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs14.png?alt=media&token=78a2559f-c064-44cf-9ddf-9c74865f33e4">
                <Col className="banner-pt" span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.multischooled.section15.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section15.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section15.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className={sm ? 'padding-horizontal pt-sm mb-small' : 'padding-horizontal transform-top-sm'} gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.multischooled.section16.text')}
                    </Text>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row align="middle" justify="center">
                        <Col sm={24} md={20}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs15.png?alt=media&token=b60a1a42-db7b-42f2-94e7-26845e58c078" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section16.imgAlt')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section16.imgAltDesc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Col>
                    <Row gutter={[32, 32]}>
                        <Col className={sm && 'pr-reset'} sm={24} md={12}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs16-1.jpg?alt=media&token=9968dac2-0564-420f-9f08-6d7b3495f39a" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section17.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section17.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs16-2.jpg?alt=media&token=d03b8242-5590-405d-9ef2-7214993607a4" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.multischooled.section17.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.multischooled.section17.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row>
                        <Col sm={24} md={18}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs17-1.jpg?alt=media&token=3a6bb0ce-4ab1-4a07-a9f4-2abaed03b0db" />
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.multischooled.section18.text')}
                    </Text>
                </Col>
            </Row>
            <Row className={sm && 'pt-xs min-30vh'}>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs17-2.jpg?alt=media&token=d7653111-e045-4500-b802-700b538eadc2" />
            </Row>
            <Row className={sm ? 'padding-horizontal mb-small' : 'padding-horizontal transform-top-sm mb-small'} gutter={[32, 32]}>
                <Col order={sm ? 2 : 1} className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section19.imgAlt1Desc')}
                    </Text>
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section19.imgAlt2')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section19.imgAlt2Desc')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section19.imgAlt3Desc')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section19.imgAlt4Desc')}
                    </Text>
                </Col>
                <Col order={sm ? 1 : 2} className={sm && 'pl-reset pt-xs'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs17-3.png?alt=media&token=b8e030a5-a9c3-41cd-9e5a-5b2ba8e29b5b" />
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs18-1.jpg?alt=media&token=5c194e25-924e-4799-9690-a4e815f9fa95" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section20.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section20.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs18-2.jpg?alt=media&token=29c1d1e9-379f-4ed9-89fd-dd11a5feec35" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section20.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section20.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col span={24}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs18-3.jpg?alt=media&token=c0b56061-1cd0-4c33-b504-a75e3c5aa07e" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section20.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section20.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs19-1.jpg?alt=media&token=50ba23e0-96c8-4b58-9a6b-82499a21ebe3" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section21.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section21.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.multischooled.section21.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm-static" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs19-2.jpg?alt=media&token=a608d911-f38c-4e47-bb07-efdc76f046a4"
                    />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section21.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section21.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs19-3.jpg?alt=media&token=80a42b06-498c-4022-b025-2d0584065d9f"
                    />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section21.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section21.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs19-4.jpg?alt=media&token=681201f7-51de-4ed6-a05c-e7062bc016ab"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section21.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section21.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs19-5.jpg?alt=media&token=e0370817-b6dc-424b-acfc-83ee3d00ae01"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.multischooled.section21.imgAlt5')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.multischooled.section21.imgAlt5Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                sm={sm}
                className="mb-small"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs20.jpg?alt=media&token=dcb93707-25b6-4192-bff0-9f768cef405e">
                <Col className="banner-pt vertical-sb" span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.multischooled.section22.title')}
                        </Text>
                    </Row>
                    <div
                        className={sm ? ' overlay w-100vw' : 'overlay w-20vw'}
                        style={{ borderTopRightRadius: '30px', paddingTop: '32px', paddingBottom: '16px', paddingLeft: '16px' }}>
                        <Row gutter={[16, 16]}>
                            <Col sm={4} md={1} style={{ backgroundColor: 'rgba(179, 56, 50, 1)' }} />
                            <Col sm={20} md={23}>
                                <Row align="start" justify="start">
                                    <Col>
                                        <Text displayblock color="light" weight="600">
                                            {t('aos.multischooled.section22.legend.red.title')}
                                        </Text>
                                        <Text displayblock color="light" weight="400">
                                            {t('aos.multischooled.section22.legend.red.desc')}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-img-alt" gutter={[16, 16]}>
                            <Col sm={4} md={1} style={{ backgroundColor: 'rgba(88, 179, 81, 1)' }} />
                            <Col sm={20} md={23}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section22.legend.green.title')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-img-alt" gutter={[16, 16]}>
                            <Col sm={4} md={1} style={{ backgroundColor: 'rgba(40, 37, 152, 1)' }} />
                            <Col sm={20} md={23}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section22.legend.purple.title')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pt-img-alt" gutter={[16, 16]}>
                            <Col sm={4} md={1} style={{ backgroundColor: 'rgba(235, 161, 74, 1)' }} />
                            <Col sm={20} md={23}>
                                <Row align="start" justify="start">
                                    <Text color="light" weight="600">
                                        {t('aos.multischooled.section22.legend.orange.title')}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Banner>
            <Row className="padding-horizontal mb-small">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.multischooled.section22.text')}
                </Text>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs21.jpg?alt=media&token=53152a81-59bf-441e-848e-225db0150203">
                <Col className="banner-pt" span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.multischooled.section23.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-small">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section23.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section23.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section23.text')}
                </Text>
            </Row>
            <Carousel className="mb-medium padding-horizontal" type="slider1" data={carouselItems} multischooled />
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs22.jpg?alt=media&token=c30ce100-d764-461e-9709-337cd29ce054"
            />
            <Row className="padding-horizontal pt-img-alt mb-small">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section24.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section24.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small">
                <Text color="light" size="21px" weight="400">
                    {t('aos.multischooled.section24.text')}
                </Text>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FCokOkullu%2Fs25.png?alt=media&token=2f093b85-758e-4692-a60e-9bf388611414">
                <Col className="banner-pt" span={24}>
                    <Row className="banner-title" style={!sm && { maxWidth: '50%' }}>
                        <Text displayblock size="48px" color="light" weight="700">
                            {t('aos.multischooled.section25.title')}
                        </Text>
                        <Text displayblock size="25px" color="light" weight="600">
                            {t('aos.multischooled.section25.titleDescription')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt pb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.multischooled.section25.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.multischooled.section25.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
        </StyledMultiSchooledPage>
    );
};

export default MultiSchooledPage;
