import React from 'react';
import { Text } from '@Components/Typography/styles';
import { Col, Row, Divider } from 'antd';
import Button from '@Components/Button';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PageWrapper, { Img } from './styles';

const Info = () => {
    const { sm } = useResponsive();
    const { t, i18n } = useTranslation('common');

    const supporters = [
        {
            id: 1,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fankara-belediyesi.jpg?alt=media&token=5e7fc4d4-05c8-48dc-b437-3b108e6d6972',
        },
        {
            id: 2,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fnetlab.jpg?alt=media&token=e3cea655-0c9b-4f84-81ab-dee18bb7bead',
        },
        {
            id: 3,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fvekam.jpg?alt=media&token=4c8db202-a7eb-47d8-9914-a9bc0f8215a3',
        },
        {
            id: 10,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fizmir-jewish-heritage.png?alt=media&token=8b9d8f27-ff57-4481-9e3e-2a3ce8e92a51https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fizmir-jewish-heritage.png?alt=media&token=be6a5d1a-2f56-4236-ae26-507b146d6005',
        },
        {
            id: 4,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fisraildeki-turkiyeliler.jpg?alt=media&token=2b67bc03-53db-460f-ab3b-cdfb64811edd',
        },
        {
            id: 5,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fanu.jpg?alt=media&token=6e9409da-8688-4d24-a7c1-6194bc3876bc',
        },
        {
            id: 6,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fpan.jpg?alt=media&token=62eb31ce-39ee-440d-a734-16567c14b5c8',
        },
        {
            id: 7,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Furban-obscura.jpg?alt=media&token=31f3abde-4822-4b77-a036-ebb8aba0c647',
        },
        {
            id: 8,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fdigihead.jpg?alt=media&token=2742fa91-a09c-45bc-83f1-261a707f46a3',
        },
        {
            id: 9,
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fmedya-gaga.png?alt=media&token=dbbd7a07-1517-4d9f-ac44-453b6e3f40d9',
        },
    ];

    const team = [
        {
            id: 't-1',
            title: t('aos.info.title1'),
            name: 'Ayşin Zoe Güneş',
        },
        {
            id: 't-2',
            title: t('aos.info.title2'),
            name: 'Onur Karaduman',
        },
        {
            id: 't-3-1',
            title: t('aos.info.title3'),
            name: 'Müge Cengizkan - Ayşin Zoe Güneş',
        },
        {
            id: 't-3-2',
            title: t('aos.info.title4'),
            name: 'Müge Cengizkan - Ayşin Zoe Güneş - Yavuz İşçen',
        },
        {
            id: 't-4',
            title: t('aos.info.title5'),
            name: 'Ergin Şafak Dikmen',
        },
        {
            id: 't-4-1',
            title: t('aos.info.title9'),
            name: 'Ural Levent - Esra Köroğlu - Alper Sarper',
        },
        {
            id: 't-5',
            title: t('aos.info.title6'),
            name: 'Cihan Uysal (Türkçe) - Michael Halphie (İngilizce)',
        },
        {
            id: 't-7',
            title: t('aos.info.title7'),
            name: 'Cem Dedekargınoğlu - Ezgi Özpamir Eskiünürlü - Dilan Ece Yıldız',
        },
        {
            id: 't-8',
            title: t('aos.info.title8'),
            name: 'Nesim Bencoya - Yavuz İşçen',
        },
    ];

    const thanks = [
        {
            id: 't-1',
            name: 'Mehmet Akan',
        },
        {
            id: 't-2',
            name: 'Julide Akşiyote',
        },
        {
            id: 't-3',
            name: 'Çiğdem Alp',
        },
        {
            id: 't-4',
            name: 'Güliz Bilgin Altınöz',
        },
        {
            id: 't-5',
            name: 'Beki Luiza Bahar',
        },
        {
            id: 't-6',
            name: 'Ender Balcı',
        },
        {
            id: 't-7',
            name: 'Lorans Tanatar Baruh',
        },
        {
            id: 't-8',
            name: 'Deniz Bezirgan',
        },
        {
            id: 't-9',
            name: 'Seda Erdem Campbell',
        },
        {
            id: 't-10',
            name: 'Atilla Cangır',
        },
        {
            id: 't-11',
            name: 'Ali Cengizkan',
        },
        {
            id: 't-12',
            name: 'Emre Dalkılıç',
        },
        {
            id: 't-13',
            name: 'Gülşen Dişli',
        },
        {
            id: 't-14',
            name: 'Aron Ender',
        },
        {
            id: 't-15',
            name: 'Yalçın Ergir',
        },
        {
            id: 't-16',
            name: 'Ferruh Gençer',
        },
        {
            id: 't-17',
            name: 'Neriman Şahin Güçhan',
        },
        {
            id: 't-18',
            name: 'Ovi Roditi Gülerşen',
        },
        {
            id: 't-19',
            name: 'Levent Gündüz',
        },
        {
            id: 't-20',
            name: 'Yalçın Ergir',
        },
        {
            id: 't-21',
            name: 'Ferruh Gençer',
        },
        {
            id: 't-22',
            name: 'Neriman Şahin Güçhan',
        },
        {
            id: 't-23',
            name: 'Ovi Roditi Gülerşen',
        },
        {
            id: 't-25',
            name: 'Gökçe Günel',
        },
        {
            id: 't-26',
            name: 'Nikolaos Gryspolakis',
        },
        {
            id: 't-27',
            name: 'Gizel Ender Hazan',
        },
        {
            id: 't-28',
            name: 'Erkan Kaçar',
        },
        {
            id: 't-29',
            name: 'Uğur Kavas',
        },
        {
            id: 't-30',
            name: 'Önder Kaya',
        },
        {
            id: 't-32',
            name: 'Ertan Keskinsoy',
        },
        {
            id: 't-32',
            name: 'Avi Avdan Kohen',
        },
        {
            id: 't-33',
            name: 'Aylin Kuryel',
        },
        {
            id: 't-34',
            name: 'Nihan Kuzu',
        },
        {
            id: 't-34',
            name: 'Raşel Meseri',
        },
        {
            id: 't-35',
            name: 'Kemal Mukatel',
        },
        {
            id: 't-36',
            name: 'Işık Namer',
        },
        {
            id: 't-36-1',
            name: 'Ayça Olcaytu',
        },
        {
            id: 't-37',
            name: 'Döne Otyam',
        },
        {
            id: 't-38',
            name: 'Bekir Ödemiş',
        },
        {
            id: 't-39',
            name: 'Koray Özalp',
        },
        {
            id: 't-40',
            name: 'Aslı Özbay',
        },
        {
            id: 't-41',
            name: 'Rezzan Özsarfati',
        },
        {
            id: 't-42',
            name: 'Ali Osman Öztürk',
        },
        {
            id: 't-43',
            name: 'Dina Parlayan',
        },
        {
            id: 't-44',
            name: 'Müşerref Salman',
        },
        {
            id: 't-44-1',
            name: 'Erman Tamur',
        },
        {
            id: 't-45',
            name: 'Turan Tanyer',
        },
        {
            id: 't-46',
            name: 'Dilek Tütüncü',
        },
        {
            id: 't-47',
            name: 'Müge Bütüner Uluğ',
        },
        {
            id: 't-48',
            name: 'Gizem Yurdanur',
        },
    ];

    return (
        <PageWrapper>
            <Col span={24}>
                <Row className="mb-sm">
                    <Text style={{ textAlign: 'center' }} color="light" size="36px" weight="400">
                        {t('aos.info.text')}
                    </Text>
                </Row>
                <Divider className="mb-sm" style={{ borderColor: '#ffffff' }}>
                    <Text color="light" style={sm ? { fontSize: '24px' } : { fontSize: '36px' }} weight="400">
                        {t('aos.info.team')}
                    </Text>
                </Divider>
                <div className="mb-md">
                    {team.map(({ id, title, name }) => (
                        <Row className="mb-semi-sm" key={`${id}-team`}>
                            <Col span={24}>
                                <Row align="middle" justify="center">
                                    <Text color="grey" size="32px" weight="300">
                                        {title}
                                    </Text>
                                </Row>
                                <Row align="middle" justify="center">
                                    <Text color="light" size="32px" weight="600">
                                        {name}
                                    </Text>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </div>

                <Row
                    className="mb-md"
                    align="middle"
                    justify="center"
                    gutter={[
                        { xs: 16, sm: 168, md: 32, lg: 32, xxl: 32 },
                        { xs: 16, sm: 168, md: 32, lg: 32, xxl: 32 },
                    ]}>
                    <Col xs={12} sm={12} md={6}>
                        <Button type="link" className="padding-reset w-100">
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fturk-smd.jpg?alt=media&token=b005b846-768e-4b97-b3f0-1adddd40a495" />
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Button type="link" className="padding-reset w-100">
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2Fvp.jpg?alt=media&token=85731d54-6182-447f-928b-7a46678a6220" />
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Button type="link" className="padding-reset w-100">
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/Info%2F%20netherlands.jpg?alt=media&token=ee228ee6-e292-4fe3-822d-5f38d4263e3b" />
                        </Button>
                    </Col>
                </Row>
                <Divider className="mb-sm" style={{ borderColor: '#ffffff' }}>
                    <Text color="light" style={sm ? { fontSize: '24px' } : { fontSize: '36px' }} weight="400">
                        {t('aos.info.supporters')}
                    </Text>
                </Divider>
                <Row className="mb-md padding-horizontal" align="middle" justify="center" gutter={[32, 32]}>
                    {supporters.map(({ id, src }) => (
                        <Col key={`${id}-supporters`} xs={12} sm={12} md={6}>
                            <Button type="link" className="padding-reset w-100">
                                <Img src={src} />
                            </Button>
                        </Col>
                    ))}
                </Row>
                <Divider className="mb-sm" style={{ borderColor: '#ffffff' }}>
                    <Text color="light" style={sm ? { fontSize: '24px' } : { fontSize: '36px' }} weight="400">
                        {t('aos.info.thanks')}
                    </Text>
                </Divider>
                <Row className="pb-sm" gutter={[32, 32]}>
                    {thanks.map(({ id, name }) => (
                        <Col key={`${id}-thanks`} xs={12} sm={12} md={6}>
                            <Row className={sm && 'padding-horizontal'} align="middle" justify={sm ? 'center' : 'start'}>
                                <Text color="light" size="32px" weight="600">
                                    {name}
                                </Text>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Col>
        </PageWrapper>
    );
};

export default Info;
