export default [
    {
        id: 1,
        family: 'Ağlamaz',
        color: 'rgba(17, 104, 88, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Roza (Benforma) Ağlamaz',
                lat: '39.926400',
                lng: '32.858842',
            },
            {
                id: 'm-2',
                name: 'Avram Ağlamaz',
                lat: '39.926282',
                lng: '32.858595',
            },
            {
                id: 'm-3',
                name: 'Leyla (Almelek) Ağlamaz',
                lat: '39.925637',
                lng: '32.859056',
            },
            {
                id: 'm-4',
                name: 'Yosef Ağlamaz',
                lat: '39.925644',
                lng: '32.857959',
            },
        ],
    },
    {
        id: 2,
        family: 'Benforma (Bilman)',
        color: 'rgba(19, 41, 74, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Besim Benforma (Bilman)',
                lat: '39.925109',
                lng: '32.858472',
            },
            {
                id: 'm-2',
                name: 'Dina Benforma (Bilman)',
                lat: '39.925321',
                lng: '32.857461',
            },
            {
                id: 'm-3',
                name: 'Jak Bilman',
                lat: '39.925615',
                lng: '32.857909',
            },
            {
                id: 'm-4',
                name: 'Josef Bilman',
                lat: '39.925871',
                lng: '32.858172',
            },
            {
                id: 'm-5',
                name: 'Moiz Benforma (Bilman)',
                lat: '39.926159',
                lng: '32.858513',
            },
            {
                id: 'm-6',
                name: 'Nisim Bilman',
                lat: '39.926405',
                lng: '32.858903',
            },
            {
                id: 'm-7',
                name: 'Yasefaçi Benforma (Bilman)',
                lat: '39.925952',
                lng: '32.859315',
            },
            {
                id: 'm-8',
                name: 'Yasef Benforma (Bilman)',
                lat: '39.925653',
                lng: '32.858981',
            },
            {
                id: 'm-9',
                name: 'Beki (Rebeka Saranga) Benforma (Bilman)',
                lat: '39.925288',
                lng: '32.858442',
            },
        ],
    },
    {
        id: 3,
        family: 'Bonfil',
        color: 'rgba(203, 170, 170, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Ester Bonfil',
                lat: '39.925190',
                lng: '32.857319',
            },
            {
                id: 'm-2',
                name: 'Flor Bonfil',
                lat: '39.925538',
                lng: '32.857809',
            },
        ],
    },
    {
        id: 4,
        family: 'Çiprut',
        color: 'rgba(162, 203, 174, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Kemal Çiprut',
                lat: '39.925729',
                lng: '32.859422',
            },
            {
                id: 'm-2',
                name: 'Roza Çiprut',
                lat: '39.925528',
                lng: '32.857895',
            },
        ],
    },
    {
        id: 5,
        family: 'Ender',
        color: 'rgba(96, 108, 56, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Yusuf Ender',
                lat: '39.924700',
                lng: '32.857348',
            },
            {
                id: 'm-2',
                name: 'Raşel Benforma Ender',
                lat: '39.925337',
                lng: '32.857447',
            },
            {
                id: 'm-3',
                name: 'Moiz Ender',
                lat: '39.925342',
                lng: '32.859074',
            },
            {
                id: 'm-4',
                name: 'İshak Ender',
                lat: '39.925735',
                lng: '32.858787',
            },
        ],
    },
    {
        id: 6,
        family: 'Parlayan',
        color: 'rgba(73, 39, 94, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İzak Parlayan',
                lat: '39.925696',
                lng: '32.858960',
            },
            {
                id: 'm-2',
                name: 'Dina Parlayan',
                lat: '39.925620',
                lng: '32.857625',
            },
        ],
    },
];
