import styled from 'styled-components';
import { Row, Col } from 'antd';
import Theme from '@Themes/main';
import { between } from 'polished';
import { SoundOutlined, SoundFilled } from '@ant-design/icons';
import Button from '@Components/Button';

const { paddingHorizontal, paddingBottom, paddingTop } = Theme.landingSectionStyles;

export const Wrapper = styled(Col)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #jqa-hero-video {
        object-fit: cover !important;
        height: 100vh !important;
        width: 100vw !important;
    }

    .disable-hover {
        padding-left: 2px;
        padding-right: 9px;
        padding-top: 2px;
        padding-bottom: 2px;

        img {
            margin-right: 5px;
        }

        :hover {
            background-color: #ffffff !important;
            span {
                color: #020b1b !important;
                /* letter-spacing: 2px; */
                /* transition: all 0.2s ease-in-out; */
            }
        }
        :focus {
            background-color: #ffffff !important;
            span {
                color: #020b1b !important;
                /* letter-spacing: 2px; */
                /* transition: all 0.2s ease-in-out; */
            }
        }
    }

    .lottie-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        z-index: 60;
        right: ${between('10px', paddingHorizontal)};
        bottom: 16px;
    }

    .lottie-styles {
        margin-left: 10px;
    }
`;

export const Content = styled(Row)`
    width: 100%;
    min-height: 150px;
`;

export const CtaWrapper = styled.div`
    padding-left: ${between('10px', paddingHorizontal)};
    padding-right: ${between('10px', paddingHorizontal)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    button {
        z-index: 34;
    }
`;

export const ButtonWrapper = styled(Row)`
    margin-top: ${between('100px', '180px')};
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 33;
    padding-left: ${between('0px', '60px')};
    padding-right: ${between('0px', '60px')};
    top: 0;
    left: 0;
`;

export const Border = styled.div`
    border-left: 0.3px solid rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 95%;
    position: absolute;
    top: 14px;
    left: 20px;
`;

export const StyledButton = styled(Button)`
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 9px;
    padding-top: 2px;
    padding-bottom: 2px;

    span {
        color: #ffffff !important;
    }

    :hover {
        background-color: transparent !important;
    }

    img {
        :hover {
            transform: scale(1.5);
            transition: transform 0.2s ease-in-out;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    align-items: center;
    justify-content: center;

    /* border: 1px solid red; */
    border-radius: 90px;
    height: 36px;
    width: 36px;
`;

export const StyledSoundOutlined = styled(SoundOutlined)`
    font-size: 32px;

    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledSoundFilled = styled(SoundFilled)`
    color: #ffffff;
    font-size: 32px;

    :hover {
        cursor: pointer;
    }
`;
