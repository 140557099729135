export default {
    colors: {
        black: '#020B1B',
        light: '#FFFFFF',
        link: '#1b81e6',
        green: '#41d2b1',
        grey: '#b0b0b0',
        lightGrey: '#F7F7F7',
    },

    layoutStyles: {
        headerColor: 'transparent',
        headerColorAos: 'rgba(0,0,0,0.3)',

        headerHeight: '90px',
        headerScrollMobileHeight: '65px',

        footerColor: '#020B1B',
        contentColor: '#FFFFFF',

        footerHeight: '200px',
        contentHeight: '100vh',

        headerPaddingVertical: '10px',
        headerPaddingHorizontal: '70px',
        paddingHorizontal: '70px',
    },

    landingSectionStyles: {
        paddingTop: '90px',
        paddingHorizontal: '70px',
        paddingBottom: '40px',
    },
};
