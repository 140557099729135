/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import LoadingSpinner from '@Components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { StyledCarousel, TextWrapper, MediaWrapper } from './styles';

const TextSlider = ({ documents }) => {
    const { i18n } = useTranslation('common');

    return (
        <StyledCarousel
            arrows
            dotPosition="top"
            prevArrow={
                <div className="arrows">
                    <LeftCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }
            nextArrow={
                <div className="arrows">
                    <RightCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }>
            {!documents ? (
                <LoadingSpinner />
            ) : (
                documents.map(({ dubsrcEN, dubsrcTR, htmlAltTR, htmlAltEN, htmlContentTR, htmlContentEN, name }, i) => (
                    <>
                        <TextWrapper key={i} span={24}>
                            {i18n.language === 'tr' ? (
                                <>
                                    <div className="news-top-wrapper" dangerouslySetInnerHTML={{ __html: htmlContentTR }} />
                                    {htmlAltTR && <div className="news-bottom-wrapper" dangerouslySetInnerHTML={{ __html: htmlAltTR }} />}
                                </>
                            ) : (
                                <>
                                    <div className="news-top-wrapper" dangerouslySetInnerHTML={{ __html: htmlContentEN }} />
                                    {htmlAltEN && <div className="news-bottom-wrapper" dangerouslySetInnerHTML={{ __html: htmlAltEN }} />}
                                </>
                            )}
                        </TextWrapper>
                        {dubsrcTR && (
                            <MediaWrapper align="middle" justify="center">
                                {i18n.language === 'en' ? (
                                    <audio controls>
                                        <source src={dubsrcEN} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                ) : (
                                    <audio controls>
                                        <source src={dubsrcTR} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                )}
                            </MediaWrapper>
                        )}
                    </>
                ))
            )}
        </StyledCarousel>
    );
};

export default TextSlider;
