import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ src, isPlaying, name, setCurrentPlayingVideo }) => (
    <ReactPlayer
        controls
        url={src}
        playing={isPlaying}
        onStart={() => setCurrentPlayingVideo(name)}
        onPause={() => setCurrentPlayingVideo()}
        onEnded={() => setCurrentPlayingVideo()}
        volume={1}
        width="100%"
        height="100%"
    />
);

export default VideoPlayer;
