/* eslint-disable sonarjs/no-nested-template-literals */
import styled from 'styled-components';

export default styled.div`
    height: ${({ height }) => height || '100vh'};
    width: 100%;
    position: absolute;
    top: 0;
    background-color: ${({ opacity }) => (opacity ? `rgba(7, 20, 38, ${opacity})` : 'rgba(7, 20, 38, 0.3)')};
    z-index: 9;
`;
