export const LANDING_PAGE = '/';
export const JEWISH_QUARTER_PAGE = '/the-jewish-quarter';
export const ANKARA_JEWISH_COMMUNITY_PAGE = '/ankara-jewish-community';
export const VIRTUAL_TOUR_PAGE = '/virtual-tour';
export const INFO_PAGE = '/info';
export const ARCHIVE_OF_SILENCE_PAGE = '/archive-of-the-silence';
export const AUTHENTIC_PAGE = '/archive-of-the-silence/authentic';
export const COMMUNITY_PAGE = '/archive-of-the-silence/community';
export const BORDERS_PAGE = '/archive-of-the-silence/borders';
export const MULTI_SCHOOLED_PAGE = '/archive-of-the-silence/multi-schooled';
export const AFTERWARDS_PAGE = '/archive-of-the-silence/afterward';
export const TRAILS_PAGE = '/archive-of-the-silence/trails';
export const MUSIC_PAGE = '/archive-of-the-silence/music';
export const FAREWELL_PAGE = '/archive-of-the-silence/farewell';
export const WATER_CULTURE_PAGE = '/archive-of-the-silence/water';
export const COLLECTIVE_PAGE = '/archive-of-the-silence/collective';
