import styled from 'styled-components';
import { Carousel } from 'antd';

export default styled.video`
    width: 100%;
    height: 100%;
`;
export const StyledCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;

    .slick-slider {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
        div {
            height: 100%;
        }
    }

    .slick-prev {
        left: 10px;
        z-index: 60;
    }

    .slick-next {
        right: 20px;
        bottom: 3px;
        z-index: 60;
    }
    .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ant-image-mask-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ant-image-img {
        object-fit: cover;
        border-radius: 8px;
    }
    /* .slick-dots {
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 10000;
        width: 200px;
        margin-left: 0px;
        background-color: black;
        margin-bottom: -11px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
    }

    li {
        margin-left: 5px !important;
        margin-right: 5px !important;
        height: 5px !important;

        border-radius: 5px;
        button {
            height: 5px !important;
        }
    } */
`;
