import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Radio, Checkbox } from 'antd';
import { Text } from '@Components/Typography/styles';
import { useTranslation } from 'react-i18next';
import useResponsive from '@Hooks/useMediaQuery';

import StyledCompare, { Menu, StyledButton } from './styles';

const Compare = (props) => {
    const [isCompareClicked, setIsCompareClicked] = useState();
    const [selectedCb, setSelectedCb] = useState([]);
    const { t } = useTranslation('common');
    const videoRef = useRef('video-ref');
    const { sm } = useResponsive();
    const [currentVideoURL, setCurrentVideoURL] = useState(
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1939_fadein.mp4?alt=media&token=164f2e48-7b07-413e-9648-a85ac0d0902e'
    );

    const video1939 =
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1939_fadein.mp4?alt=media&token=164f2e48-7b07-413e-9648-a85ac0d0902e';
    const video1957 =
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1957_fadein.mp4?alt=media&token=31aaa327-0e15-4f5d-8b1a-449179079255';
    const video2011 =
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F2011-fadein.mp4?alt=media&token=d1ed2693-78d0-4f14-b4b9-d133f872bd19';
    const video1988 =
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1988_fadein.mp4?alt=media&token=eebf3027-41f8-4c88-b330-117955c38f41';

    const compareVideos = [
        {
            id: '19391957',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1939-1957_kars%CC%A7%C4%B1las%CC%A7t%C4%B1rma.mp4?alt=media&token=4e05bc22-9b14-4193-bc44-383b491c85a7',
        },
        {
            id: '19392011',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F39-11%20kars%CC%A7%C4%B1las%CC%A7t%C4%B1rma.mp4?alt=media&token=1962f273-ebfb-420d-b990-a9aca570e044',
        },
        {
            id: '19391988',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F39-88%20kars%CC%A7%C4%B1las%CC%A7t%C4%B1rma.mp4?alt=media&token=f6e78e61-38d2-4df8-969d-f497a55336e2',
        },
        {
            id: '19572011',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F57-11%20kars%CC%A7%C4%B1lat%C4%B1rma.mp4?alt=media&token=d2093357-3a1b-4506-94e4-497dd469f815',
        },
        {
            id: '19571988',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F1957-1988-kars%CC%A7%C4%B1las%CC%A7t%C4%B1rma.mp4?alt=media&token=737718ee-8d28-4c8e-a6f9-1081fbd309da',
        },
        {
            id: '20111988',
            src: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fvideo_compare%2F88-11%20kars%CC%A7%C4%B1las%CC%A7t%C4%B1rma_1.mp4?alt=media&token=5e19a0dc-9d5d-493d-b7d8-65fec98448b0',
        },
    ];

    const options = [
        { label: <Text size="18px">Ankara, 1939</Text>, value: video1939 },
        { label: <Text size="18px">Ankara, 1957</Text>, value: video1957 },
        { label: <Text size="18px">Ankara, 1988</Text>, value: video1988 },
        { label: <Text size="18px">Ankara, 2011</Text>, value: video2011 },
    ];

    const cbChange = (e) => {
        setSelectedCb(e);
    };

    useEffect(() => {
        if (selectedCb.length === 2) {
            const videos = compareVideos.filter(({ id, src }) => id.includes(selectedCb[0]) && id.includes(selectedCb[1]));
            setCurrentVideoURL(videos[0].src);
        }
    }, [selectedCb]);

    useEffect(() => {
        videoRef.current.load();
    }, [currentVideoURL]);

    // useEffect(() => {
    //     setIsVideoReady(false);
    //     videoRef.current.addEventListener('playing', setIsVideoReady(true));
    //     return () => {
    //         videoRef.current.removeEventListener('playing', setIsVideoReady(true));
    //     };
    // }, [videoRef]);

    const onRadioChange = (e) => {
        setCurrentVideoURL(e.target.value);
    };

    const handleBack = () => {
        setIsCompareClicked(false);
        setCurrentVideoURL(video1939);
    };

    return (
        <StyledCompare {...props} align="middle" justify="center">
            <Col sm={24} xs={24} md={20} xl={20} xxl={20}>
                <video ref={videoRef} data-keepplaying preload="auto" playsInline autoPlay muted>
                    <source src={currentVideoURL} type="video/mp4" />
                </video>
                <Menu>
                    <Col md={24}>
                        {!isCompareClicked ? (
                            <>
                                <Radio.Group defaultValue={video1939} onChange={(e) => onRadioChange(e)} options={options} />
                                <StyledButton onClick={() => setIsCompareClicked(true)} type="primary">
                                    {t('aos.borders.generic.compare')}
                                </StyledButton>
                            </>
                        ) : (
                            <>
                                <Checkbox.Group onChange={(e) => cbChange(e)}>
                                    <Checkbox disabled={selectedCb.length === 2 && !selectedCb.includes('1939')} value="1939">
                                        <Text size="18px">Ankara, 1939</Text>
                                    </Checkbox>
                                    <Checkbox disabled={selectedCb.length === 2 && !selectedCb.includes('1957')} value="1957">
                                        <Text size="18px">Ankara, 1957</Text>
                                    </Checkbox>
                                    <Checkbox disabled={selectedCb.length === 2 && !selectedCb.includes('1988')} value="1988">
                                        <Text size="18px">Ankara, 1988</Text>
                                    </Checkbox>
                                    <Checkbox disabled={selectedCb.length === 2 && !selectedCb.includes('2011')} value="2011">
                                        <Text size="18px">Ankara, 2011</Text>
                                    </Checkbox>
                                </Checkbox.Group>
                                <StyledButton onClick={() => handleBack()} type="primary">
                                    {t('aos.borders.generic.back')}
                                </StyledButton>
                            </>
                        )}
                    </Col>
                </Menu>
            </Col>
            <Col sm={24} xs={24} md={20} xl={20} xxl={20}>
                <Row style={{ paddingBottom: '20vh' }}>
                    <Col className="pt-img-alt">
                        <Text displayblock color="grey">
                            {t('aos.borders.videoCompareAlt.imgAltText')}
                        </Text>
                    </Col>
                </Row>
            </Col>
        </StyledCompare>
    );
};

export default Compare;
