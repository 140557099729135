/* eslint-disable sonarjs/no-nested-template-literals */
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { GoogleMap } from '@react-google-maps/api';
import Button from '@Components/Button';

export default styled(GoogleMap)`
    .fade-in {
        opacity: 0 !important;
    }
`;
export const StyledLegend = styled.div`
    background-color: rgba(255, 255, 255, 0.6) !important;
    padding-top: 24px;
    max-height: 50vh;
    overflow: scroll !important;

    padding-bottom: 24px;
    border-top-right-radius: 24px;
    position: absolute;
    left: 0px;
    bottom: 0px !important;

    .dot-style {
        border-radius: 90%;
    }

    .space {
        margin-bottom: 4px;
    }
`;

export const Wrapper = styled(Row)`
    height: 100vh;
    position: relative;
`;

export const Dot = styled.div`
    margin-right: 16px;
    height: 16px;
    width: 16px;
    background-color: ${({ color, active }) => (!active ? 'rgba(255,255,255,0.2)' : color)};
    border: ${({ color, active }) => !active && `2px solid ${color}`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inner-dot {
        border-radius: 90%;
        border: 3px solid #ffffff;
    }
`;

export const StyledButton = styled(Button)`
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    border-radius: 0px;

    background: transparent !important;

    .inner-text {
        span {
            color: ${({ isActive, theme }) => (isActive ? theme.colors.light : theme.colors.black)} !important;
        }
    }

    :hover {
        background: transparent !important;

        /* .inner-text {
            span {
                color: ${({ theme }) => theme.colors.light} !important;
            }
        } */
    }

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
    }
`;
