import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';

export const StyledWrapper = styled(Col)`
    height: 100vh;
    background-color: #252525;
    padding-top: ${between('80px', '120px')};
    padding-bottom: ${between('10px', '20px')};

    .scroll-icon-wrapper {
        position: absolute;
        bottom: 20px;
        right: 50%;
    }

    .swiper-container {
        padding-left: ${between('10px', '60px')};
        padding-right: ${between('10px', '60px')};
        padding-bottom: ${between('30px', '50px')};
    }

    .swiper-slide {
        filter: blur(3px);
        border-radius: 40px;

        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        cursor: pointer;
    }
    .swiper-slide-active {
        filter: blur(0px);
    }

    .swiper-slide-shadow-left {
        border-radius: 40px !important;
    }

    .swiper-slide-shadow-right {
        border-radius: 40px !important;
    }
    .swiper-pagination {
        margin-top: 40px;
    }
    .swiper-pagination-bullet {
        background-color: ${({ theme }) => theme.colors.black};
        margin-top: 20px;
        border: 1px solid #fefefe;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .wrapper-flex {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card-source {
        position: absolute;
        bottom: 15px;
    }
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
`;
