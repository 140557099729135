/* eslint-disable no-plusplus */
/* eslint-disable import/no-duplicates */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-restricted-properties */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { LoadScript, Polygon, Circle, Marker, InfoWindow } from '@react-google-maps/api';
import { getCenterOfPolygon } from '@Helpers';
import Area1Data from '@Pages/ArchiveOfSilencePage/Community/area1';
import Area2Data from '@Pages/ArchiveOfSilencePage/Community/area2';
import Area3Data from '@Pages/ArchiveOfSilencePage/Community/area3';
import Area4Data from '@Pages/ArchiveOfSilencePage/Community/area4';
import Area5Data from '@Pages/ArchiveOfSilencePage/Community/area5';
import Area6Data from '@Pages/ArchiveOfSilencePage/Community/area6';

import { Row } from 'antd';
import { Text } from '@Components/Typography/styles';
import { useTranslation } from 'react-i18next';

import { generalMapOptions, circleLocations, polygonLocations, peopleOfArea3 } from './map-data';
import StyledGoogleMap, { StyledLegend, Wrapper, Dot, StyledButton } from './styles';

const VisibleMarker = ({ selectedFamily }) => {
    const [activeMarker, setActiveMarker] = useState();

    const onMarkerLoad = (marker) => {
        const timeOutVal = (Math.random() * (0.4 - 0.2) + 0.2).toFixed(2);
        const markerOpacityIncrement = 0.05;
        const markerOpacityIncrementRevert = Math.pow(markerOpacityIncrement, -1);
        setTimeout(function () {
            setTimeout(() => {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < markerOpacityIncrementRevert.toFixed(); i++) {
                    marker.setOpacity(marker.getOpacity() + Number(markerOpacityIncrement));
                }
            }, timeOutVal);
        }, 700);
    };

    useEffect(() => {
        setActiveMarker(false);
    }, [selectedFamily]);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    return (
        <>
            {selectedFamily?.members?.map(({ id, name, lat, lng }) => (
                <Marker
                    className="fade-in"
                    options={{
                        icon: {
                            path: 'M56.7307 92.9917C78.0188 88.5103 94 69.6211 94 47C94 21.0425 72.9574 0 47 0C21.0426 0 0 21.0425 0 47C0 69.6211 15.9812 88.5103 37.2693 92.9917L47 158L56.7307 92.9917Z',
                            fillColor: selectedFamily.color,
                            fillOpacity: 1,
                            scale: 0.3,
                        },
                    }}
                    key={`${name}`}
                    onLoad={onMarkerLoad}
                    visible
                    clickable
                    onClick={() => handleActiveMarker(name)}
                    opacity={0}
                    position={{ lat: Number(lat), lng: Number(lng) }}>
                    {activeMarker === name ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>{name}</div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </>
    );
};

const Legend = ({ selectedFamily, allFamilies, setSelectedFamily }) => {
    const { t } = useTranslation('common');

    const handleLegendItemClick = (clickedFamily) => {
        if (selectedFamily.family === clickedFamily.family) {
            // setSelectedFamily());
        } else {
            setSelectedFamily(clickedFamily);
        }
    };

    return (
        <StyledLegend>
            {allFamilies.map((item, i) => (
                <Row className="space" align="middle" justify="start" key={i}>
                    <StyledButton onClick={() => handleLegendItemClick(item)}>
                        <Dot active={selectedFamily.family === item.family} color={item.color} className="dot-style" />
                        <Text>
                            {/* {item.family === "Kişiler" && ?  `${item.family} ${t('aos.community.generic.family')}`} */}
                            {item.family === 'Kişiler'
                                ? `${t('aos.community.generic.people')}`
                                : `${item.family} ${t('aos.community.generic.family')}`}
                        </Text>
                    </StyledButton>
                </Row>
            ))}
        </StyledLegend>
    );
};

const Map = () => {
    const families = Area1Data.concat(Area2Data, Area3Data, Area4Data, Area5Data, Area6Data);
    const persons = families.filter((item) => item.family === 'Kişiler');
    const allFamilies = families.filter((item) => item.family !== 'Kişiler');

    const members = persons[0].members.concat(persons[1].members, persons[2].members, persons[3].members);
    allFamilies.push({
        id: 'kisiler',
        family: 'Kişiler',
        color: 'rgba(200, 200, 200, 1)',
        members,
    });

    const [selectedFamily, setSelectedFamily] = useState(allFamilies[0]);
    const [map, setMap] = useState();
    const [isMapIdle, setIsMapIdle] = useState(false);

    const onLoad = (polygon) => {
        console.log('polygon: ', polygon);
    };

    return (
        <Wrapper>
            <LoadScript googleMapsApiKey="AIzaSyDIkz21QhCfWRsOLKjMpF7ECQ6IIKG4kG0">
                <StyledGoogleMap
                    onLoad={(mapInstance) => {
                        setMap(mapInstance);
                    }}
                    onIdle={() => setIsMapIdle(true)}
                    options={generalMapOptions.mapOptions}
                    mapContainerStyle={generalMapOptions.containerStyle}
                    center={generalMapOptions.center}
                    zoom={15}>
                    {selectedFamily && <VisibleMarker selectedFamily={selectedFamily} />}
                </StyledGoogleMap>
            </LoadScript>
            {allFamilies && <Legend selectedFamily={selectedFamily} allFamilies={allFamilies} setSelectedFamily={setSelectedFamily} />}
        </Wrapper>
    );
};

export default Map;
