import PageWrapper from '@Components/PageWrapper';
import styled from 'styled-components';
import { Row, Col, Carousel } from 'antd';
import { between } from 'polished';
import Marker from '@Assets/icons/marker.svg';

export default styled(PageWrapper)`
    background: #252525;
    height: auto;

    .w-100vw {
        width: 100vw;
    }

    .w-20vw {
        width: 20vw;
    }

    .eqh-col-wrapper {
        display: flex;
    }

    .eqh-cols {
        display: flex;
        flex: 1;
    }

    .eqh-wrapper {
        display: flex;
        flex-flow: column;
    }

    .eqh-md-text {
        flex: 0 1 90px;
    }
    .eqh-lg-text {
        flex: 0 1 110px;
    }

    .eqh-img {
        flex: 1 1 auto;
    }

    .eqh-text {
        flex: 0 1 70px;
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .pnlm-container {
        background: transparent;
    }

    .test {
        .pnlm-sprite {
            background: red;
        }
    }

    .padding-horizontal {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .padding-vertical {
        padding-top: ${between('110px', '140px', '375px', '1920px')};
        padding-bottom: ${between('10px', '40px', '375px', '1920px')};
    }

    .banner-pl-sm {
        padding-left: ${between('30px', '60px')};
    }

    .banner-pl-md {
        padding-left: ${between('60px', '120px')};
    }

    .banner-pl-lg {
        padding-left: ${between('120px', '240px')};
    }

    .mt-xs {
        margin-top: 5px;
    }

    .pl-xs {
        padding-left: 10px;
    }

    .pr-xs {
        padding-right: 10px;
    }

    .pl-sm {
        padding-left: 20px;
    }

    .pr-sm {
        padding-right: 20px;
    }

    .pl-generic {
        padding-left: 40px;
    }

    .pr-generic {
        padding-right: 40px;
    }

    .pr-big {
        padding-right: 60px;
    }

    .move-top {
        height: 100%;
        position: absolute;
        bottom: 60px;
        z-index: 10;
    }

    .mb-sm-static {
        margin-bottom: 32px;
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 70px;
    }

    .vertical-sb {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
    }

    .vertical-sa {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        justify-content: space-around;
    }

    .vertical-end {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .min-height-400 {
        min-height: 525px;
    }

    .mb-xsmall {
        margin-bottom: 5vh;
    }

    .mb-small {
        margin-bottom: 10vh;
    }

    .mb-medium {
        margin-bottom: 20vh;
    }

    .mb-big {
        margin-bottom: 30vh;
    }

    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .ant-col:first-child {
        padding-left: 0 !important;
    }

    .ant-col:last-child {
        padding-right: 0 !important;
    }

    .border-test {
        border: 1px solid red;
    }

    .transform-top-sm {
        transform: translateY(-10%);
    }
    .transform-top-md {
        transform: translateY(-25%);
    }
    .transform-top-lg {
        transform: translateY(-40%);
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .h-100vh {
        height: 100vh;
    }

    .border-title {
        border: 1px solid #ffffff;
    }

    .horizontal-center {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
    }

    .horizontal-sb {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .pb-xs {
        padding-bottom: 5vh;
    }

    .pt-xs {
        padding-top: 5vh;
    }

    .pt-sm {
        padding-top: 10vh;
    }

    .pt-img-alt {
        padding-top: 10px;
    }

    .flex-full-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .border-light {
        border: 1px solid #ffffff;
        padding: 16px 22px;
    }

    .rtl {
        direction: rtl;
    }

    .fade-in {
        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fade-in 2s ease-in forwards;
    }

    .banner-pt {
        padding-top: ${between('30px', '110px')};
    }

    .banner-title {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '60px')};
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: rgba(0, 0, 0, 0.5);
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    .pl-reset {
        padding-left: 0px !important;
    }

    .pr-reset {
        padding-right: 0px !important;
    }

    .pnlm-load-box {
        background: transparent;
        p {
            display: none;
        }

        .pnlm-lbox {
            display: none;
        }
    }

    .min-30vh {
        min-height: 30vh;
    }

    .swiper-container {
        width: 100%;
    }

    .mySwiper {
        --swiper-theme-color: #000000;
        --swiper-navigation-size: ${between('12px', '24px')};
    }

    .swiper-slide {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
    }

    .h-50vh {
        height: 50vh;
    }

    .swiper-button-next {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: ${between('16px', '24px')};
        border-radius: ${between('16px', '24px')};
    }

    .swiper-button-prev {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: ${between('16px', '24px')};
        border-radius: ${between('16px', '24px')};
    }

    .pnlm-info {
        cursor: pointer;
        background-color: transparent !important;
        background-repeat: no-repeat;
        background-position: 55% 56.5%;
        background-size: 100% 650%;
        width: 64px;
        padding-left: 12px;
        height: 64px;
    }
`;

export const Banner = styled(Row)`
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-repeat: no-repeat;
    /* background-position: center center;
    background-size: contain; */
    height: ${({ sm }) => (sm ? '50vh' : '100vh')};
    background-position: center;
    background-size: 100% 100%;
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
`;

export const TourWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    height: ${({ sm }) => (sm ? '50vh' : '100vh')};
`;
