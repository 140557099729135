import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@Components/Button';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import * as routeURLs from '@Routes/constants';
import { Text } from '@Components/Typography/styles';
import HamburgerMenuIcon from '@Assets/icons/hamburger.svg';
import MainLogoEN from '@Assets/icons/main-logo-en.png';
import MainLogoTR from '@Assets/icons/main-logo-tr.png';
import StyledHeader from './styles';
import Drawer from './Drawer';

const Header = () => {
    const history = useHistory();
    const [isActive, setIsActive] = useState(localStorage.getItem('i18nextLng'));
    const [drawerVisible, setDrawerVisible] = useState(false);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { lg } = useResponsive();

    const onClickLogo = () => {
        history.push('/');
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const { pathname } = location;

    const handleLanguageChange = (obj) => {
        if (obj.name !== isActive) {
            localStorage.setItem('i18nextLng', obj.name);
            setIsActive(obj.name);
            i18n.changeLanguage(obj.name);
        }
    };

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = (e) => {};

    useEffect(() => {
        window.addEventListener('scroll', (e) => handleNavigation(e));

        return () => {
            // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener('scroll', (e) => handleNavigation(e));
        };
    }, [y]);

    const isAos = pathname !== `${routeURLs.ARCHIVE_OF_SILENCE_PAGE}/` && pathname.includes(`${routeURLs.ARCHIVE_OF_SILENCE_PAGE}/`);
    const isInfo = pathname.includes(`${routeURLs.INFO_PAGE}`);

    return (
        <StyledHeader isAos={isAos} isInfo={isInfo}>
            <Row style={{ height: '100%', width: '100%' }} align="middle" justify="space-between">
                <Col>
                    <Row align="middle" justify="center">
                        <Button onClick={onClickLogo} type="link" style={{ padding: '0px' }}>
                            {i18n.language === 'tr' ? (
                                <img className="header-logo" src={MainLogoTR} alt="main-logo-tr" />
                            ) : (
                                <img className="header-logo" src={MainLogoEN} alt="main-logo-en" />
                            )}
                        </Button>
                    </Row>
                </Col>
                {isAos && (
                    <>
                        {!lg && (
                            <Col md={12} lg={16} xl={16} xxl={16}>
                                <Row align="middle" justify="space-between">
                                    {t('aos.navItems', { returnObjects: true }).map(({ id, name, path }) => (
                                        <Col key={id}>
                                            <Button
                                                className={pathname.includes(path) && 'aos-active-btn'}
                                                textcolor="light"
                                                onClick={() => history.push(path)}
                                                type="link"
                                                style={{ padding: '0px', borderRadius: '0px' }}>
                                                {name}
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        )}
                    </>
                )}
                <Col>
                    <Row align="middle" justify="end">
                        <Col>
                            <Button
                                style={{ width: '50px' }}
                                textcolor="light"
                                className={isActive === 'tr' && 'active-btn-element'}
                                type="link"
                                onClick={() => handleLanguageChange({ name: 'tr' })}>
                                TR
                            </Button>
                        </Col>
                        <Text size="20px" color="light" weight="bold">
                            |
                        </Text>
                        <Col>
                            <Button
                                style={{ width: '50px' }}
                                textcolor="light"
                                className={isActive === 'en' && 'active-btn-element'}
                                type="link"
                                onClick={() => handleLanguageChange({ name: 'en' })}>
                                EN
                            </Button>
                        </Col>
                        {lg && isAos && (
                            <Col className="margin-left-hamburger">
                                <Button onClick={() => showDrawer()} type="link" style={{ padding: '0px' }}>
                                    <img src={HamburgerMenuIcon} alt="hamburger-menu-icon" />
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            {isAos && <Drawer width="100%" placement="right" closable onClose={closeDrawer} visible={drawerVisible} />}
        </StyledHeader>
    );
};

export default Header;
