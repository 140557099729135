export default [
    {
        id: 1,
        family: 'Yaeş',
        color: 'rgba(93, 86, 95, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Çelebon Yaeş Şener',
                lat: '39.934178',
                lng: '32.853262',
            },
            {
                id: 'm-2',
                name: 'Moiz Yaeş',
                lat: '39.934046',
                lng: '32.853230',
            },
        ],
    },
    {
        id: 2,
        family: 'Kişiler',
        color: 'rgba(96, 108, 56, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Jak Fermon',
                lat: '39.934235',
                lng: '32.853230',
            },
        ],
    },
];
