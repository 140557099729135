/* eslint-disable sonarjs/no-nested-template-literals */
import { Button } from 'antd';
import styled from 'styled-components';

// Button types  primary , secondary , bordered-primary , bordered-secondary , link

const BORDERED_PRIMARY = 'bordered-primary';
const BORDERED_SECONDARY = 'bordered-secondary';
const SECONDARY = 'secondary';
const LINK = 'link';

const borderStyles = (styles, theme) => {
    switch (styles) {
        case BORDERED_PRIMARY:
            return `1px solid ${theme.colors.light}`;
        case BORDERED_SECONDARY:
            return `1px solid ${theme.colors.black}`;
        default:
            return 'none';
    }
};

const backgroundColor = (styles, theme) => {
    switch (styles) {
        case BORDERED_PRIMARY:
            return 'transparent';
        case BORDERED_SECONDARY:
            return 'transparent';
        case LINK:
            return 'transparent';
        case SECONDARY:
            return theme.colors.black;
        default:
            return theme.colors.light;
    }
};

const hoverBackgroundColor = (styles, theme) => {
    switch (styles) {
        case SECONDARY:
            return theme.colors.light;
        case BORDERED_PRIMARY:
            return theme.colors.light;
        case BORDERED_SECONDARY:
            return theme.colors.black;
        case LINK:
            return 'transparent';
        default:
            return theme.colors.black;
    }
};

const hoverBorderStyles = (styles, theme) => {
    switch (styles) {
        case BORDERED_PRIMARY:
            return `1px solid ${theme.colors.light}`;
        case BORDERED_SECONDARY:
            return `1px solid ${theme.colors.black}`;
        default:
            return `none`;
    }
};

const textColor = (styles, theme, textcolor) => {
    switch (styles) {
        case BORDERED_PRIMARY:
            return theme.colors.light;
        case BORDERED_SECONDARY:
            return theme.colors.black;
        case LINK:
            return textcolor;
        case SECONDARY:
            return theme.colors.light;
        default:
            return theme.colors.black;
    }
};

const hoverTextColor = (styles, theme, textcolor, hovertextcolor) => {
    switch (styles) {
        case BORDERED_PRIMARY:
            return theme.colors.black;
        case BORDERED_SECONDARY:
            return theme.colors.light;
        case LINK:
            return hovertextcolor;
        case SECONDARY:
            return theme.colors.black;
        default:
            return theme.colors.light;
    }
};

export default styled(Button)`
    border-radius: 20px;
    width: ${({ buttonWidth }) => buttonWidth || '100%'};
    height: auto;

    background-color: ${({ styles, theme }) => `${backgroundColor(styles, theme)}`};
    border: ${({ styles, theme }) => `${borderStyles(styles, theme)}`};

    span {
        color: ${({ styles, theme }) => `${textColor(styles, theme)} !important`};
    }

    &:hover {
        background-color: ${({ styles, theme }) => hoverBackgroundColor(styles, theme)};
        border: ${({ styles, theme }) => hoverBorderStyles(styles, theme)};

        span {
            color: ${({ styles, theme }) => `${hoverTextColor(styles, theme)} !important`};
        }
    }
    &:focus {
        background-color: ${({ styles, theme }) => hoverBackgroundColor(styles, theme)};
        border: ${({ styles, theme }) => hoverBorderStyles(styles, theme)};

        span {
            color: ${({ styles, theme }) => `${hoverTextColor(styles, theme)} !important`};
        }
    }
`;
