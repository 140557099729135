/* eslint-disable sonarjs/no-duplicate-string */
export const slider1Data = [
    {
        id: 1,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F1.jpg?alt=media&token=55c5fbcc-ab91-48fb-aac7-382ec767fa54',
        imgAltTR: 'Ankara Sinagogu avlusu.',
        imgAltDescTR:
            '[VEKAM Kütüphanesi ve Arşivi - Taşınmaz Kültür Varlıkları Envanteri, ‘Havra Yahudi Kilisesi’, Yayın: M.Akok, Ankara’nın Eski Evleri, Ankara, 1951. E.Kömürcüoğlu, Ankara Evleri, İstanbul, 1950]',
        imgAltEN: 'Courtyard of the Ankara Synagogue',
        imgAltDescEN:
            "[VEKAM Library and Archive - Immovable Cultural Heritage Inventory, 'The Jewish Synagogue', Publication: M.Akok, ‘Old Houses of Ankara’, Ankara, 1951. E.Kömürcüoğlu, ‘Houses of Ankara’, Istanbul, 1950]",
    },
    {
        id: 2,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F2.jpg?alt=media&token=9a1bed0e-50cd-46b3-a955-dac1486c3939',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 3,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F3.jpg?alt=media&token=eda0824c-f82d-43c2-ae20-455d2d3d966c',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 4,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F4.jpg?alt=media&token=8f4a0e9b-1d66-4a8a-b408-1324f36c452d',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 5,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F5.jpg?alt=media&token=8efb1ae4-ad50-41d1-b8e7-4b5a4f24a5a7',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 6,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F6.jpg?alt=media&token=439aea64-ebd8-4864-91dd-5590e1065b37',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 7,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F7.jpg?alt=media&token=fbedc85b-1ad8-4389-b83a-3e4be5caa8ef',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 8,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F8.jpg?alt=media&token=da809285-9c7e-4485-8d54-36aa120b9e09',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 9,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F9.jpg?alt=media&token=c26be41a-20e3-4a62-8f26-5735cb86f0da',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 10,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F10.jpg?alt=media&token=e99640e0-e9f6-4c8e-b33a-13c332340177',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 11,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F11.jpg?alt=media&token=f662be61-937c-4f1e-b4f3-4e0563c96313',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 12,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F12.jpg?alt=media&token=1bb261ca-ebb0-438d-9d7d-48a6d9cbf5cd',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 13,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F13.jpg?alt=media&token=c2048171-8ab3-49f1-bcc9-a4a84a60152a',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: "[“Ankara'nın Eski Kent Dokusunda Yahudi Mahallesi ve Sinagog”, Belleten, Türk Tarih Kurumu, Aralık 1996, Fügen İlter Arşivi]",
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN:
            '[“Jewish Quarter and Synagogue in Old City Texture of Ankara”, Belleten, Turkish Historical Association, December 1996, Fügen İlter Archive]',
    },
    {
        id: 14,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F14.jpg?alt=media&token=91f2c347-24c2-4467-9b5a-a87d2ba00144',
        imgAltTR: 'Ankara Sinagogu’nun içi. Tevrat’ların konulduğu dolap açık vaziyette.',
        imgAltDescTR: '[Beki Bahar Arşivi, “Efsaneden Tarihe Ankara Yahudileri”, Pan Yayıncılık, 2003]',
        imgAltEN: 'The interior of the Ankara Synagogue. The cabinet where the Torahs are kept is open. ',
        imgAltDescEN: '[Beki Bahar Archive, “The Jews of Ankara from Legend to History”, Pan Publishing, 2003]',
    },
    {
        id: 15,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F15.jpg?alt=media&token=f15bb676-2ccc-4de2-a18e-87f262098d2a',
        imgAltEN: 'Ankara Synagogue, fountain in the courtyard',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu, avlu çeşmesi',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 16,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F16.jpg?alt=media&token=0d72e5b5-c621-4246-a8f2-e0a2fb8a6b1b',
        imgAltEN: 'Ankara Synagogue, fountain in the courtyard',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu, avlu çeşmesi',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 17,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F17.jpg?alt=media&token=278a9418-9540-48fa-a63b-859a5a2f3e68',
        imgAltEN: 'Ankara Synagogue, fountain in the courtyard',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu, avlu çeşmesi',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 18,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F18.jpg?alt=media&token=588ad480-de1a-4f85-b508-b5c91412ac62',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    ///
    {
        id: 19,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F19.jpg?alt=media&token=d74b9055-7558-4b62-a949-e48c463e1555',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 20,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F20.jpg?alt=media&token=63eefefa-92c6-4f32-8c67-9b83fa9cf166',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 21,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F21.jpg?alt=media&token=9891a228-e00f-42bc-92c1-36826dcdf629',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 22,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F22.jpg?alt=media&token=bf42c2c4-45d7-44ba-b38d-ad153b2fcc24',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 23,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F23.jpg?alt=media&token=62f3ca30-476c-4123-a43d-e1417754c910',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 24,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F24.jpg?alt=media&token=830168f8-d528-416f-9150-e55e16f669ad',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 25,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F25.jpg?alt=media&token=7d6eac82-e51c-48d7-986d-b3144f859903',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 26,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F26.jpg?alt=media&token=e0259edd-822e-43f8-bfa5-dda8998f45c9',
        imgAltEN: 'Ankara Synagogue',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2017]',
        imgAltTR: 'Ankara Sinagogu',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2017]',
    },
    {
        id: 27,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider1%2F27.jpg?alt=media&token=439bf467-6077-4db4-b059-e71d4708c79c',
        imgAltEN:
            'Ankara Synagogue, the inscription on the entrance. It is written “Ten Commandments” at the top and “For my house shall be called a house of prayer for all nations, Ankara 5667-1907” at the bottom.',
        imgAltDescEN: '[Photo: Uğur Kavas, 2017]',
        imgAltTR:
            'Ankara sinagogu girişindeki yazıt. Üstte “On Emir”, altta “Evime bütün milletler için dua evi denecek, Ankara 5667-1907” yazılıdır.',
        imgAltDescTR: '[fotoğraf: Uğur Kavas, 2017]',
    },
];

export const slider2Data = [
    {
        id: 1,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F1.jpg?alt=media&token=98fdb777-97cb-4f3b-a2e6-4888867122ef',
        imgAltTR: 'Ankara Yahudi Mahallesi, Ressam Muhammed’in Evi',
        imgAltDescTR: '[NETlab Archive, 2021]',
        imgAltEN: 'Painter Muhammed’s House, Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 2,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F2.jpg?alt=media&token=ba3de262-1f2b-43b4-8d33-0c2c915395a6',
        imgAltTR: 'Ankara Yahudi Mahallesi, Ressam Muhammed’in Evi',
        imgAltDescTR: '[NETlab Archive, 2021]',
        imgAltEN: 'Painter Muhammed’s House, Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 3,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F3.jpg?alt=media&token=440bcb18-b835-4aee-b31c-6d0638fab6a1',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, 2013] ',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: Yavuz İşçen, 2013]',
    },
    {
        id: 4,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F4.jpg?alt=media&token=8389a651-2989-4791-a590-001e9df3a2eb',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 5,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F5.jpg?alt=media&token=0e6da9b9-0788-466c-9e91-0eb46bc0c156',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 6,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F6.jpg?alt=media&token=205a87ef-2dde-4237-893b-d6a797ce8d15',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 7,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F7.jpg?alt=media&token=68ef2d18-2204-4211-b455-ab49865f075c',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 8,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F8.jpg?alt=media&token=d188236c-79f6-4250-904e-2ef3b64a09d0',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 9,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F9.jpg?alt=media&token=355a4d52-5bd1-445e-a67d-3a8f4cd0cc8e',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 10,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F10.jpg?alt=media&token=56ead5c7-3f85-4185-92e7-ecf7f58b21f1',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Archive, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 11,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F11.jpg?alt=media&token=f8df113b-b626-4475-af34-4e3742bafe0b',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 12,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F12.jpg?alt=media&token=f55f7050-31b9-4903-8b37-3018802792fd',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 13,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider2%2F13.jpg?alt=media&token=f3cd945c-cc4f-433e-98f2-33212bd1186f',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
];

export const slider3Data = [
    {
        id: 1,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F1.jpg?alt=media&token=bebdda99-b91a-4e48-a31a-42d9a2aac955',
        imgAltTR: 'Ankara Yahudi Mahallesi, Ankara Sinagogu giriş kapısı',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Entrance door of The Ankara Synagogue',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 2,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F2.jpg?alt=media&token=c6b974e4-0daa-436d-be23-22a74ae895c0',
        imgAltTR: 'Ankara Yahudi Mahallesi, Ankara Sinagogu giriş kapısı',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Entrance door of The Ankara Synagogue',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 3,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F3.jpg?alt=media&token=23790609-a8bb-4d1c-ab59-595ac1ff7384',
        imgAltTR: 'Ankara Yahudi Mahallesi, Bonomo Ailesi Evi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'The house of Bonomo Family, Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 4,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F4.jpg?alt=media&token=5fef43a5-5944-48eb-8fee-8729e4176761',
        imgAltTR: 'Ankara Yahudi Mahallesi, Bonomo Ailesi Evi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'The house of Bonomo Family, Jewish Quarter, Ankara',
        imgAltDescEN: '[Fotoğraf: N. Müge Cengizkan, 2021]',
    },
    {
        id: 5,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F5.jpg?alt=media&token=074537e0-6317-40b6-8ddd-80e7a1996a5a',
        imgAltTR: 'Ankara Yahudi Mahallesi, Araf Ailesi Evi giriş kapısı',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Entrance door of the house of Araf Family,  Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021] ',
    },
    {
        id: 6,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F6.jpg?alt=media&token=8b6ad5b8-025e-47a8-99dd-487775163b12',
        imgAltTR: 'Ankara Yahudi Mahallesi, Albukrek Ailesi Evi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'The house of Albukrek Family, Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021] ',
    },
    {
        id: 7,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F7.jpg?alt=media&token=62d5dfa4-ad83-43d5-b473-54b0b8049c69',
        imgAltTR: 'Ankara Yahudi Mahallesi, Albukrek Ailesi Evi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'The house of Albukrek Family, Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
    {
        id: 8,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F8.jpg?alt=media&token=048c6299-71d4-4ea4-9b97-082ab973f890',
        imgAltTR: 'Ankara Yahudi Mahallesi, Albukrek Ailesi Evi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'The house of Albukrek Family, Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
    {
        id: 9,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider3%2F9.jpg?alt=media&token=c1f40cf3-ddd9-42d9-a32e-2cf82960a4bf',
        imgAltTR: 'Ankara Yahudi Mahallesi, Araf Ailesi Evi giriş kapısı',
        imgAltDescTR: '[UrbanObscura Arşivi, 2015]',
        imgAltEN: 'Entrance door of the house of Araf Family,  Jewish Quarter, Ankara',
        imgAltDescEN: '[UrbanObscura Archive, 2015]',
    },
];

export const slider4Data = [
    {
        id: 1,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F1.jpg?alt=media&token=ef65904c-e0ad-4b74-88e3-504ff044c4ab',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
    {
        id: 2,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F2.jpg?alt=media&token=8a75331a-2e5e-4d2e-8bbc-f99dc564fab4',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 3,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F3.jpg?alt=media&token=734e0171-1aab-42ea-b4df-3437ef0017f9',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 4,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F4.jpg?alt=media&token=d638dafc-26cd-41ff-b424-e6933f640058',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 5,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F5.jpg?alt=media&token=71997be6-13a9-4827-ba44-8531c5a326fc',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 6,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F6.jpg?alt=media&token=79d8b340-9734-48ce-bb98-03b28670a0d6',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[NETlab Arşivi, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 7,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F7.jpg?alt=media&token=841df67d-7e70-4f79-85be-ba8ac7033233',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
    {
        id: 8,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider4%2F8.jpg?alt=media&token=8f84dcf3-580b-4c05-a507-2f3ed062c77e',
        imgAltTR: 'Ankara Yahudi Mahallesi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'Jewish Quarter, Ankara',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021',
    },
];

export const slider5Data = [
    {
        id: 1,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F1.jpg?alt=media&token=8f3c9254-77d1-4ef6-a394-b215de32bb55',
        imgAltTR: 'Bonomo Ailesi Evi',
        imgAltDescTR: '[Ali Cengizkan Arşivi, 1986]',
        imgAltEN: 'The house of Bonomo Family',
        imgAltDescEN: '[Ali Cengizkan Archive, 1986]',
    },
    {
        id: 2,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F2.jpg?alt=media&token=7c990b20-0926-4649-8983-b4d4ef187f24',
        imgAltTR: 'Araf Ailesi Evi',
        imgAltDescTR: '[Ali Cengizkan Arşivi, 1986]',
        imgAltEN: 'The house of Araf Family',
        imgAltDescEN: '[Ali Cengizkan Archive, 1986]',
    },
    {
        id: 3,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F3.jpg?alt=media&token=592e86e3-6eab-48ae-b990-21cd55a3ade3',
        imgAltTR: 'Araf Ailesi Evi',
        imgAltDescTR: '[NETlab Arşivi, Haziran 2021]',
        imgAltEN: 'The house of Araf Family',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 4,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F4.jpg?alt=media&token=ba458424-50c2-4558-9062-6f0669821aa6',
        imgAltTR: 'Araf Ailesi Evi',
        imgAltDescTR: '[Fotoğraf: Müge Cengizkan, 2021]',
        imgAltEN: 'The house of Araf Family',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
    {
        id: 5,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F5.jpg?alt=media&token=85e9d42a-bee2-46ee-a30e-89fd7b6880bf',
        imgAltTR: 'Albukrek Ailesi Evi',
        imgAltDescTR: '[NETlab Arşivi, Haziran 2021]',
        imgAltEN: 'The house of Albukrek Family',
        imgAltDescEN: '[NETlab Archive, 2021]',
    },
    {
        id: 6,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F6.jpg?alt=media&token=7e947edb-b6fd-4a06-b3d2-149a754cc073',
        imgAltTR: 'Çelebonaçi Krespi Evi',
        imgAltDescTR: '[Fotoğraf: Yavuz İşçen, Haziran 2013]',
        imgAltEN: 'The house of Çelebonaçi Krespi',
        imgAltDescEN: '[Photo: Yavuz İşçen, Haziran 2013]',
    },
    {
        id: 7,
        url: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fslider5%2F7.jpg?alt=media&token=e74fd407-2679-46ce-89c8-22f695fab752',
        imgAltTR: 'Yasef Ruso Evi',
        imgAltDescTR: '[Fotoğraf: N. Müge Cengizkan, 2021]',
        imgAltEN: 'The house of Yasef Ruso',
        imgAltDescEN: '[Photo: N. Müge Cengizkan, 2021]',
    },
];
