/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { Text } from '@Components/Typography/styles';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
// import { AnimatePresence, motion } from 'framer-motion';
import useResponsive from '@Hooks/useMediaQuery';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import Carousel from '@Components/Carousel';
import { canonicals } from '@Helpers';
import { Helmet } from 'react-helmet';
import StyledBordersPage, { Img, Banner } from './styles';
import VideoCompare from './Compare';

const BordersPage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    const slider1Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider1%2F1.jpg?alt=media&token=367e7b23-d7d4-45e9-80ac-fa37cb6f8e03',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider1%2F2.jpg?alt=media&token=f2c03ebf-00f5-42fd-aa3a-c7d74fd1bc39',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider1%2F3.jpg?alt=media&token=01fc01ff-33b1-47d9-a852-6727ccf22dd1',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider1%2F4.jpg?alt=media&token=c2d6d869-2bad-438a-933f-039c3f171a82',
            },
        ],
        imgAlt: t('aos.borders.section23.sliderAlt'),
        imgAltDesc: t('aos.borders.section23.sliderAltDesc'),
    };

    const slider2Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider2%2F1.jpg?alt=media&token=585e7fe3-f645-4288-8d3f-8409f99bcdbe',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider2%2F2.jpg?alt=media&token=ef161578-fb0f-4ff9-9719-29445cd8a609',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider2%2F3.jpg?alt=media&token=557fa3c0-7933-4874-bcda-cf55b9b70b86',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider2%2F4.jpg?alt=media&token=2b6a164d-4a0d-4c13-a024-b26386d9e4a3',
            },
        ],
        imgAlt: t('aos.borders.section25.sliderAlt'),
        imgAltDesc: t('aos.borders.section25.sliderAltDesc'),
    };

    const slider3Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider3%2F1.jpg?alt=media&token=dee89481-a7c3-4354-ab04-18679c1a3c9e',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider3%2F2.jpg?alt=media&token=0f9d3b88-0259-459d-a359-84ca55b702d2',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fslider3%2F3.jpg?alt=media&token=fbf0f688-3b0f-4427-a4a5-99299ce2d277',
            },
        ],
        imgAlt: t('aos.borders.section26.sliderAlt'),
        imgAltDesc: t('aos.borders.section26.sliderAltDesc'),
    };

    return (
        <StyledBordersPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.borders')}</title>
                <link rel="canonical" href={canonicals.borders} />
            </Helmet>
            <Banner
                sm={sm}
                className="padding-horizontal padding-vertical"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs1.jpg?alt=media&token=a917b9dd-f256-4695-9c89-b351727abeb5">
                <Col className="vertical-sb" span={24}>
                    <Row className="letterspacing-fix " align="middle" justify="center">
                        <Text className="title-styles" size="140px" letterSpacing="big" color="transparent" weight="700">
                            {t('aos.borders.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" weight="600">
                            {t('aos.borders.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.borders.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section1.text')}
                </Text>
            </Row>
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs2.jpg?alt=media&token=88095164-f723-41fa-a6f3-42c5ee6600bd"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section2.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section2.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section2.text')}
                </Text>
            </Row>
            <VideoCompare style={!sm && { minHeight: '100vh' }} className={!sm && 'padding-horizontal-lg'} />

            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs4.jpg?alt=media&token=7d3cba81-456e-4bf9-a5e9-b6850d502c2e"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section4.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section4.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section4.text')}
                </Text>
            </Row>
            {/* section5 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs5.jpg?alt=media&token=6eac4e24-fed0-4fa4-ad03-a6ac6482928c"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section5.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section5.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col span={24}>
                    {t('aos.borders.section5.entries', { returnObjects: true }).map(({ id, name }) => (
                        <Row key={`${id}-section5`}>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {`${id} - ${name}`}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>
            {/* section6 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs6.jpg?alt=media&token=b3c93344-90c0-42f0-888d-9db8eecb56dd"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section6.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section6.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col span={24}>
                    {t('aos.borders.section6.entries', { returnObjects: true }).map(({ id, name }) => (
                        <Row key={`${id}-section6`}>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {`${id} - ${name}`}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>
            {/* section7 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs7.jpg?alt=media&token=fe465525-d0f6-4b22-81b3-844efee8bdb1"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section7.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section7.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col span={24}>
                    {t('aos.borders.section7.entries', { returnObjects: true }).map(({ id, name }) => (
                        <Row key={`${id}-section7`}>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {`${id} - ${name}`}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs8.jpg?alt=media&token=2d986ef1-3577-4283-aad8-af77ae4c686d" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section8.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section8.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.borders.section8.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal">
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs9.png?alt=media&token=58b989c8-64a7-4d1d-9531-33e55b8b9005"
                />
            </Row>
            <Row className={sm ? 'pt-img-alt padding-horizontal mb-sm' : 'pt-img-alt padding-horizontal transform-top-lg mb-lg'}>
                <Col span={24}>
                    <Row align="middle" style={{ marginBottom: '16px' }}>
                        <div className="dot" style={{ backgroundColor: 'rgba(248, 36, 14, 1)' }} />
                        <Text displayblock color="grey" size="21px">
                            {t('aos.borders.section9.red.name')}
                        </Text>
                    </Row>
                    <Row align="middle" style={{ marginBottom: '16px' }}>
                        <div className="dot" style={{ backgroundColor: 'rgba(85, 75, 148, 1)' }} />

                        <Text displayblock color="grey" size="21px">
                            {t('aos.borders.section9.purple.name')}
                        </Text>
                    </Row>
                    <Row align="middle" style={{ marginBottom: '16px' }}>
                        <div className="dot" style={{ backgroundColor: 'rgba(254, 243, 29, 1)' }} />
                        <Text displayblock color="grey" size="21px">
                            {t('aos.borders.section9.yellow.name')}
                        </Text>
                    </Row>
                    <Row>
                        <Text displayblock color="grey">
                            {t('aos.borders.section9.imgAlt')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            {/* section10 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs10.jpg?alt=media&token=71cd500c-9203-43ec-a0fc-c2acbe2ce454"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section10.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section10.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col span={24}>
                    {t('aos.borders.section10.entries', { returnObjects: true }).map(({ id, name }) => (
                        <Row key={`${id}-section10`}>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {`${id} - ${name}`}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>
            {/* section11 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs11.jpg?alt=media&token=f948939a-a46a-422e-bfa4-873f6745a146"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section11.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section11.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            {/* section12 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs12.jpg?alt=media&token=c2a4249d-4b86-4e12-85ba-49973a3bd3dc"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section12.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section12.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col span={24}>
                    {t('aos.borders.section12.entries', { returnObjects: true }).map(({ id, name }) => (
                        <Row key={`${id}-section12`}>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {`${id} - ${name}`}
                            </Text>
                        </Row>
                    ))}
                </Col>
            </Row>
            {/* section13 */}
            <Row justify="space-between" className="padding-horizontal-lg mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center eqh-wrapper'} sm={24} md={12}>
                    <Row style={{ paddingBottom: '60px' }}>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.borders.section13.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={10}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs13-2.jpg?alt=media&token=caa0cb07-a716-4cef-ac78-df8bbabcd84f" />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section13.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section13.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-sm padding-horizontal-lg">
                <Col span={10}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs13-1.jpg?alt=media&token=7826e7d4-735a-463f-95b4-c0b923f34fc8" />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section13.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section13.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section14 */}

            <Row className="mb-sm padding-horizontal">
                <Text color="light" size="21px" weight="400">
                    {t('aos.borders.section14.text')}
                </Text>
            </Row>

            {/* section15 */}
            <Row className="padding-horizontal-lg mb-sm">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs15.png?alt=media&token=6e4be105-e42b-4089-be97-47c176b19150" />
                <Row className={sm ? 'pt-img-alt' : 'pt-img-alt transform-top-50'}>
                    <Col>
                        <Text displayblock color="grey">
                            {t('aos.borders.section15.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.borders.section15.imgAltDesc')}
                        </Text>
                    </Col>
                </Row>
            </Row>
            {/* section16 */}
            <Row className="padding-horizontal-lg mb-sm">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs16-1.jpg?alt=media&token=4b83c84d-d1fa-49bb-b318-53d1e5fe5e85" />
                <Row align="middle" justify={!sm && 'space-between transform-top-lg'} className="pt-img-alt w-100">
                    <Col order={sm ? 2 : 1} sm={24} md={6}>
                        <Row>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs16-2.jpg?alt=media&token=678670b6-f589-4cd2-8f4d-d26df757eff8" />
                        </Row>
                        <Row>
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.borders.section16.imgAlt2')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.borders.section16.imgAlt2Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col order={sm ? 1 : 2} sm={24} md={18}>
                        <Row align="middle" justify={sm ? 'start' : 'end'}>
                            <Text displayblock color="grey">
                                {t('aos.borders.section16.imgAlt1')}
                            </Text>
                        </Row>
                        <Row align="middle" justify="end">
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section16.imgAlt1Desc')}
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Row>
            {/* section17 */}
            <Row className="padding-horizontal-lg">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs17-1.jpg?alt=media&token=cd1ee253-d979-4770-9986-1bc80ef45621" />
                <Row align="middle" justify={!sm && 'space-between transform-top-lg'} className="pt-img-alt w-100">
                    <Col sm={24} md={18}>
                        <Row align="middle" justify="start">
                            <Text displayblock color="grey">
                                {t('aos.borders.section17.imgAlt1')}
                            </Text>
                        </Row>
                        <Row align="middle" justify="start">
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section17.imgAlt1Desc')}
                            </Text>
                        </Row>
                    </Col>
                    <Col sm={24} md={6}>
                        <Row>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs17-2.jpg?alt=media&token=7051c82f-719e-44fe-b048-d25eb4eae455" />
                        </Row>
                        <Row>
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.borders.section17.imgAlt2')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.borders.section17.imgAlt2Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row
                className={sm ? 'padding-horizontal-lg ' : 'padding-horizontal-lg transform-top-lg'}
                align="middle"
                justify="start"
                gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={12} md={8}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs14.jpg?alt=media&token=0d478640-0833-42b7-b4df-f40ea9bf41ed" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section14.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section14.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section18 */}
            <Row className={sm ? 'padding-horizontal-lg mb-sm' : 'padding-horizontal-lg transform-top-md'} gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.borders.section18.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs18.jpg?alt=media&token=4af88ff7-ce38-4b88-9ecb-956926e39bf0" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section18.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section18.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section19 */}
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs19-1.jpg?alt=media&token=d8fc7a5d-459d-46c5-a20b-fef9ab45e134" />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section19.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section19.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs19-2.jpg?alt=media&token=99280b50-30b4-43ea-a370-5fc54c91c41c" />
                    <Row className="pt-img-alt eqh-text" eqh-text>
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section19.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section19.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section20 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs20.png?alt=media&token=024be2e0-53fd-4874-92f7-d033f4594daf"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section20.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section20.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section20.text')}
                </Text>
            </Row>
            {/* section21  video */}
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    {/* video here  */}
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs21-1.jpg?alt=media&token=ca389103-60d7-4855-bd44-d793effdc7f9"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section21.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section21.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Row className="eqh-img">
                        <iframe
                            className="iframe-style"
                            src="https://www.youtube.com/embed/jw1jvzIQhS0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Row>
                    <Row className="pt-img-alt eqh-md-text" eqh-text>
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.borders.section21.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.borders.section21.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section22 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs22.jpg?alt=media&token=155cebf1-01c2-480d-aa0e-a52296396f7a"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section22.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section22.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section22.text')}
                </Text>
            </Row>
            {/* section23 */}
            <Carousel className="mb-sm" type="slider1" data={slider1Data} />
            {/* section24 */}
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FS%C4%B1n%C4%B1rlar%2Fs24.jpg?alt=media&token=2c912438-fdda-44c1-9956-2a3498c6a9dd"
                />
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.borders.section24.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.borders.section24.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" align="middle" justify="center">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.borders.section24.text')}
                </Text>
            </Row>
            {/* section25 */}
            <Carousel className="mb-sm" type="slider2" data={slider2Data} />
            {/* section26 */}
            <Carousel className="pb-md" type="slider3" data={slider3Data} />
        </StyledBordersPage>
    );
};

export default BordersPage;
