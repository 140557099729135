import React, { useState, useEffect } from 'react';
import { Text } from '@Components/Typography/styles';
import useFetchFirestoreData from '@Hooks/useFetchFirestoreData';
import LoadingSpinner from '@Components/LoadingSpinner';
import useResponsive from '@Hooks/useMediaQuery';
import PhotoSlider from '@Components/PhotoSlider';
import VideoSlider from '@Components/VideoSlider';
import TextSlider from '@Components/TextSlider';
import { StyledModal, ModalContentWrapper } from './styles';

const VirtualTourModal = ({ key, clickedVtElementData, isModalVisible, handleCancel }) => {
    const { ismobile } = useResponsive();
    const [photoDatas, setPhotoDatas] = useState();
    const [textDatas, setTextDatas] = useState();
    const [videoDatas, setVideoDatas] = useState();
    const [generalInfoDatas, setGeneralInfoDatas] = useState();
    const [documents, fetchError, setDocuments] = useFetchFirestoreData(clickedVtElementData && clickedVtElementData.currentRef);

    useEffect(() => {
        if (documents) {
            if (documents[0].htmlContentTR) {
                setTextDatas(documents);
            } else if (documents[0].dubsrcTR) {
                setGeneralInfoDatas(documents);
            } else if (documents[0].videoSRC) {
                setVideoDatas(documents);
            } else {
                setPhotoDatas(documents);
            }
            return () => {
                setTextDatas();
                setGeneralInfoDatas();
                setVideoDatas();
                setPhotoDatas();
            };
        }
    }, [documents]);

    const triggerCancel = () => {
        handleCancel();
    };

    return (
        <StyledModal
            // title={
            //     <Text size="noble-title" color="light">
            //         Haritalar
            //     </Text>
            // }

            key={key}
            width={ismobile ? 600 : 1140}
            centered
            destroyOnClose
            visible={isModalVisible}
            onCancel={triggerCancel}>
            <ModalContentWrapper destroyOnClose span={24}>
                {photoDatas && (
                    <PhotoSlider documents={photoDatas.sort(({ orderId: previousID }, { orderId: currentID }) => previousID - currentID)} />
                )}
                {videoDatas && (
                    <VideoSlider documents={videoDatas.sort(({ orderId: previousID }, { orderId: currentID }) => previousID - currentID)} />
                )}
                {textDatas && <TextSlider documents={textDatas.sort(({ orderId: previousID }, { orderId: currentID }) => previousID - currentID)} />}
            </ModalContentWrapper>
        </StyledModal>
    );
};

export default VirtualTourModal;
