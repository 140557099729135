import styled from 'styled-components';
import { Row, Col } from 'antd';
import PageWrapper from '@Components/PageWrapper';
import { between } from 'polished';

export default styled(PageWrapper)`
    background: #252525;

    .banner-title {
        letter-spacing: -0.16em;
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .vertical-sb {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
    }

    .vertical-sa {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        justify-content: space-around;
    }

    .vertical-end {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .font-title {
        /* font-family: Fredericka the Great !important; */
    }

    .flex-full-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mb-xs {
        margin-bottom: 5vh;
    }

    .mb-sm {
        margin-bottom: 10vh;
    }

    .mb-md {
        margin-bottom: 20vh;
    }

    .fade-in {
        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fade-in 2s ease-in forwards;
    }

    .padding-horizontal-sm {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .padding-horizontal {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .padding-vertical {
        padding-top: ${between('110px', '300px', '375px', '1920px')};
        padding-bottom: ${between('10px', '40px', '375px', '1920px')};
    }

    .pb-md {
        padding-bottom: 20vh;
    }

    .bg-color-main {
        background: #252525;
    }
`;

export const Banner = styled(Row)`
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-repeat: no-repeat;
    /* background-position: center center;
    background-size: contain; */
    height: ${({ sm }) => (sm ? '40vh' : '100vh')};
    background-position: center;
    background-size: 100% 100%;
`;
