import React from 'react';
import Maps from '@Components/TrailsMap';
import { Col, Row } from 'antd';
import { Text } from '@Components/Typography/styles';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import { canonicals } from '@Helpers';
import { Helmet } from 'react-helmet';
import CommunityStyles, { Banner } from './styles';

const RemainsPage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    return (
        <CommunityStyles>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.trails')}</title>
                <link rel="canonical" href={canonicals.trails} />
            </Helmet>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C4%B0zler%2Fbanner.jpg?alt=media&token=1feaee18-9df2-4da2-96b2-318466254c7d">
                <Col className="vertical-sb padding-vertical" span={24}>
                    <Row align="middle" justify="center">
                        <Text className="banner-title" family="secondary" size="140px" color="rgba(255,255,255,0.4)" weight="700">
                            {t('aos.trails.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" weight="600">
                            {t('aos.trails.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            {/* TODO */}
            <Row className="padding-horizontal pt-img-alt pb-md bg-color-main">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.trails.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.trails.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Maps style={{ height: '100vh' }} />
        </CommunityStyles>
    );
};

export default RemainsPage;
