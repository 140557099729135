import styled from 'styled-components';
import { Row, Col } from 'antd';
import Button from '@Components/Button';

export default styled(Row)`
    .iframe-style {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
`;

export const StyledButton = styled(Button)`
    background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.light : 'transparent')};
    border-radius: 10px !important;

    span {
        color: ${({ isSelected, theme }) => (isSelected ? theme.colors.black : theme.colors.light)} !important;
    }
`;
