import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Text } from '@Components/Typography/styles';
import Button from '@Components/Button';
import { useTranslation } from 'react-i18next';
import InterviewStyles, { StyledButton } from './styles';

const Interview = ({ videos, title, description, sm }) => {
    const [selectedVideo, setSelectedVideo] = useState(0);
    const { t } = useTranslation('common');

    return (
        <InterviewStyles className="padding-horizontal">
            <Col span={24}>
                <Row style={sm ? { marginBottom: '10vh', marginTop: '10vh' } : { marginBottom: '30vh', marginTop: '30vh' }}>
                    <Text color="light" size="21px" weight="400">
                        {description}
                    </Text>
                </Row>
                <Row align="middle" justify="space-between" style={{ marginBottom: '3vh' }}>
                    <Col>
                        <Text color="light" size="36px" weight="600">
                            {title}
                        </Text>
                    </Col>
                    <Col>
                        <Row
                            gutter={[
                                { xs: 12, sm: 24, md: 24, lg: 32, xl: 32, xxl: 32 },
                                { xs: 12, sm: 24, md: 24, lg: 32, xl: 32, xxl: 32 },
                            ]}>
                            {videos.map(({ id, name, url }, i) => (
                                <Col key={id}>
                                    <StyledButton isSelected={i === selectedVideo} type="bordered-primary" onClick={() => setSelectedVideo(id)}>{`${t(
                                        'aos.farewell.interviewButtons.part'
                                    )} ${i + 1}`}</StyledButton>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Row className="video-container">
                    <iframe
                        className="iframe-style"
                        src={videos[selectedVideo].url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Row>
            </Col>
        </InterviewStyles>
    );
};

export default Interview;
