/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text } from '@Components/Typography/styles';
import useResponsive from '@Hooks/useMediaQuery';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import Carousel from '@Components/Carousel';
import { Helmet } from 'react-helmet/';
import { canonicals } from '@Helpers';
import StyledNextPage, { Img, Banner } from './styles';

const Afterward = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    const slider1Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-1.jpg?alt=media&token=0759b45d-8790-4d01-b94d-60a96be5602f',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-2.jpg?alt=media&token=32d2b9e4-7400-404a-a7a5-5ff179e6cb8b',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-3.jpg?alt=media&token=df2f1b4f-33bb-4811-b555-8e556f03e25d',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-4.jpg?alt=media&token=cc506410-0681-4daf-83d1-1237141ca5e8',
            },
            {
                id: 5,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-5.jpg?alt=media&token=3946b7fd-6684-4108-8cdb-9998ef53365a',
            },
            {
                id: 6,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-6.jpg?alt=media&token=ef2e9b29-01d0-4b75-9be7-1495d25f17d9',
            },
            {
                id: 7,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-7.jpg?alt=media&token=037f2461-3dd8-4f21-a6be-27f005f6ba3b',
            },
            {
                id: 8,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-8.jpg?alt=media&token=fc2dc3ec-f6be-4b0e-9f75-94ecdd87dd84',
            },
            {
                id: 9,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-9.jpg?alt=media&token=85f41482-8d25-4b74-9c27-0a62b6a89f97',
            },
            {
                id: 10,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-10.jpg?alt=media&token=dd3230a2-654d-47b4-bd02-0d54df5625b3',
            },
            {
                id: 11,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-11.jpg?alt=media&token=c8b5936d-b905-41f0-a273-93f509efb9ec',
            },
            {
                id: 12,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-12.jpg?alt=media&token=51b7625d-4ba3-4854-9eb8-cd210200c77e',
            },
            {
                id: 13,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-13.jpg?alt=media&token=68f70144-c7e2-45b5-af8f-aec4ff598915',
            },
            {
                id: 14,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-14.jpg?alt=media&token=dac82d35-7baa-4037-a6c1-4434326cf07d',
            },
            {
                id: 15,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-15.jpg?alt=media&token=77f77f6e-12b9-4762-b6fb-fdf167a9b522',
            },
            {
                id: 16,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-16.jpg?alt=media&token=fca02995-b826-4ed2-a9e7-1c3afb874fcf',
            },
            {
                id: 17,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-17.jpg?alt=media&token=eeb200f5-ca43-4315-ac67-2f749393de4f',
            },
            {
                id: 18,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-18.jpg?alt=media&token=1d39b6bb-bb63-4bf7-87da-04b889fd4c43',
            },
            {
                id: 19,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-19.jpg?alt=media&token=e6e00be8-e228-43ec-b5a8-f44b555b070f',
            },
            {
                id: 20,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-20.jpg?alt=media&token=68797034-19fc-4f42-980e-162368a29fcc',
            },
            {
                id: 21,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider1%2Fslider1-21.jpg?alt=media&token=ca51cdf4-942b-4ecf-952c-1680f3ae19cf',
            },
        ],
        imgAlt: t('aos.afterward.section7.imgAlt'),
        imgAltDesc: t('aos.afterward.section7.imgAltDesc'),
    };

    const slider2Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslider2-1.jpg?alt=media&token=944071f4-92e6-4ff0-8551-aa135702f826',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslider2-2.jpg?alt=media&token=76a72ab4-5c61-46ee-99c1-5bf16f160233',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslider2-3.jpg?alt=media&token=0b8feda8-e1b6-4df4-8424-02c7d6943751',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_4.jpg?alt=media&token=66e0ea6c-f103-465d-95c0-6093b7444477',
            },
            {
                id: 5,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_5.jpg?alt=media&token=95cda022-2499-41ce-b7f3-404d3b1e7768',
            },
            {
                id: 6,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_6.jpg?alt=media&token=88b5aaab-f63e-4a57-bd72-fe0fd86289a6',
            },
            {
                id: 7,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_7.jpg?alt=media&token=7c38b655-d56b-49bd-bcdf-bf0ef159c744',
            },
            {
                id: 8,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_8.jpg?alt=media&token=0ab30221-55e6-4ba9-a087-293523568eba',
            },
            {
                id: 9,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_9.jpg?alt=media&token=296251e6-bacd-499b-be3c-3200478caf63',
            },
            {
                id: 10,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider2%2Fslayt2_10.jpg?alt=media&token=7cd847b1-c88b-4ffe-9977-5a6a2c11238a',
            },
        ],
        imgAlt: t('aos.afterward.section8.imgAlt'),
        imgAltDesc: t('aos.afterward.section8.imgAltDesc'),
    };

    const slider3Data = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider3%2Fslider3-1.jpg?alt=media&token=f925780a-add9-44b4-a8a8-792ea19d8551',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider3%2Fslider3-2.jpg?alt=media&token=70a56a00-c46a-4aa1-b0cd-ee90b37af3b5',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider3%2Fslider3-3.jpg?alt=media&token=6f7b7868-184a-4e19-9a3d-3e4fd9a1a7b6',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider3%2Fslider3-4.jpg?alt=media&token=5f1251ce-32fa-4d28-8e61-6a85f2ea1b36',
            },
            {
                id: 5,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fslider3%2Fslider3-5.jpg?alt=media&token=1e04650e-35f8-4f1f-a783-03d5d3f03443',
            },
        ],
        imgAlt: t('aos.afterward.section11.imgAlt'),
        imgAltDesc: t('aos.afterward.section11.imgAltDesc'),
    };

    return (
        <StyledNextPage style={{ paddingBottom: '10vh' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.landing')}</title>
                <link rel="canonical" href={canonicals.afterward} />
            </Helmet>
            <Banner
                sm={sm}
                className="padding-horizontal padding-vertical"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs1.jpg?alt=media&token=2e3013fa-2907-47ca-8433-2080c596be69">
                <Col className="vertical-sb" span={24}>
                    <Row className="letterspacing-fix" align="middle" justify="center">
                        <Text className="gradient-title" size="90px" letterSpacing="big" color="light" weight="700">
                            {t('aos.afterward.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="36px" color="light" weight="600">
                            {t('aos.afterward.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-xs">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.afterward.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.afterward.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.afterward.section2.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs2.jpg?alt=media&token=7cf5124e-a605-4634-bf68-3c8ff19cedf2" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section2.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section2.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" gutter={[16, 16]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={6}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs3-1.jpg?alt=media&token=4afaea2f-13aa-4b22-8c96-86de842e9356"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section3.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section3.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pr-reset pl-reset' : 'eqh-wrapper'} sm={24} md={6}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs3-2.jpg?alt=media&token=7f9a2162-13b5-46bb-aeec-3ede31290205"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section3.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section3.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pr-reset pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs3-3.jpg?alt=media&token=04e3211d-2227-4181-aa6b-c0c56dbfafc9"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section3.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section3.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.afterward.section2.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs4-1.jpg?alt=media&token=ff2f5e7b-980a-41fb-b173-d94c25567ff1"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section4.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section4.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs4-2.jpg?alt=media&token=ae377741-d307-401c-8b52-eb9071bedd21"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section4.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section4.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs4-3.jpg?alt=media&token=e17164e7-ee59-435f-92b9-bc3ed9e693f6"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section4.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section4.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? ' pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs4-4.jpg?alt=media&token=b9fe958b-4c90-4849-8c7b-c1d687610439"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section4.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section4.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs5.jpg?alt=media&token=1ba91a9f-2e35-43b3-891e-47338d5f4ff5"
            />
            <Row className="pt-img-alt padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.afterward.section5.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.afterward.section5.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.afterward.section5.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs6-1.jpg?alt=media&token=0f3aceb9-b9fd-449e-b9e9-c93c493cb4ac"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section6.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section6.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs6-2.jpg?alt=media&token=4375ee70-eebf-4cdb-9343-44637644f77d"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section6.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section6.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.afterward.section6.text')}
                </Text>
            </Row>
            <Carousel className="mb-sm" type="slider1" data={slider1Data} />
            <Carousel className="mb-sm" type="slider2" data={slider2Data} />
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs9.jpg?alt=media&token=fedd83f7-300c-41ce-b9b0-de7c8a5c65fb"
            />
            <Row className="pt-img-alt padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.afterward.section9.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.afterward.section9.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.afterward.section9.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs10-1.jpg?alt=media&token=2126ceee-1d44-44df-bdc0-823e71053868"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section10.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section10.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FArd%C4%B1ndan%2Fs10-2.jpg?alt=media&token=1fb935ca-a4f2-41e3-a57c-ab49f21252ca"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.afterward.section10.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.afterward.section10.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Carousel type="slider3" data={slider3Data} />
        </StyledNextPage>
    );
};

export default Afterward;
