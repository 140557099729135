import styled from 'styled-components';
import { Row, Col } from 'antd';
import Button from '@Components/Button';

export default styled(Row)`
    video {
        width: 100%;
    }
`;

export const Menu = styled(Row)`
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);

    .ant-radio-group {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ant-radio-wrapper {
        margin-bottom: 10px;
        margin-left: 8px;
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .ant-checkbox-wrapper {
        margin-bottom: 10px;
        margin-left: 8px;
    }
`;

export const StyledButton = styled(Button)`
    border-radius: 0px !important;
    width: 100%;
`;
