/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { Row, Col } from 'antd';
import { Text } from '@Components/Typography/styles';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';
import StyledFarewellPage, { Banner, Img } from './styles';
import Interview from './Interview';

const FarewellPage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    const interviews = [
        {
            id: 'i-1',
            name: 'Aron Ender',
            title: t('aos.farewell.section22.title'),
            description: t('aos.farewell.section22.text'),
            videos: [
                {
                    id: 0,
                    name: 'Aron Ender Part 1',
                    url: 'https://www.youtube.com/embed/6vWq3HfYpEo',
                },
                {
                    id: 1,
                    name: 'Aron Ender Part 2',
                    url: 'https://www.youtube.com/embed/5TohB2t7RCs',
                },
            ],
        },
        {
            id: 'i-2',
            name: 'Erol Mukatel',
            title: t('aos.farewell.section23.title'),
            description: t('aos.farewell.section23.text'),
            videos: [
                {
                    id: 0,
                    name: 'Erol Mukatel Part 1',
                    url: 'https://www.youtube.com/embed/Ws1sAxA5q5U',
                },
                {
                    id: 1,
                    name: 'Erol Mukatel Part 2',
                    url: 'https://www.youtube.com/embed/CqmNkgH2iWA',
                },
            ],
        },
        {
            id: 'i-3',
            name: 'Avi Avdan Kohen',
            title: t('aos.farewell.section24.title'),
            description: t('aos.farewell.section24.text'),
            videos: [
                {
                    id: 0,
                    name: 'Avi Avdan Kohen Part 1',
                    url: 'https://www.youtube.com/embed/1VyOjdFylL0',
                },
                {
                    id: 1,
                    name: 'Avi Avdan Kohen Part 2',
                    url: 'https://www.youtube.com/embed/8UU7z01Wyf0',
                },
            ],
        },
        {
            id: 'i-4',
            name: 'Dina Parlayan',
            title: t('aos.farewell.section25.title'),
            description: t('aos.farewell.section25.text'),
            videos: [
                {
                    id: 0,
                    name: 'Dina Parlayan Part 1',
                    url: 'https://www.youtube.com/embed/Pv6gfPyJGiw',
                },
            ],
        },
    ];

    return (
        <StyledFarewellPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.farewell')}</title>
                <link rel="canonical" href={canonicals.farewell} />
            </Helmet>
            <Banner
                style={sm && { height: '50vh' }}
                className="padding-vertical"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs1.jpg?alt=media&token=80ac2a7e-edd7-4e66-97ff-500e9b0ebbec">
                <Col className="vertical-sb" span={24}>
                    <Row className="letterspacing-fix" align="middle" justify="center">
                        <Text family="secondary" letterSpacing="motto" size="big-motto" color="light" weight="700">
                            {t('aos.farewell.section1.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="36px" className="border-light" color="light" weight="600">
                            {t('aos.farewell.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className={sm ? 'pt-img-alt padding-horizontal mb-small' : 'pt-img-alt padding-horizontal'}>
                <Col>
                    <Text color="grey" displayblock>
                        {t('aos.farewell.section1.imgAlt')}
                    </Text>
                    <Text color="grey" weight="300">
                        {t('aos.farewell.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className={sm ? 'padding-horizontal mb-xsmall' : 'padding-horizontal mb-xsmall transform-top-sm'} gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col sm={24} md={13} className="vertical-center">
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section2.text')}
                    </Text>
                </Col>
                <Col sm={24} md={11}>
                    <div className={!sm && 'transform-top-sm'}>
                        <Img
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs2.jpg?alt=media&token=ede79431-60f5-48f6-908d-d2cb30c46a26"
                            alt="Ankara Yahudi Mahallesi, Ali Cengizkan Arşivi, Temmuz 1986"
                        />
                        <Row className="mt-xs" align="middle">
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.farewell.section2.imgAlt')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.farewell.section2.imgAltDesc')}
                                </Text>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium" gutter={[{ xs: 16, sm: 16, md: 32, lg: 32, xl: 32, xxl: 32 }, 32]}>
                <Col sm={24} md={12} className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'}>
                    <Row className="eqh-img">
                        <Img
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs3-1.jpg?alt=media&token=6f9bb6a4-eaa2-4577-a5c3-e44bb3103fb2"
                            alt="Ankara Yahudi Mahallesi, Ali Cengizkan Arşivi, Temmuz 1986"
                        />
                    </Row>
                    <Row className="pt-img-alt eqh-text" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section3.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section3.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={12} className={sm && 'pl-reset'}>
                    <Row>
                        <Img
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs3-2.jpg?alt=media&token=290e0147-75b2-4b7e-9fc4-18d264b82119"
                            alt="Ankara Yahudi Mahallesi, Ali Cengizkan Arşivi, Temmuz 1986"
                        />
                    </Row>
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section3.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section3.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                    <Row className="mt-sm " gutter={[{ xs: 16, sm: 16, md: 32, lg: 32, xl: 32, xxl: 32 }, 32]}>
                        <Col className="eqh-wrapper" span={12}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs3-3.jpg?alt=media&token=346f24ef-53a3-4fa1-8da7-761c38924092" />
                            </Row>
                            <Row className="pt-img-alt eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section3.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section3.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" span={12}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs3-4.jpg?alt=media&token=8460665d-a07b-4638-b220-bf008b4f31b0" />
                            </Row>
                            <Row className="pt-img-alt eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section3.imgAlt4')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section3.imgAlt4Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col sm={24} md={14}>
                    <Row gutter={[10, 10]}>
                        <Col className="eqh-wrapper" span={16}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs4-1.jpg?alt=media&token=c3f9d869-61cc-4aa1-8a1b-bac38d14e14e" />
                            </Row>
                            <Row className="pt-img-alt eqh-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section4.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section4.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" span={8}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs4-2.jpg?alt=media&token=c12ebd46-3d7b-4f11-a246-340be92a9857" />
                            </Row>
                            <Row className="pt-img-alt eqh-text">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section4.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section4.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={10} className="vertical-center">
                    <Text color="light" size="21px" w eight="400">
                        {t('aos.farewell.section4.text')}
                    </Text>
                </Col>
            </Row>
            <Row className="mb-small">
                <Col span={24}>
                    <Row>
                        <Img
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs5.jpg?alt=media&token=9174df9e-8a57-4c94-9eb9-19f11c3672b4"
                            alt="Ankara Yahudi Mahallesi, Fotoğraf: N. Müge Cengizkan, Kasım 2021"
                        />
                    </Row>
                    <Row className="pt-img-alt padding-horizontal" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section5.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section5.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col order={sm ? 2 : 1} className="vertical-center" sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section6.text')}
                        </Text>
                    </Row>
                </Col>
                <Col style={sm && { width: '100%' }} order={sm ? 1 : 2} sm={24} md={12}>
                    <Row className="video-container">
                        <iframe
                            className="iframe-style"
                            src="https://www.youtube.com/embed/jw1jvzIQhS0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Row>
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="light">
                                {t('aos.farewell.section6.imgAlt')}
                            </Text>
                            <Text displayblock color="light" weight="300">
                                {t('aos.farewell.section6.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col sm={24} md={14}>
                    <Row gutter={[16, 16]}>
                        <Col className="eqh-wrapper" span={16}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs4-1.jpg?alt=media&token=c3f9d869-61cc-4aa1-8a1b-bac38d14e14e"
                            />
                            <Row className="pt-img-alt eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section7.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section7.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" span={8}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs4-2.jpg?alt=media&token=c12ebd46-3d7b-4f11-a246-340be92a9857"
                            />
                            <Row className="pt-img-alt eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section7.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section7.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-center" sm={24} md={10}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section7.text')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col order={sm ? 2 : 1} className="vertical-center" sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section8.text')}
                    </Text>
                </Col>
                <Col order={sm ? 1 : 2} sm={24} md={12}>
                    <Row>
                        <Img
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs8.jpg?alt=media&token=fd34d264-2967-4e11-a7eb-f673147de35d"
                            alt="1905-1906 Ankara Sinagogu Açılışı, Ben Zvi Enstitüsü"
                        />
                    </Row>
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section8.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section8.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium">
                <Col span={24}>
                    <Row gutter={[{ sm: 0, md: 32 }, 32]}>
                        <Col className="eqh-wrapper" sm={24} md={12}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs9-1.jpg?alt=media&token=052731c3-de58-4a80-8353-9357dfbc1f3c" />
                            </Row>
                            <Row className="eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section9.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section9.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" sm={24} md={8}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs9-2.jpg?alt=media&token=b6d5216c-6d0c-47a2-9bff-80974866fa3c" />
                            </Row>
                            <Row className="eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section9.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section9.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="eqh-wrapper" sm={24} md={4}>
                            <Row className="eqh-img">
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs9-3.jpg?alt=media&token=0755d7b6-6797-4414-bb7c-7c37e5c97d1a" />
                            </Row>
                            <Row className="eqh-text" align="middle">
                                <Col>
                                    <Text displayblock color="light">
                                        {t('aos.farewell.section9.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="light" weight="300">
                                        {t('aos.farewell.section9.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[{ sm: 0, md: 32 }, 32]} className="padding-horizontal mb-small">
                <Col sm={24} md={12}>
                    <Img
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs10-1.jpg?alt=media&token=271faf70-964d-45b4-9679-073317d23acd"
                        alt="Ankara Yahudi Mahallesi, NETlab Arşivi, Haziran 2021, Ankara Yahudi Mahallesi, Fotoğraf: N. Müge Cengizkan, Kasım 2021"
                    />
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section10.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section10.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-center" sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section10.text')}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[{ sm: 0, md: 32 }, 32]} className="padding-horizontal mb-small">
                <Col sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs10-2.jpg?alt=media&token=783740f8-acd7-458e-b917-bf3779d26768" />
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section10.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section10.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs10-3.jpg?alt=media&token=e660c1ca-c740-4240-9bda-797c378010fa" />
                    <Row className="pt-img-alt" align="middle">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section10.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section10.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                style={sm && { height: '50vh' }}
                className="banner-pt"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs11-1.jpg?alt=media&token=fd0cd2a2-d280-4548-92cb-1193ead7505d">
                <Col span={24}>
                    <Row>
                        <Text className="banner-title" size="48px" color="light" weight="700">
                            {t('aos.farewell.section11.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="pt-img-alt padding-horizontal mb-small" align="middle">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.farewell.section11.imgAlt1')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.farewell.section11.imgAlt1Desc')}
                    </Text>
                </Col>
            </Row>
            <Row
                className={sm ? 'padding-horizontal mb-small' : 'padding-horizontal transform-top-md'}
                gutter={[
                    { xs: 0, sm: 0, md: 32, lg: 32, xl: 32, xxl: 32 },
                    { xs: 32, sm: 32, md: 32, lg: 0, xl: 0, xxl: 0 },
                ]}>
                <Col className={!sm && 'vertical-sb pt-lg'} sm={24} md={14}>
                    <Row className={!sm && 'mb-medium'}>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section11.text')}
                        </Text>
                    </Row>
                    <Row className={sm && 'pt-img-alt'} align="middle" justify="start">
                        <Col span={24}>
                            <Row>
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs11-3.jpg?alt=media&token=6eed8b78-6b86-477a-91ef-f390f9bda2bc" />
                            </Row>
                            <Row className="pt-img-alt" align="middle">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section11.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section11.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={10}>
                    <Row align="middle" justify="start">
                        <Col>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs11-2.jpg?alt=media&token=d5c1e0ce-6f9e-4f82-ab4c-c8dcca75ff61" />
                        </Col>
                        <Row className="pt-img-alt" align="middle">
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.farewell.section11.imgAlt2')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.farewell.section11.imgAlt2Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
            <Row className={sm ? 'padding-horizontal mb-small' : 'padding-horizontal mb-small transform-top-lg'}>
                <Text color="light" size="21px" weight="400">
                    {t('aos.farewell.section12.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row className="mb-small">
                        <Col sm={24} md={20}>
                            <Row>
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs12-1.jpg?alt=media&token=59c34e94-013c-4977-9377-f472fb9f7d74" />
                            </Row>
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section12.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section12.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-small">
                        <Col offset={!sm && 6} sm={24} md={18}>
                            <Row>
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs12-4.jpg?alt=media&token=64fd7fdf-53f2-4939-8471-a1f67a6911cb" />
                            </Row>
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section12.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section12.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-small">
                        <Col span={24}>
                            <Row>
                                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs12-3.jpg?alt=media&token=4b004eeb-937d-4e2d-ae46-34a1d6aaae42" />
                            </Row>
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section12.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section12.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-small">
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section12.text2')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} offset={!sm && 2} sm={24} md={10}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs12-2.jpg?alt=media&token=2cf2dd51-8be9-4c9e-a044-a9e4455dc9fa" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section12.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section12.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs13.jpg?alt=media&token=1aa9ceed-6f60-470e-97f6-b5dfea57ecd3" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section13.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section13.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section13.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-medium" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col sm={24} md={12}>
                    <Row className="mb-small">
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section14.text')}
                        </Text>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-2.jpg?alt=media&token=7d1cd726-dbab-4b41-8207-7bb44a3810f6" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section14.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section14.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={16}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-3.jpg?alt=media&token=25c01783-3d07-4831-8676-24bdd15ac1ed" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section14.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section14.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={12}>
                    <Row className="mb-xsmall">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-1.jpg?alt=media&token=5d48f61c-155b-4b3d-987f-e1fe492e72a9" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section14.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section14.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-4.jpg?alt=media&token=4131a6b9-55b7-4983-a03d-bda2688de7a7" />
                                    <Row className="pt-img-alt">
                                        <Col>
                                            <Text displayblock color="grey">
                                                {t('aos.farewell.section14.imgAlt4')}
                                            </Text>
                                            <Text displayblock color="grey" weight="300">
                                                {t('aos.farewell.section14.imgAlt4Desc')}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-5.jpg?alt=media&token=ef43e5d9-9b02-4d94-a9af-990985b0c7c2" />
                                    <Row className="pt-img-alt">
                                        <Col>
                                            <Text displayblock color="grey">
                                                {t('aos.farewell.section14.imgAlt5')}
                                            </Text>
                                            <Text displayblock color="grey" weight="300">
                                                {t('aos.farewell.section14.imgAlt5Desc')}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-6.jpg?alt=media&token=ed319902-c354-4bd2-acf1-efdf930e212f" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section14.imgAlt6')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section14.imgAlt6Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs14-7.jpg?alt=media&token=ec2b4cb0-190f-4f26-8e3f-5c747ba8537b" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section14.imgAlt7')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section14.imgAlt7Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs15.jpg?alt=media&token=701d8746-670d-491d-ad62-18142f975a8c" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section15.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section15.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section15.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs16-1.jpg?alt=media&token=cbd03df9-ecbe-4bc6-8ed0-aeddf04e0443" />
                    <Row className="sara-title banner-title">
                        <Text size="48px" color="light" weight="700">
                            {t('aos.farewell.section16.title')}
                        </Text>
                    </Row>
                    <Row className="pt-img-alt mb-small">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section16.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section16.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section16.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs16-2.jpg?alt=media&token=eba3264a-37c4-41d2-9fae-b41de1babcae" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section16.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section16.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col sm={24} md={12}>
                    <Row>
                        <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs17.jpg?alt=media&token=9cf1e98a-2aa0-4731-bb0a-5acfa447a68a" />
                    </Row>
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section17.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section17.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-center" sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section17.text')}
                    </Text>
                </Col>
            </Row>
            <Row className={sm ? 'padding-horizontal' : 'transform-top-lg padding-horizontal '} gutter={[{ sm: 0, md: 32 }, 32]}>
                <Col className="vertical-end pb-xs" sm={24} md={12}>
                    <Text color="light" size="21px" weight="400">
                        {t('aos.farewell.section18.text')}
                    </Text>
                </Col>
                <Col sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs18.jpg?alt=media&token=fe9d3536-1af3-4208-8f25-98feb1071256" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section18.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section18.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Banner
                style={sm && { height: '50vh', marginTop: '5vh' }}
                className={sm ? 'mb-small vertical-center-wo-p pt-md' : 'vertical-center-wo-p pt-md'}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs19-1.jpg?alt=media&token=3df02833-54d9-487c-aa74-03409ef8672f">
                <Row>
                    <Text className="banner-title" size="48px" color="light" weight="700">
                        {t('aos.farewell.section19.title')}
                    </Text>
                </Row>
                <Row className="pt-img-alt padding-horizontal">
                    <Col>
                        <Text displayblock color="grey">
                            {t('aos.farewell.section19.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.farewell.section19.imgAltDesc')}
                        </Text>
                    </Col>
                </Row>
            </Banner>
            <Row className={sm ? 'padding-horizontal mb-xsmall' : 'padding-horizontal transform-top-md'} gutter={[16, 32]}>
                <Col className="vertical-center" sm={24} md={6}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs19-2.jpg?alt=media&token=cb0294d7-a0bf-4939-a19b-2606c70f67ae" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section20.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section20.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-center" sm={24} md={6}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs19-3.jpg?alt=media&token=f288bf22-13a9-4cc3-8696-6c5006316b3a" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section20.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section20.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-start" sm={24} md={6}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs19-4.jpg?alt=media&token=431d02ae-4325-4676-850d-6b17f7351d4d" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section20.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section20.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="vertical-center" sm={24} md={6}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs19-5.jpg?alt=media&token=1eadb048-b6fa-4bd1-bca4-8fc588d343fe" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.farewell.section20.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.farewell.section20.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={sm ? 'padding-horizontal mb-small' : 'padding-horizontal mb-sm-static'} gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row className="mb-sm-static">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-1.jpg?alt=media&token=7c7ffd33-48b9-4cc6-b112-d6ec5f74b7a2" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-sm-static">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-2.jpg?alt=media&token=df8e7266-d10f-4dda-b2ad-e091da271972" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-sm-static">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-4.jpg?alt=media&token=5285a532-e72c-4d72-9c89-1462103902c8" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-5.jpg?alt=media&token=86ad9288-fc96-4b5a-8333-a9743dd1f0c2" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt4')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt4Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-sb'} style={!sm && { marginTop: '-32px' }} sm={24} md={12}>
                    <Row style={sm ? { height: '50vh' } : { height: '100vh' }} className="vertical-center">
                        <Text color="light" size="21px" weight="400">
                            {t('aos.farewell.section18.text')}
                        </Text>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-8.jpg?alt=media&token=0be67742-2f94-4a49-9428-fb8f7fb1a0a9" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt7')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt7Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-small" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row className="mb-sm-static">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-6.jpg?alt=media&token=79f168e5-7d1e-4202-a322-abc741f77e76" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt5')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt5Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-7.jpg?alt=media&token=21e9fb75-7f4d-4c0a-bdca-5669c814487f" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt6')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt6Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row className="mb-sm-static">
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-9.jpg?alt=media&token=769a9d6c-2ead-4c98-ae81-2a649031586c" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt8')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt8Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs20-10.jpg?alt=media&token=423b18c9-a218-4bcc-bc03-7c4749b0f0b0" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.farewell.section21.imgAlt9')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.farewell.section21.imgAlt9Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {interviews.map(({ id, videos, title, description }) => (
                <Interview sm={sm} key={id} title={title} description={description} videos={videos} />
            ))}

            <Banner
                style={sm && { height: '50vh' }}
                className="mt-20vh"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FAyr%C4%B1l%C4%B1k%2Fs21.jpg?alt=media&token=8b3f7ee9-c8f2-4ce4-9ae2-4bfadbbe7b29">
                <Col className="banner-pt">
                    <Row className="banner-title" style={{ maxWidth: '80%' }}>
                        <Text size="48px" color="light" weight="400">
                            {t('aos.farewell.section26.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt pb-xs">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.farewell.section26.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.farewell.section26.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
        </StyledFarewellPage>
    );
};

export default FarewellPage;
