/* eslint-disable sonarjs/no-nested-template-literals */
import styled from 'styled-components';
import { Row, Col, Carousel } from 'antd';
import { between } from 'polished';

export default styled.div`
    width: 100%;
    height: 100vh;

    .right-arrow {
        position: absolute;
        z-index: 30;
        right: 10px !important;
    }
    .left-arrow {
        position: absolute;
        z-index: 30;
        left: 10px !important;
    }
`;

export const StyledCarousel = styled(Carousel)`
    width: 100%;
    height: 100vh !important;

    .slick-slider {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
        div {
            height: 100%;
        }
    }

    .slick-prev {
        left: -40px;
        z-index: 60;
    }

    .slick-next {
        position: fixed;
        /*  */
        top: 50%;
        right: 30px;
        z-index: 60px;
        span {
            background-color: red;
        }
    }

    /* .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } */
    div {
        width: 100%;
    }

    .justify-end {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .scroll-limit {
        -webkit-overflow-scrolling: touch;

        height: 150px !important;
        overflow-y: scroll !important;
    }
    .scroll-limit-alt {
        -webkit-overflow-scrolling: touch;
        height: 40px !important;
        overflow-y: scroll !important;
    }

    .text-container-mobile {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 30px;
        padding-left: 20px !important;
        padding-right: 20px !important;
        display: flex;
        flex-direction: column;
        justify-content: end;

        background-color: rgba(0, 0, 0, 0.6);
    }

    .photo-wrapper-mobile {
        width: 100%;
        img {
            width: 100% !important;
            height: auto !important;
        }
    }
`;

export const Overlay = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const BackgroundImageWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-image: url(${({ backgroundIMG }) => `"${backgroundIMG}"`}) !important;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    -webkit-filter: blur(3px);
`;

export const CarouselElementWrapper = styled.div`
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    justify-content: center;
`;

export const PhotoWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    img {
        object-fit: cover;
        height: 100%;
    }
`;
export const TextContainer = styled(Col)`
    padding-bottom: 10px;
    height: 100% !important;
    max-width: 400px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .description-styles {
        height: 250px !important;
        overflow-y: scroll;
    }

    .alt-styles {
        height: 100px !important;
        margin-top: 32px;
        padding-top: 14px;
        border-top: 1px solid #ffffff;
    }

    .alt-styles-mobile {
        height: 100px !important;
        margin-top: 16px;
        padding-top: 14px;
        border-top: 1px solid #ffffff;
    }
`;

export const InsideWrapper = styled(Row)`
    display: flex;
    background-color: #25252525;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: ${between('0px', '60px')};
    padding-right: ${between('0px', '60px')};
    padding-top: ${({ theme }) => theme.layoutStyles.headerHeight};
    padding-bottom: ${({ theme }) => theme.layoutStyles.headerHeight};
`;
