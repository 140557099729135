import styled from 'styled-components';
import { Carousel, Col, Row } from 'antd';

export const StyledCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;

    .slick-slider {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
        div {
            height: 100%;
        }
    }

    .ant-image-mask-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ant-image-img {
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .slick-prev {
        left: -40px;
        z-index: 60;
    }

    .slick-next {
        bottom: 3px;
        z-index: 60;
    }

    .arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .news-top-wrapper {
        /* height: 300px; */
        height: 88% !important;
        display: block;
        overflow: scroll;
    }

    .news-bottom-wrapper {
        /* height: 200px; */
        /* overflow: scroll; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 12% !important;

        border-top: 2px solid #efefefef;
        overflow: scroll;

        p {
            margin: 0px;
        }

        .p3 {
            display: none;
        }
    }
    /* .ant-carousel .slick-prev,
    .ant-carousel .slick-prev:hover {
        left: 10px;
        z-index: 2;
        color: white;
        font-size: 20px;
        height: 30px;
    }

    .ant-carousel .slick-next,
    .ant-carousel .slick-next:hover {
        right: 10px;
        z-index: 2;
        color: white;
        font-size: 20px;
        height: 30px;
    } */

    /* .ant-carousel .slick-prev,
    .ant-carousel .slick-next,
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover {
        font-size: inherit;
        color: currentColor;
        background: red;
    } */

    .slick-dots {
        /* padding-top: 10px;
        padding-bottom: 10px;
        z-index: 10000;
        width: 200px;
        margin-left: 0px;
        background-color: black;
        margin-bottom: -11px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top: 1px solid #ffffff;
        border-right: 1px solid #ffffff; */
        color: black;
        button {
            background-color: black !important;
        }
    }

    /* li {
        /* margin-left: 5px !important;
        margin-right: 5px !important;
        height: 5px !important;

        border-radius: 5px; */
    /* button {
            background-color: black;
        }
    }  */
`;

export const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-image-img {
        height: 100% !important;
    }
    .ant-image {
        height: 85% !important;
    }
    .ant-image-mask {
        height: 85%;
    }
`;

export const TextWrapper = styled(Col)`
    height: '100%';
    background-color: #ffffff;
    overflow-y: scroll;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    border-radius: 8px;
`;

export const MediaWrapper = styled(Row)`
    position: fixed;
    bottom: 30px;
    left: 0px;
    height: 100px !important;
    width: 100%;
    overflow-y: scroll;
    padding-top: 10px;
`;
