import styled from 'styled-components';
import { Row, Col } from 'antd';
// import Button from '@Components/Button';
import {
    FileImageOutlined,
    FileImageFilled,
    QuestionCircleOutlined,
    QuestionCircleFilled,
    PlaySquareFilled,
    HomeOutlined,
    ReadOutlined,
    ReadFilled,
    BoxPlotFilled,
    BoxPlotOutlined,
    SoundOutlined,
    SoundFilled,
    PlaySquareOutlined,
} from '@ant-design/icons';

export const Wrapper = styled(Col)`
    position: absolute;
    bottom: 0px;
    left: 50px;
    height: 40vh;
    width: 100%;
`;

export default styled(Row)`
    height: 100%;

    .overflow-scroll {
        overflow: scroll;
        max-height: 20vh;
    }

    .flex-row {
        /* position: absolute; */
        /* right: 0px;
        top: 15px; */
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;

        div {
            margin-left: 10px !important;
            margin-right: 20px !important;
        }
    }
`;

export const RightSide = styled(Col)`
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    padding-top: 10px;
    height: 100%;
    border-top-right-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    border-left: 1px solid rgba(255, 255, 255, 0.3);

    animation: ${({ isCollapsed }) => (isCollapsed ? 'fade-out 0.8s ease-out forwards' : 'fade-in 0.8s ease-in forwards')};
`;

export const StyledFileImageOutlined = styled(FileImageOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledFileImageFilled = styled(FileImageFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledQuestionCircleFilled = styled(QuestionCircleFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledSoundOutlined = styled(SoundOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledSoundFilled = styled(SoundFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledPlaySquareOutlined = styled(PlaySquareOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;
export const StyledPlaySquareFilled = styled(PlaySquareFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledBoxPlotOutlined = styled(BoxPlotOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledBoxPlotFilled = styled(BoxPlotFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledReadFilled = styled(ReadFilled)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

export const StyledReadOutlined = styled(ReadOutlined)`
    font-size: 24px;
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;
