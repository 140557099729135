/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import LoadingSpinner from '@Components/LoadingSpinner';
import useDynamicRefs from 'use-dynamic-refs';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { StyledCarousel } from './styles';
import VideoPlayer from './VideoPlayer';

const VideoSlider = ({ documents }) => {
    const [currentPlayingVideo, setCurrentPlayingVideo] = useState();

    const handleBeforeChange = () => {
        setCurrentPlayingVideo(false);
    };
    return (
        <StyledCarousel
            prevArrow={
                <div className="arrows">
                    <LeftCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }
            nextArrow={
                <div className="arrows">
                    <RightCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }
            arrows
            dotPosition="top"
            beforeChange={handleBeforeChange}>
            {!documents ? (
                <LoadingSpinner />
            ) : (
                documents.map(({ name, alt, videoSRC }, i) => (
                    <VideoPlayer
                        name={name}
                        setCurrentPlayingVideo={setCurrentPlayingVideo}
                        isPlaying={currentPlayingVideo === name}
                        key={i}
                        src={videoSRC}
                    />
                ))
            )}
        </StyledCarousel>
    );
};

export default VideoSlider;
