import { doc } from 'prettier';
import { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

const useFetchFirestoreData = (ref) => {
    const [documents, setDocuments] = useState();
    const [fetchError, setFetchError] = useState();
    const [value, loading, error] = useCollection(ref, {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    useEffect(() => {
        if (!error && value) {
            const arr = [];
            value.docs.map((item) => arr.push({ ...item.data(), docId: item.id.replace(/ /g, '') }));
            setDocuments(arr.sort(({ orderId: previousID }, { orderId: currentID }) => previousID - currentID));
        } else {
            setFetchError(error);
            console.log(error);
        }
    }, [error, value]);

    return [documents, fetchError, setDocuments];
};

export default useFetchFirestoreData;
