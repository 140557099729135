/* eslint-disable sonarjs/no-duplicate-string */
export const generalMapOptions = {
    containerStyle: {
        width: '100%',
        height: '100%',
    },

    mapOptions: {
        mapId: '852bcfbd0eec2e4e',
        panControl: false,
        fullscreenControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: true,
        overviewMapControl: false,
        rotateControl: false,
    },
    center: {
        lat: 39.933468,
        lng: 32.853716,
    },
};
export const polygonLocations = [
    {
        id: 1,
        name: 'location-1',
        paths: [
            { lat: 39.933809, lng: 32.856708 },
            { lat: 39.935516, lng: 32.858417 },
            { lat: 39.938149, lng: 32.859185 },
            { lat: 39.935549, lng: 32.861749 },
            { lat: 39.934542, lng: 32.861963 },
            { lat: 39.933326, lng: 32.860011 },
            { lat: 39.933809, lng: 32.856708 },
        ],

        options: {
            fillColor: 'green',
            fillOpacity: 0.3,
            strokeColor: 'green',
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'green',
            fillOpacity: 0.6,
            strokeColor: 'green',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },
    {
        id: 2,
        name: 'location-2',
        paths: [
            { lat: 39.925218, lng: 32.852491 },
            { lat: 39.926477, lng: 32.854873 },
            { lat: 39.928484, lng: 32.854497 },
            { lat: 39.927752, lng: 32.851074 },
            { lat: 39.925218, lng: 32.852491 },
        ],
        options: {
            fillColor: 'lightblue',
            fillOpacity: 0.3,
            strokeColor: 'lightblue',
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'lightblue',
            fillOpacity: 0.6,
            strokeColor: 'lightblue',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },
    {
        id: 3,
        name: 'location-3',
        paths: [
            { lat: 39.925324, lng: 32.856932 },
            { lat: 39.924056, lng: 32.857759 },
            { lat: 39.925468, lng: 32.860142 },
            { lat: 39.926811, lng: 32.858755 },
            { lat: 39.92604, lng: 32.858017 },
            { lat: 39.925324, lng: 32.856932 },
        ],
        options: {
            fillColor: 'purple',
            fillOpacity: 0.3,
            strokeColor: 'purple',
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'purple',
            fillOpacity: 0.6,
            strokeColor: 'purple',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },

    // { lat: 39.925681, lng: 32.854435 },
    // { lat: 39.925568, lng: 32.855487 },
    // { lat: 39.923921, lng: 32.855708 },
    // { lat: 39.923198, lng: 32.854738 },
    // { lat: 39.923294, lng: 32.853958 },
    // { lat: 39.924512, lng: 32.853673 },
    // { lat: 39.925681, lng: 32.854435 },

    {
        id: 4,
        name: 'location-4',
        paths: [
            { lat: 39.920983, lng: 32.853901 },
            { lat: 39.921921, lng: 32.852442 },
            { lat: 39.923419, lng: 32.852528 },
            { lat: 39.925147, lng: 32.853408 },
            { lat: 39.926216, lng: 32.854888 },
            { lat: 39.926135, lng: 32.855468 },
            { lat: 39.925266, lng: 32.856032 },
            { lat: 39.923184, lng: 32.856301 },
            { lat: 39.921795, lng: 32.855868 },
            { lat: 39.920983, lng: 32.853901 },
        ],
        options: {
            fillColor: 'yellow',
            fillOpacity: 0.3,
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'yellow',
            fillOpacity: 0.6,
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },
];

export const circleLocations = [
    {
        id: 5,
        name: 'location-5',
        position: {
            lat: 39.941662,
            lng: 32.854042,
        },
        options: {
            strokeColor: 'blue',
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: 'blue',
            fillOpacity: 0.3,
            clickable: true,
            draggable: false,
            editable: false,
            visible: true,
            radius: 100,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'blue',
            fillOpacity: 0.6,
            strokeColor: 'blue',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },
    {
        id: 6,
        name: 'location-6',
        position: {
            lat: 39.933919,
            lng: 32.852475,
        },
        options: {
            strokeColor: 'red',
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: 'red',
            fillOpacity: 0.3,
            clickable: true,
            draggable: false,
            editable: false,
            visible: true,
            radius: 100,
            zIndex: 1,
        },
        activeOptions: {
            fillColor: 'red',
            fillOpacity: 0.6,
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
        },
    },
];

const avramFamilyStrokeColor = '#FF0000';

const strokeOptions = {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
};

export const peopleOfArea3 = [
    {
        id: 1,
        name: 'Avram Ağlamaz',
        img: 'https://via.placeholder.com/64x64.png',
        family: 'Ağlamaz',
        position: {
            lat: 39.926873,
            lng: 32.858488,
        },
        polylines: [
            {
                polylineId: 'poly-1',
                paths: [
                    { lat: 39.926873, lng: 32.858488 },
                    { lat: 39.926744447315975, lng: 32.85998999986602 },
                ],
                options: {
                    ...strokeOptions,
                    strokeColor: avramFamilyStrokeColor,
                    fillColor: avramFamilyStrokeColor,
                    paths: [
                        { lat: 39.926873, lng: 32.858488 },
                        { lat: 39.926744447315975, lng: 32.85998999986602 },
                    ],
                },
            },
            {
                polylineId: 'poly-2',
                paths: [
                    { lat: 39.926873, lng: 32.858488 },
                    { lat: 39.9266899973, lng: 32.8593299569 },
                ],
                options: {
                    ...strokeOptions,
                    strokeColor: avramFamilyStrokeColor,
                    fillColor: avramFamilyStrokeColor,
                    paths: [
                        { lat: 39.926873, lng: 32.858488 },
                        { lat: 39.9266899973, lng: 32.8593299569 },
                    ],
                },
            },
            {
                polylineId: 'poly-3',
                paths: [
                    { lat: 39.926873, lng: 32.858488 },
                    { lat: 39.926744447315975, lng: 32.85998999986602 },
                ],
                options: {
                    ...strokeOptions,
                    strokeColor: avramFamilyStrokeColor,
                    fillColor: avramFamilyStrokeColor,
                    paths: [
                        { lat: 39.926873, lng: 32.858488 },
                        { lat: 39.926744447315975, lng: 32.85998999986602 },
                    ],
                },
            },
            {
                polylineId: 'poly-4',
                paths: [
                    { lat: 39.926873, lng: 32.858488 },
                    { lat: 39.925873, lng: 32.857488 },
                ],
                options: {
                    ...strokeOptions,
                    strokeColor: avramFamilyStrokeColor,
                    fillColor: avramFamilyStrokeColor,
                    paths: [
                        { lat: 39.926873, lng: 32.858488 },
                        { lat: 39.926744447315975, lng: 32.85998999986602 },
                    ],
                },
            },
            {
                polylineId: 'poly-5',
                paths: [
                    { lat: 39.926873, lng: 32.858488 },
                    { lat: 39.925873, lng: 32.856488 },
                ],
                options: {
                    ...strokeOptions,
                    strokeColor: avramFamilyStrokeColor,
                    fillColor: avramFamilyStrokeColor,
                    paths: [
                        { lat: 39.926873, lng: 32.858488 },
                        { lat: 39.925873, lng: 32.856488 },
                    ],
                },
            },
        ],
    },
    {
        id: 2,
        name: 'Roza (Benforma) Ağlamaz',
        img: 'https://via.placeholder.com/64x64.png',
        family: 'Ağlamaz',
        position: {
            lat: 39.926744447315975,
            lng: 32.85998999986602,
        },
    },
    {
        id: 3,
        name: 'Avram Ağlamaz',
        img: 'https://via.placeholder.com/64x64.png',
        family: 'Ağlamaz',
        position: {
            lat: 39.9266899973,
            lng: 32.8593299569,
        },
    },
    {
        id: 4,
        name: 'Leyla (Almelek) Ağlamaz',
        img: 'https://via.placeholder.com/64x64.png',
        family: 'Ağlamaz',
        position: {
            lat: 39.925873,
            lng: 32.857488,
        },
    },
    {
        id: 5,
        name: 'Yosef Ağlamaz',
        img: 'https://via.placeholder.com/64x64.png',
        family: 'Ağlamaz',
        position: {
            lat: 39.925873,
            lng: 32.856488,
        },
    },
];
