/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */

import React from 'react';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import useResponsive from '@Hooks/useMediaQuery';
import { Text } from '@Components/Typography/styles';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import Carousel from '@Components/Carousel';
import StyledMusicPage, { Banner, Img } from './styles';

const WaterCulturePage = () => {
    const { t } = useTranslation('common');
    const { sm } = useResponsive();

    const sliderData = {
        images: [
            {
                id: 1,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider1-1.jpg?alt=media&token=db07e78e-cf02-44d9-9511-545925c01126',
            },
            {
                id: 2,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider1-2.jpg?alt=media&token=83df01ec-cbf8-4f2a-942a-29f773fe905f',
            },
            {
                id: 3,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider1-3.jpg?alt=media&token=d04ba68f-0419-4cd4-959c-fa01843a2167',
            },
            {
                id: 4,
                imgURL: 'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider1-4.jpg?alt=media&token=92fdd47f-7726-4808-9f7e-d2ff63a210a7',
            },
        ],
        imgAlt: t('aos.music.section9.sliderAlt'),
        imgAltDesc: t('aos.music.section9.sliderAltDesc'),
    };

    return (
        <StyledMusicPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.music')}</title>
                <link rel="canonical" href={canonicals.music} />
            </Helmet>
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs0.jpg?alt=media&token=7f0d8d53-fd04-4145-a64d-a2ffb6b7bfcc">
                <Col className="vertical-sb overlay padding-vertical padding-horizontal" span={24}>
                    <Row className="letterspacing-fix" align="middle" justify="center">
                        <Text className="title-styles" fontStyle="italic" size="140px" color="light" weight="700">
                            {t('aos.music.section0.title')}
                        </Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" fontStyle="italic" weight="600">
                            {t('aos.music.section0.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.music.section0.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.music.section0.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.music.section1.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs1.jpg?alt=media&token=244eb67c-e850-4a7f-9e08-53d3a4132ac2" />
                    <Row className="pt-img-alt">
                        <Text displayblock color="grey">
                            {t('aos.music.section1.imgAlt')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            {/* section2 */}
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs2.jpg?alt=media&token=7ccbd286-8c2f-45e3-94bd-25402d478149" />
                    <Row className="pt-img-alt">
                        <Text displayblock color="grey">
                            {t('aos.music.section2.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.music.section2.imgAltDesc')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Col>
                            <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                                {t('aos.music.section2.text')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section2.textAlt')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section3 */}
            <Banner
                sm={sm}
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs3-1.jpg?alt=media&token=0fae80c1-2b54-45a8-8768-e2afcbfe52c6">
                <Col className="banner-pt" span={24}>
                    <Row className="banner-title-custom" style={!sm && { maxWidth: '50%' }}>
                        <Text displayblock size="38px" color="light" weight="600">
                            {t('aos.music.section3.title')}
                        </Text>
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.music.section3.imgAlt1')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.music.section3.imAlt1Desc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs3-2.JPG?alt=media&token=81c81158-bafa-4509-8d2b-647fabac59c5" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section3.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section3.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.music.section3.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row align="middle" justify="end" className="padding-horizontal mb-md">
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs3-3.jpg?alt=media&token=ad22ffd2-7824-4005-b622-226ffadaec86" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section3.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section3.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section4 */}
            <Row className="mb-sm padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.music.section4.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-lg" align="middle" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs4-1.jpg?alt=media&token=ec021eda-af17-4f79-841b-7a9d320124e7" />

                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section4.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section4.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs4-2.jpg?alt=media&token=d7e082b0-3822-4c11-98c3-7c42e32970e5" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section4.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section4.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section5 */}
            <Row className="mb-sm padding-horizontal mb-sm">
                <Col>
                    <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                        {t('aos.music.section5.text')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.music.section5.textAlt')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs5-1.jpg?alt=media&token=799c069b-90ee-4f54-950e-3622e7b0dd56"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section5.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section5.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs5-2.jpg?alt=media&token=93ad224f-6d04-4710-8456-22984d728b28"
                    />
                    <Row className="pt-img-alt eqh-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section5.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section5.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section6 */}
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs6.png?alt=media&token=f1b673d9-c185-4973-a355-c016a858e1df"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section6.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section6.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <div className="mb-xs">
                        <Text displayblock size="21px" color="light">
                            {t('aos.music.section6.title')}
                        </Text>
                        <Text displayblock style={{ whiteSpace: 'pre-line' }} color="grey" weight="300">
                            {t('aos.music.section6.entries')}
                        </Text>
                    </div>
                    <div>
                        <Text displayblock size="21px" color="light">
                            {t('aos.music.section6.lullaby')}
                        </Text>
                        <Text displayblock style={{ whiteSpace: 'pre-line' }} color="grey" weight="300">
                            {t('aos.music.section6.entries2')}
                        </Text>
                    </div>
                </Col>
            </Row>
            {/* section7 */}
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs7.jpg?alt=media&token=62521277-48cd-4401-9327-a8379a8a77f9" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section7.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section7.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                        {t('aos.music.section7.text')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.music.section7.textAlt')}
                    </Text>
                </Col>
            </Row>
            {/* section8 */}
            <Row className="padding-horizontal" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text style={{ whiteSpace: 'pre-line' }} className="mb-sm" color="light" size="32px" weight="400">
                        {t('aos.music.section8.title')}
                    </Text>
                    <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                        {t('aos.music.section8.text')}
                    </Text>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs8.jpg?alt=media&token=0ac3280a-fde5-4079-b59b-cd55bc133c8f" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section8.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section8.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section9 */}
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Carousel music type="slider1" data={sliderData} />
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row className="pt-img-alt">
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.music.section8.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            {/* section10 */}
            <Row className="mb-sm padding-horizontal mb-sm">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.music.section10.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs10-1.jpg?alt=media&token=18272662-342b-4ded-a009-9a1a0af8664d"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section10.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section10.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs10-2.jpg?alt=media&token=86505e0e-26ec-4db8-9cb1-cb123d13b36e"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section10.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section10.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section11 */}
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row>
                        <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-1.jpg?alt=media&token=e4b01095-05c2-4487-b722-7ac319ff4a35" />
                        <Row className="pt-img-alt">
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.music.section11.imgAlt1')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.music.section11.imgAlt1Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text style={{ whiteSpace: 'pre-line' }} className="mb-sm" color="light" size="32px" weight="400">
                        {t('aos.music.section11.title')}
                    </Text>
                    <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                        {t('aos.music.section11.text')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" align="middle" justify="center" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-2.jpg?alt=media&token=c8cf1a41-5ffb-4f79-8000-e436844e43f9"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper pl-reset'} sm={24} md={16}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-3.jpg?alt=media&token=afb0eb7d-f1ae-40f7-824c-039e1e4a16b1"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper pl-reset'} sm={24} md={24}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-4.jpg?alt=media&token=f64dba9a-7d6e-471c-8546-738482d76c9d"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={10}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-5.jpg?alt=media&token=4ff1d0a3-3d3e-482f-98a7-24ab200ff6e3"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt5')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt5Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper pr-reset'} sm={24} md={14}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-6.jpg?alt=media&token=9aeecad0-d6af-4f95-a8c9-cc45e159d024" />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt6')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt6Desc')}
                            </Text>
                        </Col>
                    </Row>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fslider2-7.jpg?alt=media&token=a8f0e355-4223-4143-9e9a-b512b8591a8f"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section11.imgAlt7')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section11.imgAlt7Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* section12 */}
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                        {t('aos.music.section12.text')}
                    </Text>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row>
                        <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-1.JPG?alt=media&token=0531e387-dfe9-4ca3-89ce-758f92b4fbf7" />
                        <Row className="pt-img-alt">
                            <Col>
                                <Text displayblock color="grey">
                                    {t('aos.music.section12.imgAlt1')}
                                </Text>
                                <Text displayblock color="grey" weight="300">
                                    {t('aos.music.section12.imgAlt1Desc')}
                                </Text>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-2.jpg?alt=media&token=a012bb3b-0de8-4d86-856a-6c91a33aecd6"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section12.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section12.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper pr-reset'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-3.jpg?alt=media&token=6e1c2a18-f709-4f87-8411-0341db1634e2"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section12.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section12.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'pl-reset eqh-wrapper'} sm={24} md={24}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-4.jpg?alt=media&token=3a446186-2b62-45cf-a436-e9da146324fb"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section12.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section12.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-lg" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-5.jpg?alt=media&token=68759abf-49a7-4365-8d16-a45812c6457f"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section12.imgAlt5')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section12.imgAlt5Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2FMusic%2Fs12-6.jpg?alt=media&token=601daad9-c43a-453c-94e6-c2e0d49a2118" />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.music.section12.imgAlt6')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.music.section12.imgAlt6Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-sm padding-horizontal">
                <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                    {t('aos.music.section13.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal pb-sm">
                <iframe
                    title="spotify"
                    style={{ borderRadius: '12px' }}
                    src="https://open.spotify.com/embed/playlist/5TLXoqa2eQYQZEVDXLHz4V?utm_source=generator&theme=0"
                    width="100%"
                    height="380"
                    frameBorder="0"
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                />
            </Row>
        </StyledMusicPage>
    );
};

export default WaterCulturePage;
