import styled from 'styled-components';
import { Row, Col } from 'antd';
import Theme from '@Themes/main';
import { between } from 'polished';

const { paddingHorizontal, paddingBottom, paddingTop } = Theme.landingSectionStyles;

export const Wrapper = styled(Col)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #jqa-hero-video {
        object-fit: cover !important;
        height: 100vh !important;
        width: 100vw !important;
    }

    .button-styles {
        border-right: 4px solid #ffffff;
        border-radius: 0px;
    }

    .button-styles-mobile {
        border-radius: 0px;
        margin-bottom: 3vh;
    }
`;

export const Content = styled(Row)`
    width: 100%;
    min-height: 150px;
`;

export const NavItemsWrapper = styled(Row)`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    padding-left: ${between('10px', paddingHorizontal)};
    padding-right: ${between('10px', paddingHorizontal)};
`;

export const NavItemsWrapperMobile = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    padding-left: ${between('10px', paddingHorizontal)};
    padding-right: ${between('10px', paddingHorizontal)};
`;

export const LandingFooter = styled(Row)`
    position: absolute;
    bottom: 0;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mail-icon {
        font-size: 16px;
        color: #000000 !important;
    }

    .footer-icons {
        z-index: 3000;
        margin-bottom: 3vh;
        cursor: pointer;

        padding: 6px 9px;
        border-radius: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff !important;
        /* border: 1px solid #ffffff; */
    }
`;
