import PageWrapper from '@Components/PageWrapper';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';

export default styled(PageWrapper)`
    background: #252525;
    height: auto;

    .eqh-wrapper {
        display: flex;
        flex-flow: column;
    }

    .eqh-img {
        flex: 1 1 auto;
    }

    .eqh-text {
        flex: 0 1 70px;
    }

    .fade-in {
        @keyframes fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fade-in 2s ease-in forwards;
    }

    .letterspacing-fix {
        padding-left: ${between('15px', '160px', '375px', '1920px')};
    }

    .padding-horizontal {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .padding-vertical {
        padding-top: ${between('100px', '140px', '375px', '1920px')};
        padding-bottom: ${between('20px', '40px', '375px', '1920px')};
    }

    .mt-xs {
        margin-top: 5px;
    }

    .mt-sm {
        margin-top: 16px;
    }

    .mt-20vh {
        margin-top: 20vh;
    }

    .pl-xs {
        padding-left: 10px;
    }

    .pr-xs {
        padding-right: 10px;
    }

    .pl-sm {
        padding-left: 20px;
    }

    .pr-sm {
        padding-right: 20px;
    }

    .pl-generic {
        padding-left: 40px;
    }

    .pr-generic {
        padding-right: 40px;
    }

    .pr-big {
        padding-right: 60px;
    }

    .move-top {
        height: 100%;
        position: absolute;
        bottom: 60px;
        z-index: 10;
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 70px;
    }

    .vertical-center-wo-p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .vertical-start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
    }

    .vertical-sb {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
    }

    .vertical-sa {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        justify-content: space-around;
    }

    .vertical-end {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .flex-full-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .min-height-400 {
        min-height: 525px;
    }

    .mb-xsmall {
        margin-bottom: 5vh;
    }

    .mb-small {
        margin-bottom: 10vh;
    }

    .mb-medium {
        margin-bottom: 20vh;
    }

    .pr-reset {
        padding-right: 0 !important;
    }

    .pl-reset {
        padding-left: 0 !important;
    }

    .mb-big {
        margin-bottom: 30vh;
    }

    .mb-sm-static {
        margin-bottom: 32px;
    }

    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .ant-col:first-child {
        padding-left: 0 !important;
    }

    .ant-col:last-child {
        padding-right: 0 !important;
    }

    .border-test {
        border: 1px solid red;
    }

    .transform-top-sm {
        transform: translateY(-10%);
    }
    .transform-top-md {
        transform: translateY(-25%);
    }
    .transform-top-lg {
        transform: translateY(-40%);
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .h-100vh {
        height: 100vh;
    }

    .border-title {
        border: 1px solid #ffffff;
    }

    .horizontal-center {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
    }

    .pb-xs {
        padding-bottom: 5vh;
    }
    .pb-sm {
        padding-bottom: 10vh;
    }

    .pb-md {
        padding-bottom: 15vh;
    }

    .pt-xs {
        padding-top: 5vh;
    }

    .pt-sm {
        padding-top: 10vh;
    }

    .pt-md {
        padding-top: 30vh;
    }

    .pt-lg {
        padding-top: 50vh;
    }

    .pt-img-alt {
        padding-top: 10px;
    }

    .banner-title {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '60px')};
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: rgba(0, 0, 0, 0.5);
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    .banner-pt {
        padding-top: ${between('30px', '60px')};
    }

    .sara-title {
        position: absolute;
        top: 30%;
        left: 0px;
    }

    .iframe-style {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
`;

export const Banner = styled(Row)`
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-repeat: no-repeat;
    /* background-position: center center;
    background-size: contain; */
    height: ${({ sm }) => (sm ? '600px' : '100vh')};
    background-position: center;
    background-size: 100% 100%;
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
`;
