import styled from 'styled-components';
import { Row, Col, Modal, Carousel } from 'antd';

export const StyledModal = styled(Modal)`
    position: relative;
    .ant-modal-content {
        border: 1px solid ${({ theme }) => theme.colors.light};
        background-color: rgba(2, 11, 27, 0.6);
    }
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-body {
        height: 100%;
        padding: 0px;
    }
    .ant-modal-content {
        border-radius: 8px;
        /* width: 685px; */
        height: 80vh;
    }
    .image-style {
        max-width: 150px;
    }
    .ant-modal-header {
        background-color: rgba(2, 11, 27, 0.6);
    }
    .ant-modal-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

export const ModalContentWrapper = styled(Col)`
    height: 100%;

    .ant-carousel {
        height: 100%;
    }
`;
