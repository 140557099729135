/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import StyledVirtualTourPage from '@Components/PageWrapper';
import { image360ScenesRef } from '@Services/Collections';
import useFetchFirestoreData from '@Hooks/useFetchFirestoreData';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as routeURLs from '@Routes/constants';
import Lottie from 'react-lottie';
import { canonicals } from '@Helpers';
import { Helmet } from 'react-helmet';
import useResponsive from '@Hooks/useMediaQuery';
import InfoCard from './InfoCard';
import animationData from './InfoCard/playing.json';

import {
    StyledMapWrapper,
    StyledEnvironmentOutlined,
    StyledEnvironmentFilled,
    LeftSide,
    StyledHomeOutlined,
    StyledLinkButton,
    StyledArrowRightOutlined,
    StyledArrowLeftOutlined,
} from './styles';

const VirtualTourExperiencePage = () => {
    const ambientRef = useRef('ambient-audio-ref');
    const [isMapVisible, setIsMapVisible] = useState(true);
    const [currentTileData, setCurrentTileData] = useState({ sceneId: 'R0010781' });
    const [sceneData, setSceneData] = useState();
    const [sceneBaseData, setSceneBaseData] = useState();
    const [documents, fetchError] = useFetchFirestoreData(image360ScenesRef);
    const { i18n, t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isAmbientSoundPlaying, setIsAmbientSoundPlaying] = useState(true);
    const { sm } = useResponsive();

    const history = useHistory();

    useEffect(() => {
        const checkEvent = (event) => {
            if (event.origin === 'https://jqa-360view-test.web.app') {
                if (event.data.infoHotspotElementName) {
                    setCurrentTileData({
                        sceneId: event.data.sceneId,
                        infoHotspotElementName: event.data.infoHotspotElementName.toLowerCase().replace(/\s/g, ''),
                    });
                } else {
                    setIsCollapsed(true);
                    setCurrentTileData({
                        sceneId: event.data.sceneId,
                        infoHotspotElementName: undefined,
                    });
                }
            }
        };
        window.addEventListener('message', checkEvent);
        return () => {
            window.removeEventListener('message', checkEvent);
        };
    }, []);

    useEffect(() => {
        if (documents && currentTileData) {
            const filteredScenes = documents.filter((item) => item.docId === currentTileData.sceneId);
            if (filteredScenes.length > 0) {
                const ambientSound = filteredScenes[0].ambient_sound;
                const { mapSRC } = filteredScenes[0];
                setSceneBaseData({ ambientSound, mapSRC });
                if (filteredScenes[0].location_pins && currentTileData.infoHotspotElementName) {
                    const scene = filteredScenes[0].location_pins.filter(({ nameTR }) =>
                        nameTR.toLowerCase().replace(/\s/g, '').includes(currentTileData.infoHotspotElementName.toLowerCase())
                    );

                    setSceneData(scene[0] || null);
                } else {
                    setSceneData(null);
                }
            } else {
                setSceneBaseData();
                setCurrentTileData();
            }
        }
    }, [documents, t, currentTileData]);

    const handleAmbientSoundClicked = () => {
        if (!isAmbientSoundPlaying) {
            ambientRef.current.play();
            setIsAmbientSoundPlaying(true);
        } else {
            ambientRef.current.pause();
            setIsAmbientSoundPlaying(false);
        }
    };

    const AmbientSoundOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useEffect(() => {
        if (sceneData) {
            setIsCollapsed(false);
        }
    }, [sceneData]);

    return (
        <StyledVirtualTourPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.virtualTour')}</title>
                <link rel="canonical" href={canonicals.virtualTourExperience} />
            </Helmet>
            <iframe
                frameBorder="0"
                id="marzipano-iframe"
                style={{ width: '100%', height: '100%' }}
                src="https://jqa-360view-test.web.app/"
                title="W3Schools Free Online Web Tutorials"
                allow="fullscreen"
            />
            {currentTileData && (
                <>
                    <LeftSide>
                        <StyledHomeOutlined onClick={() => history.push(routeURLs.LANDING_PAGE)} />
                        {sceneData && (
                            <>
                                {isCollapsed ? (
                                    <StyledArrowRightOutlined onClick={() => setIsCollapsed(false)} />
                                ) : (
                                    <StyledArrowLeftOutlined style={{ zIndex: '2' }} onClick={() => setIsCollapsed(true)} />
                                )}
                            </>
                        )}
                        {sceneBaseData?.ambientSound && (
                            <audio key={sceneBaseData?.ambientSound} autoPlay loop ref={ambientRef}>
                                <source src={sceneBaseData?.ambientSound} type="audio/mp3" />
                                Your browser does not support the audio element.
                            </audio>
                        )}
                        {isMapVisible
                            ? !sm && <StyledEnvironmentFilled onClick={() => setIsMapVisible(false)} />
                            : !sm && <StyledEnvironmentOutlined style={{ zIndex: '2' }} onClick={() => setIsMapVisible(true)} />}
                        <StyledLinkButton onClick={() => handleAmbientSoundClicked()} type="link">
                            <Lottie isPaused={!isAmbientSoundPlaying} isClickToPauseDisabled options={AmbientSoundOptions} />
                        </StyledLinkButton>
                    </LeftSide>
                    {!isCollapsed && (
                        <InfoCard
                            setIsAmbientSoundPlaying={setIsAmbientSoundPlaying}
                            ambientRef={ambientRef}
                            key={sceneData?.nameTR}
                            descriptionEN={sceneData?.descriptionEN}
                            descriptionTR={sceneData?.descriptionTR}
                            dubsrcEN={sceneData?.dubsrcEN}
                            dubsrcTR={sceneData?.dubsrcTR}
                            nameEN={sceneData?.nameEN}
                            nameTR={sceneData?.nameTR}
                            photos={sceneData?.photos}
                            photos2={sceneData?.['photos ']}
                            plans2={sceneData?.['plans ']}
                            news2={sceneData?.['news ']}
                            plans={sceneData?.plans}
                            news={sceneData?.news}
                        />
                    )}
                    {!sm && (
                        <StyledMapWrapper isMapVisible={isMapVisible} span={10}>
                            <img src={sceneBaseData?.mapSRC} alt="map-src" />
                        </StyledMapWrapper>
                    )}
                </>
            )}
        </StyledVirtualTourPage>
    );
};

export default VirtualTourExperiencePage;
