import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoadingSpinner from '@Components/LoadingSpinner';
import LandingPage from '@Pages/LandingPage';
import VirtualTourPage from '@Pages/VirtualTourPage';
import VirtualTourExperiencePage from '@Pages/VirtualTourExperiencePage';
import ArchiveOfSilencePage from '@Pages/ArchiveOfSilencePage';
import Layout from '@Components/Layout';
import LayoutHeader from '@Components/Layout/Header';
import LayoutContent from '@Components/Layout/Content';
import LayoutFooter from '@Components/Layout/Footer';
import JPoAPage from '@Pages/JPoAPage';
import AuthenticPage from '@Pages/ArchiveOfSilencePage/Authentic';
import BordersPage from '@Pages/ArchiveOfSilencePage/Borders';
import MultiSchoolPage from '@Pages/ArchiveOfSilencePage/MultiSchooled';
import AfterwardPage from '@Pages/ArchiveOfSilencePage/Afterward';
import TrailsPage from '@Pages/ArchiveOfSilencePage/TrailsPage';
import MusicPage from '@Pages/ArchiveOfSilencePage/MusicPage';
import FarewellPage from '@Pages/ArchiveOfSilencePage/Farewell';
import CommunityPage from '@Pages/ArchiveOfSilencePage/Community';
import InfoPage from '@Pages/Info';
import WaterCulturePage from '@Pages/ArchiveOfSilencePage/WaterCulture';
import ScrollTop from '@Components/ScrollToTop';
import * as routeURLs from './constants';

const Routes = () => (
    <Router>
        <Layout>
            <LayoutHeader />
            <Layout>
                <LayoutContent>
                    <Suspense fallback={<LoadingSpinner fullHeight />}>
                        <ScrollTop>
                            <Switch>
                                <Route path={routeURLs.ARCHIVE_OF_SILENCE_PAGE} exact component={ArchiveOfSilencePage} />
                                <Route path={routeURLs.INFO_PAGE} exact component={InfoPage} />
                                <Route path={routeURLs.JEWISH_QUARTER_PAGE} exact component={VirtualTourPage} />
                                <Route path={routeURLs.ANKARA_JEWISH_COMMUNITY_PAGE} exact component={JPoAPage} />
                                <Route path={routeURLs.VIRTUAL_TOUR_PAGE} exact component={VirtualTourExperiencePage} />
                                <Route path={routeURLs.AUTHENTIC_PAGE} exact component={AuthenticPage} />
                                <Route path={routeURLs.COMMUNITY_PAGE} exact component={CommunityPage} />
                                <Route path={routeURLs.MUSIC_PAGE} exact component={MusicPage} />
                                <Route path={routeURLs.BORDERS_PAGE} exact component={BordersPage} />
                                <Route path={routeURLs.MULTI_SCHOOLED_PAGE} exact component={MultiSchoolPage} />
                                <Route path={routeURLs.AFTERWARDS_PAGE} exact component={AfterwardPage} />
                                <Route path={routeURLs.TRAILS_PAGE} exact component={TrailsPage} />
                                <Route path={routeURLs.FAREWELL_PAGE} exact component={FarewellPage} />
                                <Route path={routeURLs.WATER_CULTURE_PAGE} exact component={WaterCulturePage} />
                                <Route path={routeURLs.LANDING_PAGE} exact component={LandingPage} />
                            </Switch>
                        </ScrollTop>
                    </Suspense>
                </LayoutContent>
            </Layout>
            <LayoutFooter />
        </Layout>
    </Router>
);
export default Routes;
