export default [
    {
        id: 1,
        family: 'Alkaş',
        color: 'rgba(89, 70, 96, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Avram Alkaş',
                lat: '39.926767',
                lng: '32.851866',
            },
            {
                id: 'm-2',
                name: 'Ziya Alkaş',
                lat: '39.926874',
                lng: '32.852053',
            },
        ],
    },
    {
        id: 2,
        family: 'Arditti',
        color: 'rgba(101, 108, 77, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Dario Arditi',
                lat: '9.926648',
                lng: '32.851799',
            },
            {
                id: 'm-2',
                name: 'Efrayim Arditti',
                lat: '39.926705',
                lng: '32.851936',
            },
            {
                id: 'm-3',
                name: 'Ferit Arditi',
                lat: '39.926882',
                lng: '32.851939',
            },
            {
                id: 'm-4',
                name: 'Medi Arditi',
                lat: '39.926824',
                lng: '32.851714',
            },
            {
                id: 'm-5',
                name: 'Rıfat Arditti',
                lat: '39.926719',
                lng: '32.852060',
            },
        ],
    },
    {
        id: 3,
        family: 'Attias',
        color: 'rgba(85, 85, 114, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İzak Attias',
                lat: '39.926327',
                lng: '32.851941',
            },
            {
                id: 'm-2',
                name: 'Yusuf Attias',
                lat: '39.926352',
                lng: '32.852078',
            },
            {
                id: 'm-3',
                name: 'Anıl Attias',
                lat: '39.926442',
                lng: '32.851885',
            },
        ],
    },
    {
        id: 4,
        family: 'Biçaçi',
        color: 'rgba(51, 82, 60, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İzak Biçaçi',
                lat: '39.926788',
                lng: '32.851426',
            },
            {
                id: 'm-2',
                name: 'Jak Biçaçi',
                lat: '39.926850',
                lng: '32.851343',
            },
            {
                id: 'm-3',
                name: 'Liya Biçaçi',
                lat: '39.926860',
                lng: '32.851303',
            },
            {
                id: 'm-4',
                name: 'Moşe Biçaçi',
                lat: '39.926965',
                lng: '39.926965',
            },
        ],
    },
    {
        id: 5,
        family: 'Arav (Safkan)',
        color: 'rgba(105, 33, 33, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İshak Çakır',
                lat: '39.927544',
                lng: '32.851465',
            },
            {
                id: 'm-2',
                name: 'Beki Çakır',
                lat: '39.927648',
                lng: '32.851482',
            },
            {
                id: 'm-3',
                name: 'Moiz (Moşe) Çakır',
                lat: '39.927670',
                lng: '32.851231',
            },
            {
                id: 'm-4',
                name: 'Albert Çakır',
                lat: '39.927706',
                lng: '32.851339',
            },
        ],
    },
    {
        id: 6,
        family: 'Nahum',
        color: 'rgba(58, 50, 20, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Bernard Nahum',
                lat: '39.926706',
                lng: '32.851716',
            },
            {
                id: 'm-2',
                name: 'Gabriel Nahum',
                lat: '39.926659',
                lng: '32.851743',
            },
            {
                id: 'm-3',
                name: 'Gaston Nahum',
                lat: '39.926616',
                lng: ' 32.851813',
            },
            {
                id: 'm-4',
                name: 'Mile Sidoni Nahum',
                lat: '39.926719',
                lng: '32.851703',
            },
        ],
    },
    {
        id: 7,
        family: 'Yafet',
        color: 'rgba(170, 135, 9, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Dolly Yafet Aji',
                lat: '39.927021',
                lng: '32.850624',
            },
            {
                id: 'm-2',
                name: 'Mimi Yafet',
                lat: '39.926939',
                lng: '32.850584',
            },
        ],
    },
    {
        id: 8,
        family: 'Kişiler',
        color: 'rgba(136, 138, 135, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Almozlino',
                lat: '39.926928',
                lng: '32.850109',
            },
        ],
    },
];
