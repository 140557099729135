export const FontTheme = {
    Family: {
        primary: 'Source Sans Pro',
        secondary: 'Open Sans',
    },
    Color: {
        black: '#020B1B',
        darkBlack: '#000000',
        lightGrey: '#CCCCCC',
        grey: '#C8C8C8',
        smoke: 'rgba(33, 33, 33, 0.5)',
        light: '#FFFFFF',
        link: '#1b81e6',
        blue: '#1F62C4',
    },
    Size: {
        tiny: '12px',
        description: '14px',
        regular: '16px',
        nobleRegular: '18px',
        title: '24px',
        nobleTitle: '28px',
        motto: '48px',
        bigMotto: '96px',
    },
    Weight: {
        light: '300',
        regular: '400',
        semiBold: '500',
        medium: '600',
        bold: '700',
        extraBold: '800',
    },
    Style: {
        normal: 'normal',
        italic: 'italic',
        oblique: 'oblique',
    },
};
