/* eslint-disable sonarjs/no-nested-template-literals */
import styled from 'styled-components';
import { Row, Col, Modal } from 'antd';
import { GoogleMap } from '@react-google-maps/api';
import Button from '@Components/Button';
import { between } from 'polished';

export default styled(GoogleMap)`
    .fade-in {
        opacity: 0 !important;
    }
`;
export const StyledLegend = styled.div`
    background-color: rgba(255, 255, 255, 0.6) !important;
    padding-top: 24px;
    max-height: 50vh;
    overflow-y: auto !important;

    padding-bottom: 24px;
    border-top-right-radius: 24px;
    position: absolute;
    left: 0px;
    bottom: 0px !important;

    .dot-style {
        border-radius: 90%;
    }

    .space {
        margin-bottom: 4px;
    }
`;

export const Wrapper = styled(Row)`
    height: 100vh;
    position: relative;
`;

export const Dot = styled.div`
    margin-right: 16px;
    height: 16px;
    width: 16px;
    background-color: ${({ color, active }) => (!active ? 'rgba(255,255,255,0.2)' : color)};
    border: ${({ color, active }) => !active && `2px solid ${color}`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inner-dot {
        border-radius: 90%;
        border: 3px solid #ffffff;
    }
`;

export const StyledButton = styled(Button)`
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    border-radius: 0px;

    background: transparent !important;

    .inner-text {
        span {
            color: ${({ isActive, theme }) => (isActive ? theme.colors.light : theme.colors.black)} !important;
        }
    }

    :hover {
        background: transparent !important;

        /* .inner-text {
            span {
                color: ${({ theme }) => theme.colors.light} !important;
            }
        } */
    }

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
    }
`;

export const StyledModal = styled(Modal)`
    .h-100 {
        height: 100%;
    }

    .mb-sm {
        margin-bottom: 10vh;
    }

    .pt-alt {
        padding-top: 10px;
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        padding: 10px !important;
    }
    .vertical-sb {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px !important;
    }

    .ant-modal-content {
        border: 1px solid ${({ theme }) => theme.colors.light};
        background-color: rgba(2, 11, 27, 0.6);
    }
    .ant-modal-footer {
        display: none !important;
    }
    .ant-modal-body {
        height: 100%;
        padding: 0px;
    }
    .ant-modal-content {
        border-radius: 8px;
        /* width: 685px; */
        height: auto;
    }
    .image-style {
        max-width: 150px;
    }
    .ant-modal-header {
        background-color: rgba(2, 11, 27, 0.6);
    }
    .ant-modal-title {
        display: none;
        /* flex-direction: row;
        align-items: center;
        justify-content: center; */
    }
`;

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
`;
