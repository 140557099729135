/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useState } from 'react';
import LoadingSpinner from '@Components/LoadingSpinner';
import { Text } from '@Components/Typography/styles';
import { Col, Row } from 'antd';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

import SwiperCore, { Navigation, Pagination, EffectFade, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { SwiperWrapper, Img, AltContainer } from './styles';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

const Carousel = (props) => {
    const { sm } = useResponsive();
    const [swiperInstance, setSwiperInstance] = useState();
    const [activeIndex, setActiveIndex] = useState();
    const { i18n } = useTranslation('common');
    const { data, type, authentic, multischooled, textcolor, trails, water, music } = props;
    SwiperCore.use([EffectFade, Pagination, Navigation]);

    return (
        <SwiperWrapper {...props}>
            <Swiper
                spaceBetween={30}
                effect="fade"
                fadeEffect={{
                    crossFade: true,
                }}
                navigation
                slidesPerView={1}
                onSlideChange={() => setActiveIndex(swiperInstance.activeIndex)}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                pagination={{
                    type: 'progressbar',
                    clickable: true,
                }}
                className={multischooled ? 'multi-schooled-slider-controls mySwiper' : trails ? 'trails-slider-controls mySwiper' : 'mySwiper'}>
                {!data ? (
                    <LoadingSpinner />
                ) : authentic ? (
                    data?.map(({ id, url, imgAltTR, imgAltDescTR, imgAltEN, imgAltDescEN }, i) => (
                        <SwiperSlide className={sm ? 'h-30vh' : 'h-100vh'} key={url}>
                            <Img src={url} />
                            <AltContainer key={`authentic-${url}`} className="padding-horizontal-text authentic-text-wrapper">
                                {swiperInstance && swiperInstance.activeIndex === i && (
                                    <Col>
                                        <Text displayblock color={textcolor || 'grey'}>
                                            {i18n.language === 'tr' ? imgAltTR : imgAltEN}
                                        </Text>
                                        <Text displayblock color={textcolor || 'grey'} weight="300">
                                            {i18n.language === 'tr' ? imgAltDescTR : imgAltDescEN}
                                        </Text>
                                    </Col>
                                )}
                            </AltContainer>
                        </SwiperSlide>
                    ))
                ) : multischooled ? (
                    data?.map(({ id, url, imgAltTR, imgAltDescTR, imgAltEN, imgAltDescEN }, i) => (
                        <SwiperSlide className={sm && 'h-30vh '} key={url}>
                            <Img style={{ height: 'auto' }} src={url} />
                            <AltContainer key={`multischooled-${url}`} className="padding-horizontal">
                                {swiperInstance && swiperInstance.activeIndex === i && (
                                    <Col>
                                        <Text displayblock color={textcolor || 'grey'}>
                                            {i18n.language === 'tr' ? imgAltTR : imgAltEN}
                                        </Text>
                                        <Text displayblock color={textcolor || 'grey'} weight="300">
                                            {i18n.language === 'tr' ? imgAltDescTR : imgAltDescEN}
                                        </Text>
                                    </Col>
                                )}
                            </AltContainer>
                        </SwiperSlide>
                    ))
                ) : trails ? (
                    data?.map(({ altDescEN, altDescTR, altEN, altTR, src }, i) => (
                        <>
                            <SwiperSlide className={sm && 'h-30vh '} key={src}>
                                <Img className="trails-img" src={src} />
                                <AltContainer key={`trails-${src}`}>
                                    {swiperInstance && swiperInstance.activeIndex === i && (
                                        <Col>
                                            <Text displayblock color={textcolor || 'grey'}>
                                                {i18n.language === 'tr' ? altTR : altEN}
                                            </Text>
                                            <Text displayblock color={textcolor || 'grey'} weight="300">
                                                {i18n.language === 'tr' ? altDescTR : altDescEN}
                                            </Text>
                                        </Col>
                                    )}
                                </AltContainer>
                            </SwiperSlide>
                        </>
                    ))
                ) : water ? (
                    data?.map(({ id, src, imgAlt, imgAltDesc }, i) => (
                        <SwiperSlide className={sm && 'h-30vh '} key={id}>
                            <Img style={{ height: 'auto' }} src={src} />
                            <AltContainer className="water-alt-container" key={`water-${src}`}>
                                {swiperInstance && swiperInstance.activeIndex === i && (
                                    <Col>
                                        <Text displayblock color={textcolor || 'grey'}>
                                            {imgAlt}
                                        </Text>
                                        <Text displayblock color={textcolor || 'grey'} weight="300">
                                            {imgAltDesc}
                                        </Text>
                                    </Col>
                                )}
                            </AltContainer>
                        </SwiperSlide>
                    ))
                ) : music ? (
                    data?.images.map(({ id, imgURL }, i) => (
                        <SwiperSlide className={sm ? 'h-30vh' : 'h-100vh'} key={`${type}-${id}`}>
                            <Img src={imgURL} />
                            <AltContainer
                                style={{ backgroundColor: 'rgba(0,0,0,0.3)', paddingLeft: '10px', paddingRight: '10px;' }}
                                className="music-text-wrapper">
                                <Col>
                                    <Text displayblock color="light">
                                        {data.imgAlt}
                                    </Text>
                                    <Text displayblock color="light" weight="300">
                                        {data.imgAltDesc}
                                    </Text>
                                </Col>
                            </AltContainer>
                        </SwiperSlide>
                    ))
                ) : (
                    data?.images.map(({ id, imgURL }, i) => (
                        <SwiperSlide className={sm ? 'h-30vh' : 'h-100vh'} key={`${type}-${id}`}>
                            <Img src={imgURL} />
                            <AltContainer className="padding-horizontal authentic-text-wrapper">
                                <Col>
                                    <Text displayblock color={textcolor || 'grey'}>
                                        {data.imgAlt}
                                    </Text>
                                    <Text displayblock color={textcolor || 'grey'} weight="300">
                                        {data.imgAltDesc}
                                    </Text>
                                </Col>
                            </AltContainer>
                        </SwiperSlide>
                    ))
                )}
            </Swiper>
        </SwiperWrapper>
    );
};

export default Carousel;
