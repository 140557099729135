/* eslint-disable sonarjs/no-duplicate-string */
import React, { useRef, useState } from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import LoadingSpinner from '@Components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { Text } from '@Components/Typography/styles';
import { jpoaRef } from '@Services/Collections/index';
import Button from '@Components/Button';
import { Row, Col } from 'antd';
import useFetchFirestoreData from '@Hooks/useFetchFirestoreData';
import useResponsive from '@Hooks/useMediaQuery';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';

import StyledJPoAPage, {
    Overlay,
    StyledCarousel,
    CarouselElementWrapper,
    InsideWrapper,
    PhotoWrapper,
    TextContainer,
    BackgroundImageWrapper,
} from './styles';

const JPoAPage = () => {
    const { i18n, t } = useTranslation('common');
    const [documents] = useFetchFirestoreData(jpoaRef);
    const { ismobile, sm } = useResponsive();
    const carouselRef = useRef('carousel-ref');
    const [backgroundIMG, setBackgroundIMG] = useState(
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/JPOA%2Fbeki%20bahar%2029B%20-%20edit.jpg?alt=media&token=7cbecaa8-fad2-4dde-9c7d-80a85f68cd1c'
    );

    const handleNextClick = () => {
        carouselRef.current.next();
    };

    const handlePrevClick = () => {
        carouselRef.current.prev();
    };

    return (
        <StyledJPoAPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.jpoa')}</title>
                <link rel="canonical" href={canonicals.jpoa} />
            </Helmet>
            <StyledCarousel effect="fade" ref={carouselRef} dotPosition="bottom">
                {!documents ? (
                    <LoadingSpinner />
                ) : (
                    documents?.map(({ orderId, descriptionEN, descriptionTR, altTR, altEN, altDescEN, altDescTR, imgSRC }, i) => (
                        <>
                            <BackgroundImageWrapper key={`img-${imgSRC}`} backgroundIMG={imgSRC} />
                            <Overlay />
                            <CarouselElementWrapper ismobile={ismobile} key={orderId}>
                                <Button
                                    style={{ backgroundColor: 'black', width: '40px', height: '40px' }}
                                    type="link"
                                    className="right-arrow"
                                    icon={<RightCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />}
                                    onClick={() => handleNextClick()}
                                />
                                <Button
                                    style={{ backgroundColor: 'black', width: '40px', height: '40px' }}
                                    type="link"
                                    icon={<LeftCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />}
                                    className="left-arrow"
                                    onClick={() => handlePrevClick()}
                                />
                                <InsideWrapper align="middle" justify="center" gutter={[{ xs: 0, sm: 0, md: 64 }, 0]}>
                                    <PhotoWrapper className={sm && 'photo-wrapper-mobile'} sm={24} md={16} style={{ paddingLeft: '0px' }}>
                                        <img src={imgSRC} alt="" />
                                    </PhotoWrapper>
                                    <TextContainer
                                        className={sm && 'text-container-mobile'}
                                        sm={24}
                                        md={6}
                                        style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                        <Row className={sm ? 'justify-end' : 'description-styles'} align="start" justify="start">
                                            <Text className={sm && 'scroll-limit'} color="light" size="18px">
                                                {i18n.language === 'tr' ? descriptionTR : descriptionEN}
                                            </Text>
                                        </Row>
                                        <Row className={sm ? 'alt-styles-mobile' : 'alt-styles'} align="start" justify="start">
                                            <Col className={sm && 'scroll-limit-alt'}>
                                                <Text displayblock color="grey">
                                                    {i18n.language === 'tr' ? altTR : altEN}
                                                </Text>
                                                <Text displayblock color="grey" weight="300">
                                                    {i18n.language === 'tr' ? altDescTR : altDescEN}
                                                </Text>
                                            </Col>
                                            {/* <Text color="light" weight="light" size="18px">
                                                {i18n.language === 'tr' ? altTR : altEN}
                                            </Text> */}
                                        </Row>
                                    </TextContainer>
                                </InsideWrapper>
                            </CarouselElementWrapper>
                        </>
                    ))
                )}
            </StyledCarousel>
        </StyledJPoAPage>
    );
};

export default JPoAPage;
