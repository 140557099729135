import React from 'react';
import useResponsive from '@Hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import LogoEN from '@Assets/icons/main-logo-en.png';
import LogoTR from '@Assets/icons/main-logo-tr.png';
import * as routeURLs from '@Routes/constants';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@Components/Button';
import StyledDrawer from './styles';

const Drawer = (props) => {
    const { t, i18n } = useTranslation('common');
    const { sm } = useResponsive();
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;

    // const isAos = pathname !== `${routeURLs.ARCHIVE_OF_SILENCE_PAGE}/` && pathname.includes(`${routeURLs.ARCHIVE_OF_SILENCE_PAGE}/`);

    return (
        <StyledDrawer {...props}>
            <Col span={24}>
                <Row align="middle" justify="center" style={{ marginBottom: '10vh' }}>
                    {i18n.language === 'tr' ? (
                        <img src={LogoTR} className="icon-width" alt="logo-tr" />
                    ) : (
                        <img src={LogoEN} className="icon-width" alt="logo-tr" />
                    )}
                </Row>
                <Row align="middle" justify="center">
                    <Col>
                        {t('aos.navItems', { returnObjects: true }).map(({ id, name, path }) => (
                            <Row align="middle" justify="center" key={id} style={{ paddingBottom: '3vh' }}>
                                <Button
                                    className={pathname.includes(path) && 'aos-active-btn'}
                                    textcolor="light"
                                    onClick={() => history.push(path)}
                                    type="link"
                                    style={{ padding: '0px', borderRadius: '0px' }}>
                                    {name}
                                </Button>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Col>
        </StyledDrawer>
    );
};

export default Drawer;
