/* eslint-disable no-plusplus */
/* eslint-disable sonarjs/no-duplicate-string */
import {
    virtualTourVideoInterviewsRef,
    virtualTourPhotosRef,
    virtualTourNewsRef,
    virtualTourMapsRef,
    virtualTourAerialPhotosRef,
    virtualTourGeneralInfoRef,
} from '@Services/Collections';

export const fireStoreRefDecorator = (data) => {
    switch (data) {
        case 'aerial':
            return virtualTourAerialPhotosRef;
        case 'photos':
            return virtualTourPhotosRef;
        case 'maps':
            return virtualTourMapsRef;
        case 'news':
            return virtualTourNewsRef;
        case 'interviews':
            return virtualTourVideoInterviewsRef;
        case 'general':
            return virtualTourGeneralInfoRef;
        default:
            break;
    }
};

export const getCenterOfPolygon = (polygon) => {
    function Point(lat, lng) {
        this.lat = lat;
        this.lng = lng;
    }

    function Region(points) {
        this.points = points || [];
        this.length = points.length;
    }

    Region.prototype.area = function () {
        let area = 0;
        let i;
        let j;
        let point1;
        let point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.lat * point2.lng;
            area -= point1.lng * point2.lat;
        }
        area /= 2;

        return area;
    };

    Region.prototype.centroid = function () {
        let lat = 0;
        let lng = 0;
        let i;
        let j;
        let f;
        let point1;
        let point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            f = point1.lat * point2.lng - point2.lat * point1.lng;
            lat += (point1.lat + point2.lat) * f;
            lng += (point1.lng + point2.lng) * f;
        }

        f = this.area() * 6;

        return new Point(lat / f, lng / f);
    };

    const region = new Region(polygon);

    return region.centroid();
};

export const canonicals = {
    landing: 'https://jewish-quarter-ankara.web.app/',
    jpoa: 'https://jewish-quarter-ankara.web.app/jewish-people-of-ankara',
    virtualTour: 'https://jewish-quarter-ankara.web.app/virtual-tour',
    aos: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence',
    community: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/community',
    authentic: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/authentic',
    trails: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/trails',
    music: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/music',
    multiSchooled: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/multi-schooled',
    farewell: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/farewell',
    waterCulture: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/water-culture',
    borders: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/borders',
    afterward: 'https://jewish-quarter-ankara.web.app/archive-of-the-silence/afterward',
    virtualTourExperience: 'https://jewish-quarter-ankara.web.app/virtual-tour-experience',
};
