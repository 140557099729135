export default [
    {
        id: 1,
        family: 'Alaton',
        color: 'rgba(72, 149, 239, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Binyamin Alaton',
                lat: '39.935045',
                lng: '39.935045',
            },
            {
                id: 'm-2',
                name: 'İshak Alaton',
                lat: '39.935184',
                lng: '32.860613',
            },
            {
                id: 'm-3',
                name: 'Kalef Alaton ',
                lat: '39.935052',
                lng: '32.861214',
            },
            {
                id: 'm-4',
                name: 'Simha Alaton',
                lat: '39.934970',
                lng: '32.861246',
            },
        ],
    },
    {
        id: 2,
        family: 'Albukrek',
        color: 'rgba(96, 108, 56, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Aron Ender',
                lat: '39.935776',
                lng: '32.860390',
            },
            {
                id: 'm-2',
                name: 'David Peker',
                lat: '39.935723',
                lng: '32.860735',
            },
            {
                id: 'm-3',
                name: 'Gizel Ender Hazan',
                lat: '39.935569',
                lng: '32.860294',
            },
            {
                id: 'm-4',
                name: 'Klaçura Ender',
                lat: '39.935653',
                lng: '32.860407',
            },
            {
                id: 'm-5',
                name: 'Klara Albukrek',
                lat: '39.935816',
                lng: '32.860642',
            },
            {
                id: 'm-6',
                name: 'Lazar Elyazar',
                lat: '39.935745',
                lng: '32.860639',
            },
            {
                id: 'm-7',
                name: 'Lina Albukrek',
                lat: '39.935628',
                lng: '32.860315',
            },
            {
                id: 'm-8',
                name: 'Maris Ender Albukrek',
                lat: '39.935643',
                lng: '32.860733',
            },
            {
                id: 'm-9',
                name: 'Marko Ovadya Albukrek',
                lat: '39.935382',
                lng: '32.860535',
            },
            {
                id: 'm-10',
                name: 'Menaşe Albukrek',
                lat: '39.935600',
                lng: '32.860358',
            },
            {
                id: 'm-11',
                name: 'Moiz Albukrek',
                lat: '39.935565',
                lng: '32.860347',
            },
            {
                id: 'm-12',
                name: 'Raşel Albukrek',
                lat: '39.935429',
                lng: '32.860427',
            },
            {
                id: 'm-13',
                name: 'Şimon Elyazar',
                lat: '39.935870',
                lng: '32.860646',
            },
            {
                id: 'm-14',
                name: 'Verjeni Albukrek (Ender)',
                lat: '39.935450',
                lng: '32.860300',
            },
            {
                id: 'm-15',
                name: 'Vida Kalef Albukrek',
                lat: '39.935667',
                lng: '32.860806',
            },
            {
                id: 'm-16',
                name: 'Viktor Albukrek',
                lat: '39.935643',
                lng: '32.860309',
            },
            {
                id: 'm-17',
                name: 'Virjini Albukrek',
                lat: '39.935436',
                lng: '32.860313',
            },
            {
                id: 'm-18',
                name: 'Yaakov Albukrek',
                lat: '39.935604',
                lng: '32.860327',
            },
            {
                id: 'm-19',
                name: 'Yaşar Hayim Paker (Albukrek)',
                lat: '39.935628',
                lng: '32.860152',
            },
            {
                id: 'm-20',
                name: 'Yusuf Albukrek',
                lat: '39.935601',
                lng: '32.860482',
            },
            {
                id: 'm-21',
                name: 'Yusuf Elyazar',
                lat: '39.935644',
                lng: '32.860823',
            },
            {
                id: 'm-22',
                name: 'Hayim Albukrek',
                lat: '39.935703',
                lng: '32.860760',
            },
        ],
    },
    {
        id: 3,
        family: 'Almaleh',
        color: 'rgba(68, 68, 132, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Aron Almaleh Bayramiç',
                lat: '39.935426',
                lng: '32.859199',
            },
            {
                id: 'm-2',
                name: 'Eliya Almaleh',
                lat: '39.935180',
                lng: '32.860172',
            },
            {
                id: 'm-3',
                name: 'İsak Almaleh',
                lat: '39.935488',
                lng: '32.859798',
            },
            {
                id: 'm-4',
                name: 'Kemal Şenmelek (Avimelek)',
                lat: '39.934863',
                lng: '32.860227',
            },
            {
                id: 'm-5',
                name: 'Roza Almaleh',
                lat: '39.935332',
                lng: '32.860592',
            },
            {
                id: 'm-6',
                name: 'Yaakov Almaleh (Yakup Almaleh)',
                lat: '39.934699',
                lng: '32.859562',
            },
            {
                id: 'm-7',
                name: 'Ziya Şenmelek (Avimelek)',
                lat: '39.934732',
                lng: '32.858489',
            },
        ],
    },
    {
        id: 4,
        family: 'Araf',
        color: 'rgba(87, 117, 144, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Aaron Araf',
                lat: '39.934746',
                lng: '32.860686',
            },
            {
                id: 'm-2',
                name: 'Bonomo Araf',
                lat: '39.934703',
                lng: '32.860603',
            },
            {
                id: 'm-3',
                name: 'Ojeni Araf',
                lat: '39.935065',
                lng: '32.860737',
            },
            {
                id: 'm-4',
                name: 'Virgini Araf',
                lat: '39.934851',
                lng: '32.860541',
            },
        ],
    },
    {
        id: 5,
        family: 'Arav (Safkan)',
        color: 'rgba(35, 96, 100, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Izak Arav',
                lat: '39.936175',
                lng: '32.860762',
            },
            {
                id: 'm-2',
                name: 'Sara Luna Arav',
                lat: '39.936052',
                lng: '32.860784',
            },
            {
                id: 'm-3',
                name: 'Leona Arav Arav',
                lat: '39.935786',
                lng: '32.860953',
            },
            {
                id: 'm-4',
                name: 'Eliya Arav',
                lat: '39.935671',
                lng: '32.860964',
            },
            {
                id: 'm-5',
                name: 'Halifa Vitali Safkan',
                lat: '39.936220',
                lng: '32.860588',
            },
            {
                id: 'm-6',
                name: 'Sara Safkan',
                lat: '39.936023',
                lng: '32.860829',
            },
        ],
    },
    {
        id: 6,
        family: 'Blok',
        color: 'rgba(73, 39, 94, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Yusuf Blok',
                lat: '39.935396',
                lng: '32.861267',
            },
            {
                id: 'm-2',
                name: 'Sara Blok',
                lat: '39.935723',
                lng: '32.860969',
            },
        ],
    },
    {
        id: 7,
        family: 'Bonomo',
        color: 'rgba(94, 80, 63, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Sara Bonomo',
                lat: '39.935986',
                lng: '32.860674',
            },
            {
                id: 'm-2',
                name: 'Yosef Bonomo',
                lat: '39.935924',
                lng: '32.860768',
            },
            {
                id: 'm-3',
                name: 'Mordo Bonomo',
                lat: '39.935858',
                lng: '32.860813',
            },
        ],
    },
    {
        id: 8,
        family: 'Çakır',
        color: 'rgba(105, 33, 33, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Mazaluça Çakır',
                lat: '39.934774',
                lng: '32.859868',
            },
            {
                id: 'm-2',
                name: 'Moiz Çakır',
                lat: '39.934686',
                lng: '32.859894',
            },
            {
                id: 'm-3',
                name: 'Yeşaya Çakır',
                lat: '39.934701',
                lng: '32.859986',
            },
        ],
    },
    {
        id: 9,
        family: 'Eşkenazi',
        color: 'rgba(201, 124, 93, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Fuli Eşkenazi',
                lat: '39.935052',
                lng: '32.860765',
            },
            {
                id: 'm-2',
                name: 'Mile Eşkenazi',
                lat: '39.935019',
                lng: '32.860757',
            },
            {
                id: 'm-3',
                name: 'Nesim Eşkenazi',
                lat: '39.934918',
                lng: '32.860907',
            },
        ],
    },
    {
        id: 10,
        family: 'Kohen',
        color: 'rgba(244, 185, 66, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Albert Erkip',
                lat: '39.935089',
                lng: '32.861438',
            },
            {
                id: 'm-2',
                name: 'Davit Kohen',
                lat: '39.935031',
                lng: '32.861468',
            },
            {
                id: 'm-3',
                name: 'Elza Erkip',
                lat: '39.934951',
                lng: '32.861460',
            },
            {
                id: 'm-4',
                name: 'Hayim Kohen Erkip',
                lat: '39.934893',
                lng: '32.861460',
            },
            {
                id: 'm-5',
                name: 'Jak Kohen Erkip',
                lat: '39.934980',
                lng: '32.861374',
            },
            {
                id: 'm-6',
                name: 'Kemal Kohen (Yürüm)',
                lat: '39.935039',
                lng: '32.861318',
            },
            {
                id: 'm-7',
                name: 'Öjeni Kohen (Yürüm)',
                lat: '39.934986',
                lng: '32.861342',
            },
            {
                id: 'm-8',
                name: 'Marko Kohen Erkip',
                lat: '39.935089',
                lng: '32.861481',
            },
            {
                id: 'm-9',
                name: 'Nisim Erkip',
                lat: '39.935027',
                lng: '32.861355',
            },
        ],
    },
    {
        id: 11,
        family: 'Krespi',
        color: 'rgba(156, 175, 183, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Binyamin Krespi',
                lat: '39.935391',
                lng: '32.860038',
            },
            {
                id: 'm-2',
                name: 'Mari Krespi',
                lat: '39.935564',
                lng: '32.859979',
            },
            {
                id: 'm-3',
                name: 'Çelebonaçi Krespi',
                lat: '39.935396',
                lng: '32.859786',
            },
            {
                id: 'm-4',
                name: 'Davit Krespi',
                lat: '39.935447',
                lng: '32.859842',
            },
            {
                id: 'm-5',
                name: 'Hayim Krespi',
                lat: '39.935454',
                lng: '32.859671',
            },
            {
                id: 'm-6',
                name: 'Jak Krespi',
                lat: '39.935351',
                lng: '32.859593',
            },
            {
                id: 'm-7',
                name: 'Nesim Krespi',
                lat: '39.935271',
                lng: '32.859606',
            },
            {
                id: 'm-8',
                name: 'Öjeni Krespi',
                lat: '39.935213',
                lng: '32.859644',
            },
            {
                id: 'm-9',
                name: 'Raheline Krespi',
                lat: '39.935153',
                lng: '32.859630',
            },
            {
                id: 'm-10',
                name: 'Raşel Krespi',
                lat: '39.93516',
                lng: '32.859714',
            },
            {
                id: 'm-11',
                name: 'Sol Baruh Krespi',
                lat: '39.935373',
                lng: '32.860073',
            },
            {
                id: 'm-12',
                name: 'Yaakov Krespi',
                lat: '39.935252',
                lng: '32.859598',
            },
        ],
    },
    {
        id: 12,
        family: 'Levi',
        color: 'rgba(0, 67, 70, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Eli Levi',
                lat: '39.935795',
                lng: '32.859813',
            },
            {
                id: 'm-2',
                name: 'Hayim Tezman (Levi)',
                lat: '39.935723',
                lng: '32.859504',
            },
            {
                id: 'm-3',
                name: 'Josef Levi (Yusuf Tezman)',
                lat: '39.935495',
                lng: '32.860390',
            },
            {
                id: 'm-4',
                name: 'Refika Albukrek Tezman',
                lat: '39.935484',
                lng: '32.860512',
            },
            {
                id: 'm-5',
                name: 'Mario Levi',
                lat: '39.935484',
                lng: '32.860512',
            },
            {
                id: 'm-6',
                name: 'Tamar Levi',
                lat: '39.935645',
                lng: '32.860441',
            },
            {
                id: 'm-7',
                name: 'Rebeka L. Tezman',
                lat: '39.935567',
                lng: '32.860342',
            },
        ],
    },
    {
        id: 13,
        family: 'Mukatel',
        color: 'rgba(214, 104, 83, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Kemal Mukatel',
                lat: '39.935194',
                lng: '32.860737',
            },
            {
                id: 'm-2',
                name: 'Paker Mukatel',
                lat: '39.935171',
                lng: '32.860790',
            },
            {
                id: 'm-3',
                name: 'Erol Mukatel',
                lat: '39.935253',
                lng: '32.860589',
            },
            {
                id: 'm-4',
                name: 'Katya Mukatel',
                lat: '39.935206',
                lng: '32.860669',
            },
        ],
    },
    {
        id: 14,
        family: 'Ruso',
        color: 'rgba(54, 65, 86, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Klara Ruso (Esendemir)',
                lat: '39.935077',
                lng: '32.860778',
            },
            {
                id: 'm-2',
                name: 'Rahamim Ruso',
                lat: '39.935032',
                lng: '32.860743',
            },
            {
                id: 'm-3',
                name: 'Samuel Ruso',
                lat: '39.935077',
                lng: '32.860657',
            },
            {
                id: 'm-4',
                name: 'Şemuel Ruso',
                lat: '39.935126',
                lng: '32.860604',
            },
            {
                id: 'm-5',
                name: 'Vitorya Ruso',
                lat: '39.935143',
                lng: '32.860556',
            },
            {
                id: 'm-6',
                name: 'Yasef Ruso (Esendemir)',
                lat: '39.935186',
                lng: '32.860499',
            },
            {
                id: 'm-7',
                name: 'İsak Esendemir',
                lat: '39.935120',
                lng: '32.860483',
            },
        ],
    },
    {
        id: 15,
        family: 'Yolak',
        color: 'rgba(188, 184, 177, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Nesim Selonu ve Eşi',
                lat: '39.935562',
                lng: '32.859959',
            },
        ],
    },
    {
        id: 16,
        family: 'Algazi',
        color: 'rgba(34, 34, 59, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Algazi Ailesi / Algazi Family',
                lat: '39.935356',
                lng: '32.860431',
            },
        ],
    },
    {
        id: 17,
        family: 'Kişiler',
        color: 'rgba(20, 199, 167, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Avram Sigal',
                lat: '39.934920',
                lng: '32.860273',
            },
            {
                id: 'm-2',
                name: 'Değirmenci Kozmeto',
                lat: '39.942924',
                lng: '32.862895',
            },
            {
                id: 'm-3',
                name: 'Kemal Seviya',
                lat: '39.934638',
                lng: '32.860287',
            },
            {
                id: 'm-4',
                name: 'Moiz Boton',
                lat: '39.935605',
                lng: '32.859994',
            },
            {
                id: 'm-5',
                name: 'R. Bensusen',
                lat: '39.934663',
                lng: '32.858602',
            },
            {
                id: 'm-6',
                name: 'Rahamim',
                lat: '39.935187',
                lng: '32.861044',
            },
            {
                id: 'm-7',
                name: 'Sara',
                lat: '39.935048',
                lng: '32.860876',
            },
            {
                id: 'm-8',
                name: "Sara'nın annesi",
                lat: '39.934994',
                lng: '32.86095',
            },
            {
                id: 'm-9',
                name: "Tamar Hanım (Yirmibinlik Hanım, 'La devente mil')",
                lat: '39.934945',
                lng: '32.860424',
            },
            {
                id: 'm-10',
                name: 'Davit Kazado',
                lat: '39.935474',
                lng: '32.859966',
            },
            {
                id: 'm-11',
                name: 'Leon Sadoch',
                lat: '39.935610',
                lng: '32.861081',
            },
        ],
    },
];
