/* eslint-disable sonarjs/no-nested-template-literals */
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';

export const SwiperWrapper = styled(Row)`
    .mySwiper {
        --swiper-theme-color: #000000;
        --swiper-navigation-size: ${between('12px', '24px')};
    }

    .swiper-container {
        width: 100%;
    }

    .h-50vh {
        height: 50vh;
    }

    .h-100vh {
        height: 100vh;
    }

    .swiper-button-next {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: ${between('16px', '24px')};
        border-radius: ${between('16px', '24px')};
    }

    .swiper-button-prev {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: ${between('16px', '24px')};
        border-radius: ${between('16px', '24px')};
    }

    .padding-horizontal {
        padding-left: ${between('30px', '110px')};
        padding-right: ${between('30px', '110px')};
    }

    .padding-horizontal-text {
        padding-left: ${between('30px', '90px')};
        padding-right: ${between('30px', '90px')};
    }

    .transform-bottom-md {
        transform: translateY(55%);
    }

    .authentic-text-wrapper {
        position: absolute;
        bottom: 15px;
    }

    .music-text-wrapper {
        position: absolute;
        bottom: 0px;
    }

    .multi-schooled-slider-controls {
        .swiper-button-next {
            transform: translateY(-80px) !important;
        }
        .swiper-button-prev {
            transform: translateY(-80px) !important;
        }
    }
    .trails-slider-controls {
        .swiper-button-next {
            transform: translateY(-30px) !important;
        }
        .swiper-button-prev {
            transform: translateY(-30px) !important;
        }
    }

    .multi-schooled-slider-img {
        object-fit: cover;
        height: auto !important;
        width: 100%;
    }

    .mb-top {
        margin-top: 5vh;
    }

    .trails-img {
        height: calc(100% - 100px) !important;
    }
`;

export const Img = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
`;

export const AltContainer = styled(Row)``;
