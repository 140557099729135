export default [
    {
        id: 1,
        family: 'Namer',
        color: 'rgba(171, 134, 113, 1)',
        members: [
            {
                id: 'm-1',
                name: 'İsrail Namer',
                lat: '39.941760',
                lng: '32.854459',
            },
        ],
    },
    {
        id: 2,
        family: 'Soriano',
        color: 'rgba(121, 121, 121, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Leon Hananel Soriano',
                lat: '39.941866',
                lng: '32.854273',
            },
            {
                id: 'm-2',
                name: 'Emil Hananel Soriano',
                lat: '39.942030',
                lng: '32.854465',
            },
            {
                id: 'm-3',
                name: 'Jak Soriano',
                lat: '39.941806',
                lng: '32.854576',
            },
        ],
    },
    {
        id: 3,
        family: 'Kişiler',
        color: 'rgba(200, 200, 200, 1)',
        members: [
            {
                id: 'm-1',
                name: 'Banaroya',
                lat: '39.942035',
                lng: '32.854141',
            },
            {
                id: 'm-2',
                name: 'Eliya Almaleh',
                lat: '39.942187',
                lng: '32.854699',
            },
            {
                id: 'm-3',
                name: 'Baruh - Farhi',
                lat: '39.941727',
                lng: '32.853588',
            },
            {
                id: 'm-4',
                name: 'Daniel Aytekin (Mösyö David)',
                lat: '39.933269',
                lng: '32.865542',
            },
            {
                id: 'm-5',
                name: 'Dario Moreno',
                lat: '39.937614',
                lng: '32.854205',
            },
            {
                id: 'm-6',
                name: 'Eli Burla ve Daniel Burla (Burla Biraderler)',
                lat: '39.942411',
                lng: '32.854566',
            },
            {
                id: 'm-7',
                name: 'Jul Fresko',
                lat: '39.942381',
                lng: '32.854351',
            },
            {
                id: 'm-8',
                name: 'Jak Aciman',
                lat: '39.941838',
                lng: '32.855419',
            },
            {
                id: 'm-9',
                name: 'Mösyö Jozef',
                lat: '39.942677',
                lng: '32.854476',
            },
            {
                id: 'm-10',
                name: 'Salti - Franko',
                lat: '39.939968',
                lng: '32.859876',
            },
        ],
    },
];
