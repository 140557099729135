import React, { useEffect } from 'react';
import LoadingSpinner from '@Components/LoadingSpinner';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text } from '@Components/Typography/styles';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { StyledCarousel, ImageWrapper, TextWrapper } from './styles';

const PhotoSlider = ({ documents, isNews }) => {
    const { i18n } = useTranslation('common');

    function createMarkup(newsHtml) {
        return { __html: newsHtml };
    }

    return isNews ? (
        <StyledCarousel
            arrows
            dotPosition="top"
            prevArrow={
                <div className="arrows">
                    <LeftCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }
            nextArrow={
                <div className="arrows">
                    <RightCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }>
            {!documents ? (
                <LoadingSpinner />
            ) : (
                documents?.map(({ alt, name, src, objectFit }, i) => (
                    <ImageWrapper objectFit={objectFit} key={name}>
                        <Image height="90%" width="100%" src={src} placeholder={<Image preview={false} src={src} />} />
                        <TextWrapper style={{ paddingTop: '10px' }} key={i} align="middle" justify="center">
                            <div dangerouslySetInnerHTML={createMarkup(alt)} />
                        </TextWrapper>
                    </ImageWrapper>
                ))
            )}
        </StyledCarousel>
    ) : (
        <StyledCarousel
            arrows
            dotPosition="top"
            prevArrow={
                <div className="arrows">
                    <LeftCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }
            nextArrow={
                <div className="arrows">
                    <RightCircleOutlined style={{ color: '#ffffff', fontSize: '32px' }} />
                </div>
            }>
            {!documents ? (
                <LoadingSpinner />
            ) : (
                documents?.map(({ altTR, altEN, src }, i) => (
                    <ImageWrapper className="alper" key={i}>
                        <Image height="90%" width="100%" src={src} placeholder={<Image preview={false} src={src} />} />
                        <TextWrapper key={i} align="middle" justify="center">
                            <Text key={i}>{i18n.language === 'en' ? altEN : altTR}</Text>
                        </TextWrapper>
                    </ImageWrapper>
                ))
            )}
        </StyledCarousel>
    );
};

export default PhotoSlider;
