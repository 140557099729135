/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
import StyledVirtualTourPage from '@Components/PageWrapper';
import VideoOverlay from '@Components/VideoOverlay';
import { Col, Carousel } from 'antd';
import VirtualTourModal from '@Components/VirtualTourModal';
import * as routeURLs from '@Routes/constants';
import { useHistory } from 'react-router-dom';
import Button from '@Components/Button';
import { fireStoreRefDecorator, canonicals } from '@Helpers';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import MapsPrimaryIcon from '@Assets/icons/maps-primary-icon.svg';
import MapsSecondaryIcon from '@Assets/icons/maps-secondary-icon.svg';
import AirPhotosPrimaryIcon from '@Assets/icons/air-photos-primary-icon.svg';
import AirPhotosSecondaryIcon from '@Assets/icons/air-photos-secondary-icon.svg';
import GeneralInfoPrimaryIcon from '@Assets/icons/general-info-primary-icon.svg';
import GeneralInfoSecondaryIcon from '@Assets/icons/general-info-secondary-icon.svg';
import NewsPrimaryIcon from '@Assets/icons/news-primary-icon.svg';
import NewsSecondaryIcon from '@Assets/icons/news-secondary-icon.svg';
import PhotosPrimaryIcon from '@Assets/icons/photos-primary-icon.svg';
import PhotosSecondaryIcon from '@Assets/icons/photos-secondary-icon.svg';
import VideoInterviewPrimaryIcon from '@Assets/icons/video-interview-icon.svg';
import VideoInterviewSecondaryIcon from '@Assets/icons/video-interview-secondary-icon.svg';
import useResponsive from '@Hooks/useMediaQuery';
import animationData from '../VirtualTourExperiencePage/InfoCard/playing.json';

import { Wrapper, Content, ButtonWrapper, CtaWrapper, Border, StyledButton, IconWrapper, StyledSoundFilled, StyledSoundOutlined } from './styles';

const VirtualTourPage = () => {
    const { sm } = useResponsive();
    const virtualTourHero =
        'https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/jqa-virtual-tour%2Fvirtual-tour-video.mp4?alt=media&token=b238b568-7b04-4262-bf37-372a16d942ae';
    const history = useHistory();
    const [hoveredElementId, setHoveredElementId] = useState();
    const [clickedElementId, setClickedElementId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalKey, setModalKey] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(true);
    const backgroundVideoRef = useRef('background-video-ref');
    const [clickedVtElementData, setClickedVtElementData] = useState();

    const { i18n, t } = useTranslation('common');

    const handleButtonClick = (data, i) => {
        setClickedElementId(i);
        const currentRef = fireStoreRefDecorator(data.key);
        setClickedVtElementData({
            currentRef,
            data,
        });
        setModalKey(i);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setHoveredElementId();
        setClickedElementId();
    };

    const titleConstruct = (item, i) => {
        if ((hoveredElementId === i && i18n.language === 'en') || (clickedElementId === i && i18n.language === 'en')) {
            return item.titleEN;
        }
        if ((hoveredElementId === i && i18n.language === 'tr') || (clickedElementId === i && i18n.language === 'tr')) {
            return item.titleTR;
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const navItems = [
        {
            id: 1,
            key: 'maps',
            primaryIcon: MapsPrimaryIcon,
            secondaryIcon: MapsSecondaryIcon,
            titleEN: 'Maps',
            titleTR: 'Haritalar',
        },
        {
            id: 2,
            key: 'aerial',
            primaryIcon: AirPhotosPrimaryIcon,
            secondaryIcon: AirPhotosSecondaryIcon,
            titleEN: 'Aerial Photos',
            titleTR: 'Hava Fotoğrafları',
        },
        {
            id: 3,
            key: 'interviews',
            primaryIcon: VideoInterviewPrimaryIcon,
            secondaryIcon: VideoInterviewSecondaryIcon,
            titleEN: 'Video Interviews',
            titleTR: 'Video Röportajlar',
        },
        {
            id: 4,
            key: 'photos',
            primaryIcon: PhotosPrimaryIcon,
            secondaryIcon: PhotosSecondaryIcon,
            titleEN: 'Photos',
            titleTR: 'Fotoğraflar',
        },
        {
            id: 5,
            key: 'news',
            primaryIcon: NewsPrimaryIcon,
            secondaryIcon: NewsSecondaryIcon,
            titleEN: 'Press News',
            titleTR: 'Basın Haberleri',
        },
        {
            id: 6,
            key: 'general',
            primaryIcon: GeneralInfoPrimaryIcon,
            secondaryIcon: GeneralInfoSecondaryIcon,
            titleEN: 'General Information',
            titleTR: 'Genel Bilgiler',
        },
    ];

    const handleSoundClick = () => {
        if (isAudioPlaying) {
            setIsAudioPlaying(false);
            backgroundVideoRef.current.muted = true;
        } else {
            setIsAudioPlaying(true);
            backgroundVideoRef.current.muted = false;
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            backgroundVideoRef.current.muted = true;
            setIsAudioPlaying(false);
        }
    }, [isModalVisible]);

    return (
        <StyledVirtualTourPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.virtualTour')}</title>
                <link rel="canonical" href={canonicals.virtualTour} />
            </Helmet>
            <Wrapper span={24}>
                <VideoOverlay opacity="0.35" />
                <Content align="middle">
                    <video ref={backgroundVideoRef} src={virtualTourHero} type="video/mp4" data-keepplaying id="jqa-hero-video" loop autoPlay />
                </Content>
                <CtaWrapper align="middle" justify="center">
                    <Button onClick={() => history.push(routeURLs.VIRTUAL_TOUR_PAGE)} weight="bold" letterSpacing="middle" size="32px">
                        {t('virtualTourPage.ctaButton')}
                    </Button>
                </CtaWrapper>
                <ButtonWrapper sm={sm}>
                    <Col span={10}>
                        <Border />
                        {i18n.language === 'tr'
                            ? navItems.map((item, i) => (
                                  <Col key={i} style={i === navItems.length - 1 ? { marginBottom: '0px' } : { marginBottom: '48px' }}>
                                      <StyledButton
                                          onMouseEnter={() => setHoveredElementId(i)}
                                          onMouseLeave={() => setHoveredElementId()}
                                          icon={
                                              <IconWrapper>
                                                  <img
                                                      style={{ width: '36px' }}
                                                      key={clickedElementId === i || hoveredElementId === i ? item.secondaryIcon : item.primaryIcon}
                                                      src={clickedElementId === i || hoveredElementId === i ? item.secondaryIcon : item.primaryIcon}
                                                      alt={item.titleTR}
                                                  />
                                              </IconWrapper>
                                          }
                                          onClick={() => handleButtonClick(item, i)}
                                          type="primary"
                                          textcolor="light"
                                          key={i}>
                                          {titleConstruct(item, i)}
                                      </StyledButton>
                                  </Col>
                              ))
                            : navItems
                                  .filter((item) => item.titleTR !== 'Basın Haberleri')
                                  .map((item, i) => (
                                      <Col key={item.id} style={item.id === 6 ? { marginBottom: '0px !important' } : { marginBottom: '48px' }}>
                                          <StyledButton
                                              onMouseEnter={() => setHoveredElementId(i)}
                                              onMouseLeave={() => setHoveredElementId()}
                                              icon={
                                                  <IconWrapper>
                                                      <img
                                                          style={{ width: '36px' }}
                                                          key={
                                                              clickedElementId === i || hoveredElementId === i ? item.secondaryIcon : item.primaryIcon
                                                          }
                                                          src={
                                                              clickedElementId === i || hoveredElementId === i ? item.secondaryIcon : item.primaryIcon
                                                          }
                                                          alt={item.titleTR}
                                                      />
                                                  </IconWrapper>
                                              }
                                              onClick={() => handleButtonClick(item, i)}
                                              type="primary"
                                              textcolor="light"
                                              key={i}>
                                              {titleConstruct(item, i)}
                                          </StyledButton>
                                      </Col>
                                  ))}
                    </Col>
                </ButtonWrapper>
                <Carousel autoplay />
                <div className="lottie-wrapper">
                    {isAudioPlaying ? (
                        <StyledSoundFilled onClick={() => handleSoundClick()} />
                    ) : (
                        <StyledSoundOutlined onClick={() => handleSoundClick()} />
                    )}
                    <div className="lottie-styles">
                        <Lottie isPaused={!isAudioPlaying} options={defaultOptions} isClickToPauseDisabled height={32} width={32} />
                    </div>
                </div>
            </Wrapper>
            <VirtualTourModal
                key={modalKey}
                clickedVtElementData={clickedVtElementData}
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
            />
        </StyledVirtualTourPage>
    );
};

export default VirtualTourPage;
